<template>
    <v-app>
          <div class="d-flex  flex-row">  
                 <p class="text-h4 mx-5 mt-5 mr-auto">Loterias</p>
                 <p class="mx-5 mt-5 ml-auto">Dashboard > Loterias</p>
          </div>
          <v-main>
                <v-card class="mx-auto mt-5" color="transparent" max-width="1280" elevation="0">
                    <v-simple-table class="mt-5 mx-5 rounded-lg">
                        <template v-slot:default>
                            <thead>
                                <tr class="purple darken-2">   
<!--                                    <th class="white&#45;&#45;text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">Nº</th>-->
                                    <th class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">NOMBRE</th>
                                    <th class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >NOMBRE DE BOLILLA</th>
                                    <th class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">NÚMEROS</th>
                                    <th class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">NÚMERO ESPECIAL</th>
                                    <th class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >JUGADAS/DIA</th>
                                    <th class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >ACCIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(lottery, i) in lotteries" :key="i" >
<!--                                    <td class="text-center text-h5"> {{i + 1}} </td>-->
                                    <template v-if="!lottery.state">
                                        <td class="text-center text-h5"> {{lottery.name}} </td>
                                        <td class="text-center text-h5"> {{lottery.sub_name}}</td>
                                        <td class="text-center text-h5"> 1 - {{lottery.size}} </td>
                                        <td class="text-center text-h5"> 1 - {{lottery.sub_length}} </td>
                                        <td class="text-center text-h5"> {{lottery.intents}} </td>
                                        <td class="text-center">
                                            <v-btn class="mx-5 orange"  dark samll :fab="false"  @click="edit(lottery.id)"><v-icon>edit</v-icon></v-btn>
                                            <v-btn class="red" dark samll :fab="false" @click="delete(lottery.id)"><v-icon>delete</v-icon></v-btn>
                                        </td>
                                    </template>
                                </tr>
                            </tbody>  
                        </template>
                    </v-simple-table>
                </v-card>
          </v-main>
    </v-app> 
</template>

<script>
import { mapState, mapActions } from "vuex";
import sweet from 'sweetalert2'

export default {
    name: 'List',
    data() {
        return {}
    },
    mounted(){
        this.getLotteries({...this.$route.params})
    },
    computed: {
      ...mapState({
        lotteries: state => state.lottery.lotteries
      })
    },
    methods:{
        ...mapActions({
           getLotteries : 'getLotteries',
           deleteLottery: 'deleteLottery'
        }),
        edit(id){
          this.$router.push("/admin/loterias/" +id)
        },
        delete(id){
          sweet.fire({
                title: '¿Deseas eliminar el registro?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar',
                cancelButtonText: 'Cancelar'                         
            }).then(  (result) => {
                if (result.isConfirmed) { 
                   this.deleteLottery({id: id})
                   sweet.fire('¡Registro eliminado!','El registro ha sido eliminado','success')
                } else if (result.isDenied) {
                  console.log("")
                }
            })              
        },
    }
}
</script>

<style>

</style>