<template>
    <div style="padding: 10px 45px">

        <div class="col-md-3" style="width: 20%;">
            <div class="add-listing-section row event dynamic-features">
                <div class="add-listing-headline " style="display: flex" >
                    <label>
                        PANEL DE NUMEROS:
                    </label>
                    <b-form-checkbox v-model="lottery.isShow" name="check-button" switch>
                    </b-form-checkbox>
                </div>
                <div class="play-card-body cartillaSelectedJugar" style=" width: 270px " :key="'i-'+index" v-for="(item, index) in items" >
                    <div class="number-list left" style="display: flex; flex-wrap: wrap; justify-content: center; width: 270px ">
                        <div class="numeros-test" v-for="i in lottery_.size" :key="i" :class="{ active: paint(index, i) }" v-on:click="active(index, i)" >{{ i }}</div>
                    </div>
                </div>
                <template v-if="lottery_.sub_length!== 0">
                    <label style="margin-top: 20px">{{ lottery_.ball_lottery }}:</label>
                    <div class="play-card-body cartillaSelectedJugar" style=" width: 270px "  :key="'j-'+index" v-for="(item, index) in items" >
                        <div class="number-list left" style="display: flex; flex-wrap: wrap; justify-content: center; width: 270px ">
                            <div class="numeros-test" v-for="j in lottery_.sub_length" :key="j" :class="{ ball_red: paint_bf(j) }" v-on:click="active_bf(j)" >
                                {{ j }}
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="lottery_.plier_lottery !== 'MULTIPLICADOR' && parseInt(lottery.type) === 0" >
                    <label style="margin-top: 20px">{{ lottery_.plier_lottery }}:</label>
                    <div class="play-card-body cartillaSelectedJugar" style=" width: 270px " :key="'k-'+index" v-for="(item, index) in items" >
                        <div class="number-list left" style="display: flex; flex-wrap: wrap; justify-content: center; width: 270px ">
                            <div class="numeros-test" v-for="k in 10" :key="k" :class="{ ball_blue: paint_potenciador(k) }" v-on:click="active_potenciador(k)" >
                                {{ k }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="col-md-9" style="margin-left: 10px">
            <div class="add-listing-section row event dynamic-features" style="margin-bottom: 20px">

                <div class="col-md-3">
                    <label >Tipo :</label>
                    <select   style="height: 51px" v-model="lottery.type" class="form-control">
                        <option value="0" >Tradicional</option>
                        <option value="1">Repechage</option>
                    </select>
                </div>
                <template v-if="parseInt(lottery.type) === 1">
                    <div class="col-md-3" >
                        <label >Loteria :</label>
                        <vSelect v-model="selected.date" :from="resultDates" @change="btnRelation(selected)"></vSelect>
                    </div>
                </template>
                <div class="col-md-3">
                    <label >Monto:</label>
                    <input type="number" v-model="lottery.amount" class="input-text">
                </div>
                <div class="col-md-3">
                    <label > Fecha resultado:</label>
                    <input type="date" v-model="lottery.date" class="input-text " v-on:change="onDateNextResult"/>
                </div>
                <div class="col-md-3">
                    <label >Fecha proximo resultado:</label>
                    <input type="date" v-model="lottery.date_before" disabled/>
                </div>
            </div>

            <div class="add-listing-section row event dynamic-features">
                <div class="add-listing-headline " style="display: flex; justify-content: space-between">
                    <h3>
                        <i class="sl sl-icon-picture"></i> Ganadores
                    </h3>
                    <div>
                        <button class="button " @click="add"><i class="fas plus"></i>Agregar</button>
                        <button class="button " @click="save" > Guardar </button>
                        <button class="button " v-on:click="back"> Cancelar </button>
                    </div>
                </div>

                <div style="margin: 0 30px 0 30px;">
                    <Item :prizes="prizes" :type="type" :type_result="parseInt(lottery.type)" :enhancer="lottery.enhancer"  />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import Item from './item'
import moment from 'moment'
import sweet from "sweetalert2";
import Vue from 'vue'
import '@desislavsd/vue-select/dist/vue-select.css'
import { vSelect } from '@desislavsd/vue-select'
Vue.component("v-select", vSelect);

export default {
    name : 'New',
    data() {
        return {
            items: [{numbers: []}],
            price: 10.00,
            total: 1,
            num: 0,
            num_espe: 0,
            type_ball: "1",
            type_result: 0,
            type: 'create',
            selected: {
                date: '',
                id:''
            },

            lottery: {
                enhancer: 0,
                balls:"",
                ball_red: "",
                date_before: "",
                amount: 0,
                associated: '',
                name: this.$route.params.name,
                date:"",
                lottery_id: this.$route.params.id,
                detail: '',
                type: 0,
                isShow: true,
            },
        };
    },
    components: {
        Item,
        vSelect
    },
    async mounted(){
        await this.getLottery({ id:this.$route.params.id, name: this.$route.params.name })
        await this.listTop({ ...this.$route.params, limit: 30 })
    },
    computed:{
        ...mapState({
            lottery_: state => state.lottery.lottery,
            prizes:  state => state.resultsAdmin.prizes,
            created_result: state => state.resultsAdmin.created_result,
            list_top: state => state.resultsAdmin.list_top
        }),

        resultDates(){
            return this.list_top.map( e => `${e.date}` );
        }
    },
    methods:{
        ...mapActions({
            create: 'createResultLotteryAdmin',
            getLottery: 'getLottery',
            addResult: 'addResult',
            listTop: 'resultTopOneHundred'
        }),
        onDateNextResult(){
            let date_before;
            //let day_keys = [1, 2, 3, 4, 5, 6, 7]
            let day_key = moment(this.lottery.date).weekday() + 1;
            //let lottery_days = JSON.parse(this.lottery_.days)

            if (this.lottery_.name === 'power_balls') {
                date_before = this.addDayPowerBall(day_key)
            }
            if (this.lottery_.name === 'mega_millions') {
                date_before = this.addDayMega(day_key)
            }
            if (this.lottery_.name === 'florida') {
                date_before = this.addDayFlorida(day_key)
            }
            this.lottery.date_before = date_before
        },
        addDayPowerBall(day_key) {
            let date_before;
            switch (day_key) {
                case 1:
                case 4:
                case 6:
                    date_before = moment(this.lottery.date).add(2, 'days');
                    break;
                case 2:
                case 5:
                case 7:
                    date_before = moment(this.lottery.date).add(1, 'days');
                    break;
                case 3:
                    date_before = moment(this.lottery.date).add(3, 'days');
                    break;
            }
            return date_before.format('YYYY-MM-DD')
        },
        addDayMega(day_key) {
            let date_before;
            switch (day_key) {
                case 1:
                case 4:
                    date_before = moment(this.lottery.date).add(1, 'days');
                    break;
                case 2:
                    date_before = moment(this.lottery.date).add(3, 'days');
                    break;
                case 3:
                case 6:
                case 7:
                    date_before = moment(this.lottery.date).add(2, 'days');
                    break;
                case 5:
                    date_before = moment(this.lottery.date).add(4, 'days');
                    break;
            }
            return date_before.format('YYYY-MM-DD')
        },
        addDayFlorida(day_key) {
            let date_before;
            switch (day_key) {
                case 1:
                case 4:
                    date_before = moment(this.lottery.date).add(2, 'days');
                    break;
                case 2:
                case 5:
                    date_before = moment(this.lottery.date).add(1, 'days');
                    break;
                case 3:
                case 7:
                    date_before = moment(this.lottery.date).add(3, 'days');
                    break;
                case 6:
                    date_before = moment(this.lottery.date).add(4, 'days');
                    break;

            }
            return date_before.format('YYYY-MM-DD')
        },
        btnRelation(val) {
            let data = this.list_top.filter(e => {
                return e.date === val.date
            })
            this.lottery.date = data[0].date
            this.lottery.date_before = data[0].date_before
            this.lottery.amount = data[0].amount
        },
        add() {
            this.addResult({ position: 0, prize_level: "", lottery_winner: '', lottery_prize:'', play_winner: '', play_prize: ''})
        },
        async save(){
            sweet.fire({
                title: 'Por favor, espere!',
                allowOutsideClick: false,
                confirmButtonText: false,
                showConfirmButton: false,
                willOpen: () => { sweet.showLoading() } });
            //this.lottery.balls = JSON.stringify(this.items[0].numbers);
            this.lottery.balls = JSON.stringify(this.items[0].numbers.sort(function(a,b){return a - b;})); // order asc
            let array_flat = []
            this.prizes.forEach((item, i) => {
                item.position = i
                array_flat.push(item)
            });
            //this.lottery.isShow = this.lottery.isShow
            this.lottery.detail = JSON.stringify(array_flat)
            await this.create(this.lottery);
            if (this.created_result) {
                sweet.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: 'Nuevo resultado registrado!',
                    showConfirmButton: false,
                    timer: 2000 })
                this.back()
            }
        },
        notification(){
            sweet.fire({
                position: 'center',
                icon: 'success',
                title: '¡Registro actualizado exitosamente!',
                showConfirmButton: true,
            })
        },
        back(){
            this.$router.push(`/admin/results/${this.$route.params.name}/${this.$route.params.id}`)
        },
        paint(index,i){
            let j;
            for(j = 0; j < this.items[index].numbers.length; j++){
                if(i === this.items[index].numbers[j]){
                    return true
                }
            }
        },
        active(index,i){
            if(!this.items[index].numbers.includes(i)){
                if(this.items[index].numbers.length < this.lottery_.selectNumber){
                    this.items[index].numbers.push(i)
                }
            }else{
                let flag = this.items[index].numbers.indexOf(i)
                if ( flag !== -1 ) {
                    this.items[index].numbers.splice( flag, 1 )
                }
            }
        },
        paint_ball_red(i){
            if(this.lottery.ball_red === i){
                return true
            }
        },
        paint_bf(i){
            if(i === this.lottery.ball_red){
                return true
            }
        },
        active_bf(i){
            this.lottery.ball_red = i
        },
        paint_potenciador(i){
            if(i === this.lottery.enhancer){
                return true
            }
        },
        active_potenciador(i){
            this.lottery.enhancer = i
        }
    },
}

</script>

<style scoped>

.add-listing-section {

    padding: 25px !important;
}
.add-listing-headline  {
    left: 0;
    padding: 0 40px 20px 40px !important;
}
select {
    line-height: initial !important;
}
.cartillaSelectedJugar {
    box-shadow: 0px 0px 2px black;
    background: #eaebff;
    /* background: #EAF0F5; */
    border-radius: 16px;
    /* padding: 2px; */
    transition: 0.3s;
}
.cartillaSelectedJugar:hover {
    background: #c5c4c4;
}
.activehot {
    font-size: 48px;
    margin: 3px;
    color: #68001C !important;
}
.numeros-test {
    cursor: pointer;
    background: white;
    border-radius: 7px;
    margin: 2px 2px;
    padding: 7px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.v-select {
    --c-base: #fff;
    --c-theme: #f0f0f0;
    --c-border: #ccc;
    --radius: 0.2em;
    --padd: 0px;
    --height: 51px;
    font-size: 12px;
}


.swal2-icon {
    position: relative;
    box-sizing: content-box;
    justify-content: center;
    width: 8em !important;
    height: 8em !important;
    margin: 1.25em auto 1.875em;
    border: 0.25em solid transparent;
    border-radius: 50%;
    font-family: inherit;
    line-height: 5em;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.play-card-body {
    padding: 10px 11px 10px 11px;
    border-top: 1px solid #ededed;
}
.play-card-body {
    padding: 5px !important;
}
.number-list {
    margin: 0 -3px;
}
.dashboard-list-box ul li {
    padding: 0 0 !important;
    border-bottom: 1px solid #eaeaea;
    transition: 0.3s;
    position: relative;
}
.number-list li {
    border-radius: 50% !important;
}
.ball_red {
    background-color: #ed2626 !important;
    border-color: #ed2626 !important;
    color: #ffffff !important;
}
.ball_blue {
    background-color: #1654f2 !important;
    border-color: #1654f2 !important;
    color: #ffffff !important;
}
.play-card-body .number-list div.active {
    color: white;
    background: #32a842 !important;
    border-color: #32a842 !important;
}
.left {
    text-align: left !important;
}
</style>