<template>
    <div class="content-table" :class="{ dark: picked }">

      <highcharts :options="chartOptions"></highcharts>

    </div> 
</template>
<script>
import analyticAPI from "../../../../api/analytic";
import { Chart } from "highcharts-vue";
import {mapActions, mapState} from "vuex";
import {EventBus} from "@/plugins/event-bus";
export default {
    name: 'FrequencyMore',
    components: {
      highcharts: Chart,
    },
    data() {
        return {
          chartOptions: {
            chart: {
              type: "bar",
              spacingBottom: 10,
              spacingTop: 0,
              spacingLeft: 0,
              spacingRight: 0,
              width: 300,
              height: 900,
              backgroundColor: this.picked,
            },
            colors: [ '#68001c', '#666666', '#027b76', ],
            legend: {
              verticalAlign: 'top',
              enabled: true,
              itemStyle: { color: '#b68c64' }
            },
            plotOptions: {
              bar : { },
              series: {
                cursor: "pointer",
                pointWidth: 9,
                point: {
                  events: {
                    mouseOver: (e) => {
                      this.sendIndications({ball: e.target.category, count: e.target.y, date: e.target.date })
                    },
                  },
                },
              },
            },
            xAxis: {
              categories: null,
              title: {
                text: "BOLILLAS",
                style: {
                  color: null
                }
              },
              labels: {
                style: {
                  color: null
                }
              }
            },
            yAxis: {
              categories: null,
              title: {
                text: "CANTIDAD",
                style: {
                  color: null
                }
              },
              labels: {
                style: {
                  color: null
                }
              }
            },
            title: {
            text:' ',

            },
            series: null
          },
        }
    },
    computed: {
        ...mapState({
            picked: state => state.costumer.picked,
            theme_dark: state => state.costumer.theme_dark,
            theme_light: state => state.costumer.theme_light,
        }),
    },
    mounted() {
      this.init();
    },
  props: {
    type: {
      type: Number
    }
  },
    methods: {
      ...mapActions({
        changeIndicators: "changeIndicators",
      }),
      async init() {
        const { data: data } = await analyticAPI.findMore({
          name: this.$route.params.name,
          id: this.$route.params.id,
          limit: 10,
          type: this.type,
        });

        this.chartOptions.xAxis.categories = data.labels;

        this.chartOptions.series = data.data;

        this.appearances = data.appearances;
        this.chartOptions.chart.borderColor = "#b68c64"
        this.chartOptions.chart.plotBorderColor = "#b68c64"
        EventBus.$on('change_theme', this.eventBusTheme);

      },
      async sendIndications({...searchParams}) {
        await this.changeIndicators(searchParams)
      },
      eventBusTheme() {
        this.chartOptions.xAxis.labels.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
        this.chartOptions.xAxis.title.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
        this.chartOptions.yAxis.labels.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
        this.chartOptions.yAxis.title.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
      },
      toTour(){
        this.$nextTick(() => {
          const tour = this.$shepherd({
            useModalOverlay: true,
            cancelIcon: {
              enabled: true
            },
            classes: 'class-1 class-2',
            scrollTo: { behavior: 'smooth', block: 'center' }
          });

          tour.addStep({
            attachTo: { element: ".tour_one", on: 'bottom' },
            text: 'Listado de las bolillas mas frecuentes, donde tambien muestra la cantidad de veces que a aparecido.<h1>test</h1>',
            classes: 'shepherd-theme-arrows',
            buttons: [
              {
                text: 'Next',
                action: tour.next
              }
            ],


          });
          tour.addStep({
            attachTo: { element: ".tour_two", on: 'bottom' },
            text: 'Test 2',
            classes: 'shepherd-theme-arrows',
            buttons: [
              {
                action: tour.back,
                classes: 'shepherd-button-secondary',
                text: 'Back'
              },
              {
                text: 'Next',
                action: tour.next
              }
            ]
          });
          tour.start();
        });
      },

  },
  
}
</script>

<style>

</style>