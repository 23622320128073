<template>
  <header id="header-container" class="fixed fullwidth dashboard">
	<div id="header" class="not-sticky">
		<div class="container">
			<div class="left-side">
				<div id="logo">
					<!--<h1 class="white text-center"><strong>LoteryPlay</strong></h1>-->
					<img class="margin-bottom-20 margin-left-20 margin-top-20 margin-right-20 change" v-bind:src="`/uploads/logo.png`" alt="image">
				</div>
				<div class="mmenu-trigger">
					<button class="hamburger hamburger--collapse" type="button">
						<span class="hamburger-box">
							<span class="hamburger-inner"></span>
						</span>
					</button>
				</div>
				<div class="clearfix"></div>
			</div>
			<div class="right-side">
				<div class="header-widget">
					<div class="user-menu">
						<!--<div class="user-name"><span><img src="" alt=""></span><strong>LoteryPlay</strong></div>
						<ul> 
							<li><a href=""><i class="sl sl-icon-power"></i> Salir</a></li>
						</ul>-->
					</div>
					</div>
			</div>
		</div>
	</div>
</header>
</template>

<script>
export default {
    name: 'Header'
}
</script>

<style>

#logo img {
    max-height: 70px  !important;
    width: auto;
	transform: translate3d(0,0,0);
	padding-top: 6px !important;
    margin-top: 2px !important;
}
</style>