<template>
    <div style="padding: 10px 45px">
    <div class="tab-content margin-top-0 padding-top-0">
<!--      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <img class="margin-bottom-20  margin-top-20 margin-right-20 change" v-bind:src="`${lottery_.image}`" alt="image" style="width: 300px">
          <h1 class="title" style="display:contents; font-size:25px;">Editar Bolillas Ganadoras</h1>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label >Mostrar:</label>
          <b-form-checkbox v-model="result.isShow" name="check-button" switch>
           &lt;!&ndash; Switch Checkbox <b>(Checked: {{ result.isShow }})</b>&ndash;&gt;
          </b-form-checkbox>
        </div>
      </div>-->

             <div class="col-md-3" style="width: 22%;">
                <div class="add-listing-section row event dynamic-features">
                    <div class="add-listing-headline " style="display: flex" >
                        <label>
                            PANEL DE NUMEROS:
                        </label>
                        <b-form-checkbox v-model="result.isShow" name="check-button" switch>
                        </b-form-checkbox>
                    </div>
                    <div class="play-card-body cartillaSelectedJugar"  style=" width: 270px " :key="'i-'+index" v-for="(item, index) in items">
                        <div class="number-list left" style="display: flex; flex-wrap: wrap; justify-content: center; width: 270px ">
                            <div  class="numeros-test" v-for="i in lottery_.size" :key="'l-'+i" :class="{ active: paint(index,i)}" v-on:click="active(index,i)">
                                {{i}}
                            </div>
                        </div>
                    </div>
                    <template v-if="lottery_.sub_length !== 0">
                        <label class="margin-top-10">{{lottery_.ball_lottery}}:</label>
                        <div class="play-card-body cartillaSelectedJugar"  style=" width: 270px " :key="'j-'+index" v-for="(item, index) in items">
                            <div class="number-list left" style="display: flex; flex-wrap: wrap; justify-content: center; width: 270px ">
                                <div class="numeros-test" v-for="j in lottery_.sub_length" :key="'k-'+j" :class="{ball_red: paint_bf(j)}" v-on:click="active_bf(j)">
                                    {{j}}
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="lottery_.type && !result.type">
                        <label class="margin-top-10">{{lottery_.plier_lottery}}:</label>
                        <div class="play-card-body cartillaSelectedJugar"  style=" width: 270px " :key="index" v-for="(item, index) in items">
                            <div class="number-list left" style="display: flex; flex-wrap: wrap; justify-content: center; width: 270px ">
                                <div class="numeros-test" v-for="k in 10" :key="'m-'+k" :class="{ball_blue: paint_enhancer(k)}" v-on:click="active_enhancer(k)">
                                    {{k}}
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="lottery_.playoff_multiplier && result.type">
                        <label class="margin-top-10">{{lottery_.plier_lottery}}:</label>
                        <div class="play-card-body cartillaSelectedJugar"  style=" width: 270px " :key="index" v-for="(item, index) in items">
                            <div class="number-list left" style="display: flex; flex-wrap: wrap; justify-content: center; width: 270px ">
                                <div class="numeros-test" v-for="k in 10" :key="'m-'+k" :class="{ball_blue: paint_enhancer(k)}" v-on:click="active_enhancer(k)">
                                    {{k}}
                                </div>
                            </div>
                        </div>
                    </template>

                </div>
            </div>

            <div class="col-md-9" >
                <div class="add-listing-section row event dynamic-features" style="margin-bottom: 20px; margin-left:20px">
                         <div class="col-lg-3 col-md-3 col-sm-12">
                            <label >Tipo:</label>
                            <select v-model="result.type" class="form-control chosen-select-no-single">
                                <option value="0" >Tradicional</option>
                                <option value="1">Repechage</option>
                            </select>
                        </div>
                        <template >
                            <div class="col-lg-3 col-md-3 col-sm-12" v-if="parseInt(result.type) === 1">
                                <label >Loteria :</label>
                                <vSelect v-model="result.date"  :from="resultDates" @change="btnRelation(result)"></vSelect>
                            </div>
                        </template>
                        <div class="col-lg-3 col-md-3 col-sm-12">
                            <label >Monto:</label>
                            <input type="number" v-model="result.amount"  class="form-control" required>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12">
                            <label > Fecha resultado:</label>
                            <input type="date" v-model="result.date" required disabled/>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12">
                            <label >Fecha proximo resultado:</label>
                            <input type="date" v-model="result.date_before" required disabled/>
                        </div>


                </div>
                <div class="add-listing-section row event dynamic-features" style=" margin-left:20px">
                    <div class="add-listing-headline " style="display: flex; justify-content: space-between">
                        <h3>
                            <i class="sl sl-icon-picture"></i> Ganadores
                        </h3>
                        <div>
                            <button class="button " @click="add"><i class="fas plus"></i>Agregar</button>
                            <button class="button " @click="save" > Guardar </button>
                            <button class="button " v-on:click="back"> Cancelar </button>
                        </div>
                    </div>
                    <table id="pricing-list-container">
                        <tr class="pricing-list-item pattern">
                          <template v-if="result.detail.length > 0">
                            <Item :prizes="result.detail" :lottery_name="this.$route.params.name" :type="type" :type_result="parseInt(result.type)" :enhancer="parseInt(lottery.enhancer)" class="col-md-12"/>

                          </template>
                        </tr>
                    </table>
                </div>
            </div>
    </div>
  </div>
</template>
<script>
import sweet from 'sweetalert2'
import moment from 'moment'
import {mapActions, mapState} from "vuex";
import Item from './item'
import Vue from 'vue'
import '@desislavsd/vue-select/dist/vue-select.css'
import { vSelect } from '@desislavsd/vue-select'
Vue.component("v-select", vSelect);

export default {
  name : 'EditResult',
  data() {
    return {
      items: [ { numbers : []} ],
      price : 10.00,
      total: 1,
      num : 0,
      num_espe : 0,
      image:"",
      type: 'edit',
      type_ball: "1",
      selected: {
        date: '',
        id:''
      },
      SextaBolilla:"",
      power:"",
      lottery: {
        enhancer: "",
        balls:"",
        ball_red: "",
        date_before: "",
        amount: 0,
        date:"",
        detail: '',
        type: 0
      },
      data : []
    };
  },
  components: {
    Item,
     vSelect
  },
  async mounted(){
    await this.getLottery({ id: this.$route.params.lottery_id, name: this.$route.params.name })
    await this.findOne({name: this.$route.params.name, id:this.$route.params.id, lottery_id: this.$route.params.lottery_id });
    await this.listTop({ ...this.$route.params, limit: 30 })
    await this.show()
  },
  computed:{
    ...mapState({
      lottery_: state => state.lottery.lottery,
      result:  state => state.resultsAdmin.result,
      updated_result: state => state.resultsAdmin.updated_result,
      detail_remove: state => state.resultsAdmin.detail_remove,
      list_top: state => state.resultsAdmin.list_top

    }),
    resultDates(){
      return this.list_top.map( e => `${e.date}` );
    }
  },
  methods:{
    ...mapActions({
      findInfoLotteryAdmin: 'findInfoLotteryAdmin',
      getLottery: 'getLottery',
      findOne: 'findResultLotteryAdmin',
      updateResultLotteryAdmin: 'updateResultLotteryAdmin',
      addResult: 'addResultAdmin',
      listTop: 'resultTopOneHundred'
    }),
    format_date(value){
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
      }
    },
    btnRelation(val) {

        if(val.date) {
            let data = this.list_top.filter(e => {
                console.log('e.date', e.date);
                console.log('val.date', val.date);
                return e.date === val.date
            })
            console.log('data', data);
            this.result.date = data[0].date
            this.result.date_before = data[0].date_before
            this.result.amount = data[0].amount
        }
    },
    add(){
      this.addResult({ id: 0, position: 0  , prize_level: "", lottery_winner: '', lottery_prize:'', play_winner: '', play_prize: ''})
    },
    back(){
      this.$router.push(`/admin/results/${this.$route.params.name}/${this.$route.params.lottery_id}`)
    },
    async save(){
      this.move()
      this.lottery.date_before = this.result.date_before
      this.lottery.amount = this.result.amount
      this.lottery.type = this.result.type

      //this.lottery.enhancer = this.result.enhancer

      this.lottery.balls = JSON.stringify(JSON.parse(this.lottery.balls).sort(function(a,b){return a - b;})); // order asc
      this.lottery.lottery_id = this.lottery_.id
      this.lottery.name = this.$route.params.name
      this.lottery.date = this.result.date
      this.lottery.isShow = this.result.isShow

      let array_flat = []
      this.result.detail.forEach((item, i) => {
        if (item.position === 0) {
          item.position = i
          array_flat.push(item)
        }
      });

      this.lottery.detail = JSON.stringify(array_flat)
      //this.lottery.detail = JSON.stringify(this.result.detail)
      this.lottery.datail_remove = JSON.stringify(this.detail_remove)

      await this.updateResultLotteryAdmin({  name: this.$route.params.name, lottery_id: this.$route.params.lottery_id, id:this.$route.params.id, resultado:this.lottery});

      if (this.updated_result) {
        this.notification();
        this.back();
      } else {
        sweet.fire({
          position: 'center',
          icon: 'warning',
          title: `¡"error al actualizar"!`,
          showConfirmButton: true,
        })
      }
    },
    notification(){
      sweet.fire({
        position: 'center',
        icon: 'success',
        title: '¡Registro actualizado exitosamente!',
        showConfirmButton: true,
      })
    },
    async show(){
      console.log('test', this.result)
      this.items[0].numbers = JSON.parse(this.result.balls)
      this.lottery.enhancer = this.result.enhancer
      this.lottery.ball_red = this.result.ball_red
      this.lottery.date = this.format_date(this.result.date)
    },
    paint(index,i){
      let j;
      for(j = 0; j < this.items[index].numbers.length; j++){
        if(i === parseInt(this.items[index].numbers[j])){
          return true
        }
      }
    },
    active(index,i){
      if(!this.items[index].numbers.includes(i)){
        if(this.items[index].numbers.length < this.lottery_.selectNumber){
          this.items[index].numbers.push(i)
        }
      }else{
        let flag = this.items[index].numbers.indexOf(i)
        if ( flag !== -1 ) {
          this.items[index].numbers.splice( flag, 1 )
        }
      }
    },
    move(){
      this.lottery.balls = JSON.stringify(this.items[0].numbers)
    },
    paint_ball_red(i){
      if(this.lottery.ball_red === i){
        return true
      }
    },
    paint_bf(i){
      if(i === this.lottery.ball_red){
        return true
      }
    },
    active_bf(i){
      this.lottery.ball_red = i
    },
    paint_enhancer(i){
      if(i === this.lottery.enhancer){
        return true
      }
    },
    active_enhancer(i){
      this.lottery.enhancer = i
      for (let j = 0; j < this.result.detail.length; j++) {
        if (j !== 0) {
          this.result.detail[j].play_prize = this.lottery.enhancer * this.result.detail[j].lottery_prize;
        }
      }
    }
  }
}
</script>

<style scoped>

.add-listing-section {

    padding: 25px !important;
}
.add-listing-headline  {
    left: 0;
    padding: 0 40px 20px 40px !important;
}
.cartillaSelectedJugar {
  box-shadow: 0epx 0px 2px black;
  background: #eaebff;
  /* background: #EAF0F5; */
  border-radius: 16px;
  /* padding: 2px; */
  transition: 0.3s;
}
.cartillaSelectedJugar:hover {
  background: #c5c4c4;
}
.activehot {
  font-size: 48px;
  margin: 3px;
  color: #68001C !important;
}
.numeros-test {
  cursor: pointer;
  background: white;
  border-radius: 7px;
  margin: 2px 2px;
  padding: 7px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

select {
  line-height: initial !important;
}
.form-control {
  box-shadow: none;
  border-radius: 2px;
  padding: 12px 15px;
  height: 51px;
  font-size: 16px;
  width: 100%;
}
.v-select {
  --c-base: #fff;
  --c-theme: #f0f0f0;
  --c-border: #ccc;
  --radius: 0.2em;
  --padd: 0px;
  --height: 51px;
  font-size: 12px;
}

.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 8em !important;
  height: 8em !important;
  margin: 1.25em auto 1.875em;
  border: .25em solid transparent;
  border-radius: 50%;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.play-card-body {
  padding: 10px 11px 10px 11px;
  border-top: 1px solid #ededed;
}
.play-card-body {
  padding: 5px !important;
}
.number-list {
  margin: 0 -3px;
}

.dashboard-list-box ul li {
  padding: 0px 0px !important;
  border-bottom: 1px solid #eaeaea;
  transition: 0.3s;
  position: relative;
}
.ball_red{
  background-color: #ed2626 !important;
  border-color: #ed2626 !important;
  color: #ffffff !important;
}
.ball_blue{
  background-color: #1654f2 !important;
  border-color: #1654f2 !important;
  color: #ffffff !important;
}
.play-card-body .number-list div.active {
  background: #32a842 !important;
  color: #ffffff !important;
}
.left{
  text-align:left !important;
}
</style>