<template>
    <v-app>
          <div class="d-flex  flex-row">  
                 <p class="text-h4 mx-5 mt-5 mr-auto">Ganadores</p>
                 <p class="mx-5 mt-5 ml-auto"><v-btn class="mx-5" :fab="false" dark color="#458ef3" @click="Anadir()"> <v-icon>add</v-icon> </v-btn></p>
          </div> 
          <div>
            
                            <v-main>
                                <v-card class="mx-auto mt-5" color="transparent" max-width="1280" elevation="0">
                                    <v-simple-table class="mt-5 mx-5 rounded-lg">
                                        <template v-slot:default>
                                            <thead >
                                                <tr class="purple darken-2">   
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">ID</th>
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">JUGADOR</th> 
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >LOTERIA</th>
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">BOLILLAS</th>
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">POTENCIADOR</th> 
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">BOLA ROJA</th> 
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >ACCIONES</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(pena, index) in penas_prueba" :key="index">
                                                    <td class="text-center text-h5"> {{pena.id}} 
                                                    <td class="text-center text-h5"> {{pena.costumer_name}} {{pena.costumer_lastname}} </td>
                                                    <td class="text-center text-h5"> {{pena.lotery_name}} </td>
                                                    <td class="text-center text-h5">
                                                            <ul class="number-list" style="text-align:center" >
                                                                <li class="active_1" :key="index" v-for="(element, index) in JSON.parse(pena.balls)">{{element}}</li>
                                                            </ul>
                                                        </td>
                                                    <td class="text-center text-h5">
                                                            <ul class="number-list" style="text-align:center">
                                                                <li class="active ball-blue">{{pena.potenciador}} </li>
                                                            </ul>
                                                        </td>
                                                    <td class="text-center text-h5">
                                                            <ul class="number-list" style="text-align:center">
                                                                <li class="active ball-red">{{pena.ball_red}}</li>
                                                            </ul>
                                                        </td>
                                                    <td class="text-center">
                                                        <v-btn class="mx-5 orange"  dark samll :fab="false" @click="Editar(pena.id, pena.costumer_id, pena.lotery_id, pena.detail_lotery)"><v-icon>edit</v-icon></v-btn>
                                                        <v-btn class="red" dark samll :fab="false" @click="Eliminar(pena.id)" ><v-icon>delete</v-icon></v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            
                                        </template>
                                    </v-simple-table>
                                    <div class="col-md-12" style="text-align:center;">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-outline-secondary button_pagination"
                                                    v-if="pageFlag != 1"
                                                    @click="pageFlag--"
                                                > Ant
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-outline-secondary button_pagination"
                                                    v-for="pageNumber in pages_prueba"
                                                    @click="page = pageNumber"
                                                    :class="{active_1: pintar(pageNumber)}"
                                                    :key="pageNumber"
                                                >{{pageNumber}}</button>
                                                <button
                                                    type="button"
                                                    @click="pageFlag++"
                                                    v-if=" pages_prueba.length >= pageForSection"
                                                    class="btn btn-sm btn-outline-secondary button_pagination"
                                                >Sgt</button>
                                            </div>
                                </v-card>
                            </v-main>
            <v-dialog v-model="dialog" max-width="800" >
                <v-card>
                  <v-card-title class="green darken-4 white--text">Añadir Ganador</v-card-title>
                  <v-form>
                      <v-card-text>   
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label for="input-valid">Jugador:</label>
                              </b-col>
                               <b-col sm="3" lg="9">
                                <div>
                                  <select v-model="player" >
                                      <option :value="player.id" v-for="(player, index) in players" :key="index">{{player.name}} {{player.lastname}}</option>
                                  </select>
                                </div> 
                              </b-col>
                            </b-row>  
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label for="input-valid">Loteria:</label>
                              </b-col>
                              <b-col sm="3" lg="9">
                                <select v-model="lotery" v-on:change="get_balls()">
                                      <option :value="loterie.id" v-for="(loterie, index) in loterias" :key="index">{{loterie.name}}</option>
                                  </select>
                              </b-col>
                            </b-row>
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label for="input-valid">Bolillas:</label>
                              </b-col>
                              <b-col sm="3" lg="12">
                                <div>
                                    <v-data-table
                                        v-model="selected"
                                        :headers="headers"
                                        :items="balls_loteria"
                                        :single-select="true"
                                        item-key="id"
                                        :items-per-page="5"
                                        show-select
                                        class="elevation-1"
                                    >
                                    </v-data-table>
                                </div>
                              </b-col>
                            </b-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="NewWinner()" color="blue-grey" dark>Guardar</v-btn>
                        <v-btn @click="dialog=false" color="blue-grey" dark>Cancelar</v-btn>
                      </v-card-actions>
                  </v-form>
                </v-card>
            </v-dialog>
                        
        </div>
          
    </v-app> 
    
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Swal from 'sweetalert2'
import 'vue-happy-scroll/docs/happy-scroll.css'
export default {
    name:'ListWinner',
    data(){
        return{ 
            selected: [],
            headers: [
                {
                    text: 'Fecha',
                    align: 'center',
                    sortable: false,
                    value: 'date',
                },
                { 
                    text: 'Bolillas',
                    align: 'center', 
                    value: 'balls' 
                },
                {   text: 'Red Ball',
                    align: 'center', 
                    value: 'ball_red' 
                },
                { 
                    text: 'Potenciador',
                    align: 'center', 
                    value: 'potenciador' 
                },
            ],
            
            penas : [],
            penas_activate : [],
            lines : [],
            //url:"https://new.ialotteryplay.com/",    
            url:"https://latam.ialotteryplay.com/",
            page: 1,
            perPage: 10,
            pages: [],
            pageFlag : 1,
            pageForSection: 10,
            id : '',
            dialog : false,
            dialog_balls: false,
            player : '',
            lotery : '',
            players : [],
            loterias : [],
            balls_loteria : [],
            createOrUpdate : false
        }
    },
    mounted(){               
      this.mostrar()
    }, 
    rules: {
        "no-mixed-spaces-and-tabs": 0, // disable rule
    },
    methods:{
        format_date(value){
            if (value) {
            return moment(String(value)).format('YYYY-MM-DD')
            }
        },
        async mostrar(){
            await axios.get(this.url+'winners/listWinner')
            .then(response =>{
                this.penas = response.data.data  
                this.players = response.data.users
                this.loterias = response.data.loteries           
            })    
        },
        Eliminar(id){
            Swal.fire({
            title: '¿Deseas eliminar el registro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.post(this.url+'winners/deleteWinner/'+id)
                    Swal.fire(
                        '¡Registro eliminado!',
                        'El registro ha sido eliminado',
                        'success'
                    )
                    this.mostrar()
                }
                
            })
        },
        async Editar(id, costumer_id, lotery_id,detail_lotery){
            this.id = id,
            this.player = costumer_id
            this.lotery = lotery_id
            await axios.get(this.url+'winners/get_ball/'+ this.lotery)
            .then(response =>{
                this.balls_loteria = response.data.bolillas          
            })
            const found = this.balls_loteria.find(element => element.id == detail_lotery);
            this.selected = [found]
            this.createOrUpdate = false
            this.dialog = true
        },
        Anadir(){
            this.id = ''
            this.player = ''
            this.lotery = ''
            this.selected = []
            this.dialog = true
            this.createOrUpdate = true
            this.balls_loteria = []
        },
        async NewWinner(){
                let url_au
                let data = {
                    'costumer_id' : this.player,
                    'lotery_id' : this.lotery,
                    'detail_lotery' : this.selected[0].id
                }
                this.createOrUpdate ? url_au = this.url+'winners/createWinner' : url_au = this.url+'winners/updateWinner/'+this.id
                await axios.post(url_au, data)
                .then(async () =>{
                        await Swal.fire(
                        '¡añadido!',
                        'El registro ha sido modificado exitosamente',
                        'success'
                        ) 
                        this.player = ''
                        this.lotery = ''
                        this.selected = []
                        this.balls_loteria = []
                        this.dialog = false
                        await this.mostrar()
                })
                .catch(error => {
                    alert(error)
                }) 
            
             
            
        },
        async get_balls(){
            await axios.get(this.url+'winners/get_ball/'+ this.lotery)
            .then(response =>{
                this.balls_loteria = response.data.bolillas          
            })
            if(this.lotery == 3){
                this.headers = [
                {
                    text: 'Fecha',
                    align: 'start',
                    sortable: false,
                    value: 'date',
                },
                { 
                    text: 'Bolillas', 
                    value: 'balls' 
                }
            ]
            }else{
                this.headers = [
                {
                    text: 'Fecha',
                    align: 'start',
                    sortable: false,
                    value: 'date',
                },
                { 
                    text: 'Bolillas', 
                    value: 'balls' 
                },
                {   text: 'Red Ball', 
                    value: 'ball_red' 
                },
                { 
                    text: 'Potenciador', 
                    value: 'potenciador' 
                },
                ]
            }
        },

        setArticles() {
            this.pages = []
            let numberOfPages = Math.ceil(this.penas.length / this.perPage);
            for (let i = 1; i <= numberOfPages; i++) {
            this.pages.push(i);
            }
        },
        paginate(cartillas) {
            let page = this.page;
            let perPage = this.perPage;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            return cartillas.slice(from, to);
        },
        pagination_section(pagine){
            let forPaginate = this.pageForSection
            let flag = this.pageFlag
            let from = (flag * forPaginate) - forPaginate;
            let to = (flag * forPaginate)
            return pagine.slice(from,to)
        },
        pintar(value){
            if(value == this.page){
            return true
            }else{
            return false
            }
        },
    },
    watch: {
      penas(){
        this.setArticles();
      }
    },
    computed: {
      penas_prueba: function () {
        return this.paginate(this.penas)
      },
      
      pages_prueba:function(){
        //let cant = this.pages.length
        let forPaginate = this.pageForSection
        let flag = this.pageFlag
        let from = (flag * forPaginate) - forPaginate;
        let to = (flag * forPaginate);
        return this.pages.slice(from,to)
      }
    }
}
</script>

<style>
.select{
   height: 50px !important;
   color: rgba(91, 108, 143, 1) !important;
}
.input{
    height: 51px !important;
    border-radius: 5px !important;
}
.height-50{
      height: 51px !important; 
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    font-size: 16px !important;
    height: 50px;
}
.v-data-footer {
    font-size: 16px !important;
}
.v-data-footer__select .v-select__selections .v-select__selection--comma {
    font-size: 16px !important;
}
</style>