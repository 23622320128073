<template>
    <div class="col-md-12">
          <div class="tab-content margin-top-0">
              <div class="row margin-bottom-25">
                <div class="col-md-12">
                  <h1>Editar Loteria</h1>
                </div>
              </div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="dashboard-list-box margin-top-0" id="completadoForm">
                      <div class="dashboard-list-box-static">
                        <div id="lista-detalle" class="container" >
                          <div class="row with-forms">
                            <div class="col-md-12 margin-top-20">
                              <div class="row with-forms">
                                <div class="col-md-12 margin-top-15">
                                  <div class="col-md-12" style="display: flex; text-align: center; justify-content: center;">
                                    <img id="image" :src="lottery.image" alt="your image" style="height: 300px; width: 500px; object-fit: contain;" />
                                  </div>
                                    <input class="input-file margin-top-30" type="file" id="file" ref="myFiles" v-on:change="previewFiles()">
                                </div>
                                <div class="col-md-6">
                                  <label>Nombre: </label>
                                  <div class="input-with-icon medium-icons">
                                    <input name="id_producto" type="hidden" value="">
                                    <input required class="text-input" name="nombre" v-model="lottery.name" type="text" disabled>
                                  </div>
                                </div> 
                                <div class="col-md-6">
                                  <label>Nombre de bolilla:</label>
                                  <div class="input-with-icon medium-icons">
                                      <input required class="text-input" name="sub_nombre" v-model="lottery.sub_name" type="text">
                                    
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <label>Números:</label>
                                  <div class="input-with-icon medium-icons">
                                    <input required class="text-input" name="maximo" v-model="lottery.size" type="number">
                                    
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <label>Bolilla Especial:</label>
                                  <div class="input-with-icon medium-icons">
                                    <input required class="text-input" name="sub_numeros" v-model="lottery.sub_length" type="number">
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <label>Intentos/dia:</label>
                                  <div class="input-with-icon medium-icons">
                                    <input required class="text-input" name="intents" v-model="lottery.intents" type="number">
                                    
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <label>Fecha de Apertura:</label>
                                  <div class="input-with-icon medium-icons">
                                    <input  type="date" v-model="lottery.date_opening">
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <label>Descripcion:</label>
                                  <div class="input-with-icon medium-icons">
                                    <textarea required class="text-input" name="descripcion" v-model="lottery.description"  ></textarea>

                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <label>Dias jugados:</label>
                                  <div class="input-with-icon medium-icons">
                                    <div class="checkboxes in-row margin-bottom-20" style="display: flex;">
                                      <b-form-group>
                                        <b-form-checkbox-group id="checkbox-group-1" v-model="days_selected" :options="options" name="flavour-1"></b-form-checkbox-group>
                                      </b-form-group>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div>
                                    <label >Multiplicador tradicional</label>
                                    <b-form-checkbox v-model="lottery.type" name="check-button" switch>
                                    </b-form-checkbox>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div>
                                    <label >Multiplicador Repechaje</label>
                                    <b-form-checkbox v-model="lottery.playoff_multiplier" name="check-button" switch>
                                    </b-form-checkbox>
                                  </div>
                                </div>
                                <button style="padding: 12px 50px;"  @click="edit()" class="button margin-top-20 margin-bottom-20 margin-right-20">Guardar</button>
                                <router-link style="padding: 12px 50px;" class="button margin-top-20 margin-bottom-20"
                                      :to="{ tag: 'a',  path: `/admin/loterias`}"> Cancelar
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
          </div>
    </div>
</template>

<script>
import sweet from 'sweetalert2'
import moment from 'moment'
import {mapActions, mapState} from "vuex";
export default {
    name: 'EditLottery',
    data() {
      return {
        days_selected: [],
        photos: [],
        checked_traditional: false,
        checked_play_off: false,
        type : true,
        options: [
          { text: 'Lunes', value: 1 },
          { text: 'Martes', value: 2 },
          { text: 'Miercoles', value: 3 },
          { text: 'Jueves', value: 4 },
          { text: 'Viernes', value: 5 },
          { text: 'Sabado', value: 6 },
          { text: 'Domingo', value: 0 },
        ]
      }
    },
    async mounted(){
      await this.getLottery({ id: this.$route.params.id } )
      this.days_selected = JSON.parse(this.lottery.days)
    },
    computed: {
      ...mapState({
        lottery: state => state.lottery.lottery,
        updated: state => state.lottery.updated
      }),
    },
    methods:{
      ...mapActions({
        editLottery: 'editLottery',
        getLottery: 'getLottery'
      }),
      format_date(value){
         if (value) {
           return moment(  value ).format('YYYY-MM-DD');
          }
      },
      edit(){
          let lottery = {
            id: this.lottery.id,
            name: this.lottery.name,
            sub_name: this.lottery.sub_name,
            description: this.lottery.description,
            image: [...this.photos][0],
            days: JSON.stringify(this.days_selected),
            size: this.lottery.size,
            sub_length: this.lottery.sub_length,
            date_opening: this.lottery.date_opening,
            type: this.lottery.type,
            playoff_multiplier: this.lottery.playoff_multiplier
          }
          const formData = new FormData();
          for (const prop in lottery) {
            formData.append(prop, lottery[prop]);
          }
          this.editLottery(formData)
          //if (this.updated) {
              sweet.fire({
                position: 'center',
                icon: 'success',
                title: '¡Registro actualizado exitosamente!',
                showConfirmButton: true,
              })
              this.back()
          //}
        },
        previewFiles(){

          const photos = this.$refs.myFiles.files;
          for (const photo of photos) {
            const reader = new FileReader();
            this.preview = [];
            reader.addEventListener('load', () => {
              this.preview.push(reader.result);
            });
            reader.readAsDataURL(photo);
          }
          this.photos = photos;
          this.lottery.image = URL.createObjectURL(photos[0]);
          this.type = false
        },
      back(){
        this.$router.push(`/admin/loterias/`)
      },
    },

}
</script>

<style scoped>
.input-file{
  line-height: inherit;
}
input:focus{
  opacity: 0 !important;
}
fieldset {
  border-width: 0;
  border-style: hidden !important;
  padding: 0;
  margin: 0;
}
</style>