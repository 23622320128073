<template>
  <td >
    <div  :key="i" v-for="(prize, i) in prizes" >
      <template v-if="parseInt(type_result) === 0">
          <div style="display: flex">
            <div class=" item-prize">
              <input type="text" class="form-control" placeholder="Nivel"  v-model="prize.prize_level" style="width: 170px">
            </div>
            <div class=" item-prize">
              <input type="text" placeholder="Ganadores*"  v-model="prize.lottery_winner" style="width: 130px">
            </div>
            <div class=" item-prize">
              <input type="number" @keyup.enter="updatePlayPrize(i, prize.lottery_prize, enhancer)" placeholder="Premio" v-model="prize.lottery_prize" style="width: 170px">
            </div>
            <div class=" item-prize">
              <input type="text" placeholder="Ganadores*" v-model="prize.play_winner" style="width: 130px">
            </div>
            <div class=" item-prize">
              <input type="text" placeholder="Premio*" v-model="prize.play_prize"  >
            </div>
            <div class=" fm-close">
              <a class="delete">
                <i style="color: red; padding: 22px 0 0 5px;" class="fa fa-remove" @click="remove(prize,i)"></i>
              </a>
            </div>
          </div>
        </template>
        <template v-if="parseInt(type_result) === 1 " >

          <div style="display: flex">
            <div class="item-prize" >
              <input type="text" placeholder="Nivel"  v-model="prize.prize_level" style="width: 270px">
            </div>
            <div class="item-prize">
              <input type="text" placeholder="1"  v-model="prize.lottery_winner" style="width: 200px">
            </div>
            <div class="item-prize">
              <input type="text" placeholder="222.000.000" v-model="prize.lottery_prize" style="width: 270px">
            </div>
            <div >
              <div class="fm-close">
                <a class="delete">
                  <i style="color: red; padding: 22px 0 0 5px;" class="fa fa-remove" @click="remove(prize,i)"></i>
                </a>
              </div>
            </div>
          </div>

        </template>

   </div>
  </td>
</template>

<script>
import {mapActions} from "vuex";
export default {
  name: "item",
  data(){
    return {
      detail: []
    }
  },
  props: {
    prizes: Array,
    type: String,
    type_result: Number,
    enhancer: Number,
    lottery_name: String
  },
  methods: {
    ...mapActions({
      deletePrizeLevel: 'deletePrizeLevel',
      deletePrizeLevelCreate: 'deletePrizeLevelCreate'
    }),
    mounted(){

      this.detail = this.prizes.sort((a,b) => {return a.position - b.position;})
    },
    updatePlayPrize(i, lottery_prize, enhancer) {
      if (enhancer) {
        this.prizes[i].play_prize = lottery_prize *  enhancer ;
      }
    },
    remove(item, i){
      if (this.type === 'create') {
        this.deletePrizeLevelCreate(i)
      } else {
         this.deletePrizeLevel(item,i)
      }
    }
  }
}
</script>
<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
input {
  background: white !important;
  padding: 0 15px !important;
  margin:  0 5px 5px 0 !important;
}
.item-prize {
  padding-bottom: 0;
  padding-top: 5px;
}
</style>