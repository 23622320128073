<template>
  <div>
    <div class="container" >
      <highcharts :options="chartOptions"  class="content-table-mode"></highcharts>
    </div>

    <div>
      <div :class="{ dark: picked }"  @mousedown="moveSuggestion" @mouseup="stopSuggestion"  id="figure_mode" style="
          display: flex;
          cursor: move;
          flex-direction: column;
          align-items: center;
          box-shadow: black 0 0 3px;
          border-radius: 6px;
          z-index: 1;
          right: 25px;
          padding: 4px;
          width: 250px;
          background: white;
          top: 185px;
          position: absolute;
        " class="content-legend">
        <div>
          <p class="styleTextOne" style="text-align: center">LEYENDA</p>
        </div>
        <div style="display: flex; flex-direction: column">
          <div>
            <div>  Bolilla: <span>{{ indicators.ball }}</span> </div>
            <div> Cantidad: <span>{{indicators.count}}</span> </div>
            <div>
              Ultima Aparicion:
              <ul>
                <li style="list-style: none" >
                  <div style="
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                      " >
                    <div style=" display: flex; flex-wrap: wrap; justify-content: space-evenly; margin: 5px 0; ">
                      <div class="numeroConstellationStyle" style="width: 150px !important">
                        <p class="styleTextTwo">{{ indicators.date }}</p>
                      </div>
                    </div>
                    <div class="copiarStyleForecast content-legend-search" style="padding: 8px;">
                      <div style="font-size: 20px; cursor: pointer" @click="sendDate(indicators.date)">
                        <i class="fas fa-search" style="margin-left: 5px !important;"></i>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import analyticAPI from "../../../../api/analytic";
import {mapMutations, mapState} from "vuex";
import {EventBus} from "@/plugins/event-bus";

export default {
  name: "Mode",
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      indicators: {
        ball: '-',
        count: '-',
        date: '-'
      },
      appearances: [],
      chartOptions: {
        chart: {
          type: "column",
          spacingBottom: 15,
          spacingTop: 0,
          spacingLeft: 0,
          spacingRight: 0,
          backgroundColor: this.picked

          // width: 1400,
         // height: 400,
        },
        legend: {
          enabled: true,
          layout: "horizontal",
          reversed: false,
          lineHeight: 40,
          x: 0,
          y: 0,
          itemStyle: { color: '#b68c64' }
        },
        plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                mouseOver: (e) => {
                  if (this.appearances.length > 0) this.indicators.date = this.appearances.filter( (item) => item.ball === parseInt(e.target.category))[0].date;
                  this.indicators.ball = e.target.category;
                  this.indicators.count = e.target.y;
                },
              },
            },
          },
        },
        xAxis: {
          categories: null,
          labels: {
            style: {
              color: null
            }
          }
        },
        yAxis: {
          categories: null,
          title: {
            text: "CANTIDAD",
            style: {
              color: null
            }
          },
          labels: {
            style: {
              color: null
            }
          }
        },
        title: { text: "MODA DE LOS ULTIMOS 3 MESES" ,  style: { color: "#b68c64" } },
        series: null,
      },
    };
  },
  computed: {
    ...mapState({
        picked: state => state.costumer.picked,
        theme_dark: state => state.costumer.theme_dark,
        theme_light: state => state.costumer.theme_light,
    }),
  },
  mounted() {
    this.init();
    EventBus.$on('change_theme', this.eventBusTheme);

},
  props: {
    type: {
      type: Number
    }
  },
  methods: {
    ...mapMutations({
      DATE_APPEARANCE: 'DATE_APPEARANCE',
      NAVIGATE_TAB_PRINCIPAL:'NAVIGATE_TAB_PRINCIPAL' 
    }),
    eventBusTheme() {
      this.chartOptions.xAxis.labels.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
      //this.chartOptions.xAxis.title.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
      this.chartOptions.yAxis.labels.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
      this.chartOptions.yAxis.title.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
    },
    moveSuggestion (e){
      let figure_mode = document.getElementById("figure_mode");

      let left = parseInt( window.getComputedStyle(figure_mode).getPropertyValue("left") );
      let top = parseInt( window.getComputedStyle(figure_mode).getPropertyValue("top") );
      let mouseX = e.clientX;
      let mouseY = e.clientY;

      document.onmousemove = function (e) {
        let dx = mouseX - e.clientX;
        let dy = mouseY - e.clientY;
        figure_mode.style.left = left - dx + "px";
        figure_mode.style.top = top - dy + "px";
      };
    },
    stopSuggestion: function(){
      // console.log('stopSuggestion');
      document.onmousemove = null;
    },
    toTourMode() {
      this.$nextTick(() => {
        const tour = this.$shepherd({
          useModalOverlay: true,
          cancelIcon: {
            enabled: true
          },
          classes: 'class-1 class-2',
          scrollTo: { behavior: 'smooth', block: 'center' }
        });
        tour.addStep({
          attachTo: { element: ".content-table-mode", on: 'right' },
          text: 'Grafico donde muestra las bolillas de moda de los ultimos 3 meses',
          classes: 'shepherd-theme-arrows',
          buttons: [
            {
              text: 'Sig.',
              action: tour.next
            }
          ],
        });
        tour.addStep({
          attachTo: { element: ".content-legend", on: 'left' },
          text: 'Al pasar por el grafico automaticamente mostrara el detalle de cada bolilla como la ultima fecha que aparecio la bolilla y la cantidad.',
          classes: 'shepherd-theme-arrows',
          buttons: [
            {
              action: tour.back,
              classes: 'shepherd-button-secondary',
              text: 'Ant.'
            },
            {
              text: 'Sig.',
              action: tour.next
            }
          ]
        });
        tour.addStep({
          attachTo: { element: ".content-legend-search", on: 'left' },
          text: 'Con este boton podras ver la cartilla en el modulo resultados',
          classes: 'shepherd-theme-arrows',
          buttons: [
            {
              action: tour.back,
              classes: 'shepherd-button-secondary',
              text: 'Ant.'
            },
            {
              text: 'Hecho',
              action: tour.complete
            }
          ]
        });
        tour.start();
      });
    },
    async sendDate(searchParams) { 
      this.NAVIGATE_TAB_PRINCIPAL(0);
      this.DATE_APPEARANCE(searchParams);
    },
    async init() {
      const { data: data } = await analyticAPI.findModa({
        name: this.$route.params.name,
        id: this.$route.params.id,
        limit: 10,
        type: this.type,
      });

      this.chartOptions.xAxis.categories = data.labels;
      this.chartOptions.series = data.data;
      this.appearances = data.appearances;

    },
    mobile(){ 
                /* Storing user's device details in a variable*/
                    let details = navigator.userAgent;
            
                    /* Creating a regular expression 
                    containing some mobile devices keywords 
                    to search it in details string*/
                    let regexp = /android|iphone|kindle|ipad/i;
            
                    /* Using test() method to search regexp in details
                    it returns boolean value*/
                    let isMobileDevice = regexp.test(details);
            
                    if (isMobileDevice) {
                        return true;
                    } 
                    return false;
                
            }
  },
};
</script>

<style  >
.highcharts-series-group {
  width: 100%;
}
.content-table-mode {
  padding-top: 15px;
}

@media screen and (max-width: 1368px) {
  .container {
    width: 1000px !important;
  }
}

</style>