<template>
  <div id="page" class="hfeed site" :class="{ dark: picked }" style="height: 100vh">
    <SidebarMobile></SidebarMobile>
    <Header></Header>
    <router-view :key="$route.fullPath" class="is-overflow"></router-view>
    <SidebarRight></SidebarRight>
  </div>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import $ from "jquery";

  //import Footer from "./include/Footer";
  import Header from "./include/Header";
  import SidebarMobile from "./include/SidebarMobile";
  //import SidebarMobileBottom from "./include/SidebarMobileBottom";
  import SidebarRight from "./include/SidebarRight";
  export default {
    name: "frontend-main",
    components: {
    //  Footer,
      Header,
      SidebarRight,
      SidebarMobile,
      //SidebarMobileBottom,
    },
    computed: {
      ...mapState({
        costumer: (state) => state.costumer.costumer,
        picked: state => state.costumer.picked,
        theme_dark: state => state.costumer.theme_dark,
        theme_light: state => state.costumer.theme_light,
      }),
    },
    mounted() {
      this.init();
    },
    methods: {
      ...mapActions({
        createLotery: "createLotery",
      }),
      init() {
        $(".ic-mobile-menu-button").off("click");
        $(".ic-mobile-menu-button").on("click", () => {
          $("#page").addClass("menu-mobile-active");
        });
        $(".ic-mobile-menu-close-button").off("click");
        $(".ic-mobile-menu-close-button").on("click", () => {
          $("#page").removeClass("menu-mobile-active");
        });
        if ($(".ts-menu nav.mobile-menu .ts-menu-drop-icon").length > 0) {
          $(".ts-menu nav.mobile-menu .sub-menu").hide();
        }
        $(".ts-menu nav.mobile-menu .ts-menu-drop-icon").on("click", function () {
          var is_active = $(this).hasClass("active");
          var sub_menu = $(this).siblings(".sub-menu");
          if (is_active) {
            sub_menu.slideUp(250);
            sub_menu.find(".sub-menu").hide();
            sub_menu.find(".ts-menu-drop-icon").removeClass("active");
          } else {
            sub_menu.slideDown(250);
          }
          $(this).toggleClass("active");
        });
      },
      initFlorida() {
        let oTable = document.getElementById("tableFloridas");
        let rowLength = oTable.rows.length;
        let data = [];

        for (let i = 0; i < rowLength; i++) {
          let oCells = oTable.rows.item(i).cells;
          let date = oCells.item(0).innerHTML;
          let val = oCells.item(1).innerHTML.split("-");

          let date1 = new Date(date.trim());
          let day = date1.getDate();
          let month = date1.getMonth() + 1;
          let year = date1.getFullYear();
          let fecha, ball_pot;

          let flat = val[5].split("x");

          if (flat.length === 1) {
            ball_pot = 1;
          } else {
            ball_pot = val[5].split("x")[1].split(" ")[0];
          }
          if (month < 10) {
            fecha = `${year}-0${month}-${day}`;
          } else {
            fecha = `${year}-${month}-${day}`;
          }
          let params = {
            date: fecha,
            ball_one: parseInt(val[0].trim()),
            ball_two: parseInt(val[1].trim()),
            ball_three: parseInt(val[2].trim()),
            ball_four: parseInt(val[3].trim()),
            ball_five: parseInt(val[4].trim()),
            ball_red: parseInt(val[5].trim()),
            potenciador: parseInt(ball_pot),
          };
          data.push(params);
        }
        //console.log(data)
        data.forEach(async (element) => {
          await this.createLotery(element);
        });
      },
      initMegamillions() {
        var oTable = document.getElementById("tablesMegamillions");
        var rowLength = oTable.rows.length;
        let data = [];
        let ball_pot;
        for (let i = 0; i < rowLength; i++) {
          var oCells = oTable.rows.item(i).cells;
          let date = oCells.item(0).innerHTML;
          let val = oCells.item(1).innerHTML.split("-");
          let ball_five = val[4].split("MB")[0];
          const ball_red = val[4].split("MB")[1].split("x")[0];
          let flat = val[4].split("MB")[1].split("x");
          if (flat.length == 1) {
            ball_pot = 1;
          } else {
            ball_pot = val[4].split("MB")[1].split("x")[1].split(" ")[0];
          }
          var date1 = new Date(date.trim());
          let day = date1.getDate();
          let month = date1.getMonth() + 1;
          let year = date1.getFullYear();
          let fecha;
          if (month < 10) {
            fecha = `${year}-0${month}-${day}`;
          } else {
            fecha = `${year}-${month}-${day}`;
          }
          let params = {
            date: fecha,
            ball_one: parseInt(val[0].trim()),
            ball_two: parseInt(val[1].trim()),
            ball_three: parseInt(val[2].trim()),
            ball_four: parseInt(val[3].trim()),
            ball_five: parseInt(ball_five.trim()),
            ball_red: parseInt(ball_red.trim()),
            potenciador: parseInt(ball_pot),
          };
          data.push(params);
        }
        //console.log(data)
        data.forEach(async (element) => {
          await this.createLotery(element);
        });
      },
      initPowerBall() {
        var oTable = document.getElementById("tablesPowerBall");
        var rowLength = oTable.rows.length;
        let data = [];
        let ball_pot;
        for (let i = 0; i < rowLength; i++) {
          var oCells = oTable.rows.item(i).cells;
          let date = oCells.item(0).innerHTML;
          let val = oCells.item(1).innerHTML.split("-");
          let ball_five = val[4].split("PB")[0];
          const ball_red = val[4].split("PB")[1].split("x")[0];
          let flat = val[4].split("PB")[1].split("x");
          if (flat.length == 1) {
            ball_pot = 1;
          } else {
            ball_pot = val[4].split("PB")[1].split("x")[1].split(" ")[0];
          }
          var date1 = new Date(date.trim());

          let day = date1.getDate();
          let month = date1.getMonth() + 1;
          let year = date1.getFullYear();
          let fecha;
          if (month < 10) {
            fecha = `${year}-0${month}-${day}`;
          } else {
            fecha = `${year}-${month}-${day}`;
          }
          let params = {
            date: fecha,
            ball_one: parseInt(val[0].trim()),
            ball_two: parseInt(val[1].trim()),
            ball_three: parseInt(val[2].trim()),
            ball_four: parseInt(val[3].trim()),
            ball_five: parseInt(ball_five.trim()),
            ball_red: parseInt(ball_red.trim()),
            potenciador: parseInt(ball_pot),
          };
          data.push(params);
        }
        //   console.log("data", data);

        data.forEach(async (element) => {
          await this.createLotery(element);
        });
      },
    },
  };
</script>

<style>
  @import url("../../assets/minify/1ebbd.css");
  @import url("../../assets/minify/38b85.css");
  @import url("../../assets/minify/4e58e.css");
  @import url("../../assets/minify/5dcd4.css");
  @import url("../../assets/minify/84c97.css");
  @import url("../../assets/minify/9cb6d.css");
  @import url("../../assets/minify/style.css");
  .table-dark {
    color: #fff;
    background: #1f2027 !important;
  }
  ol li, ul li {
    padding: 0 0 !important;
  }
  .cmn-frm .frm-group i {
    color: #85878f;
    position: relative;
    bottom: 55px !important;
    left: 20px;
  }
 a:hover  {
   color:#b68c64 !important
 }
</style>

 