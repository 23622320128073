<template> 
      <v-app>
          <div class="d-flex  flex-row  ">  
                 <p class="text-h4 mx-5 mt-5 mr-auto">Preguntas frecuentes</p>  
                 
                 <p class="mx-5 mt-5 ml-auto">Dashboard > Preguntas frecuentes</p>
          </div> 
          
          <v-main>
              <v-card class="mx-auto mt-5" color="transparent" max-width="1280" elevation="0">
                  <v-btn class="mx-5" :fab="false" dark color="#458ef3" @click="formNuevo()"> <v-icon>add</v-icon> </v-btn>
                   <v-simple-table class="mt-5 mx-5 rounded-lg">
                      <template v-slot:default>
                        <thead>
                            <tr class="purple darken-2">   
                                  <th h1 class="white--text  blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">Nº</th>
                                <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">CATEGORÍA</th> 
                                <th h1 class="white--text   blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >ACCIONES</th>  
                            </tr>
                        </thead>
                        <tbody>
                            <tr  v-for="(articulo, index) in articulos" :key="index">
                                <td class="text-center text-h5">{{index}}</td>
                                <td class="text-center text-h5">{{articulo.title}}</td>
                                <td class="text-center">
                          
                                  <!-- <router-link :to="{    tag: 'a', name:'question-list-id',  path: '/admin/questions/1'}"   >
                                    <v-btn class="blue" dark samll :fab="false">Administrar preguntas</v-btn>
                                     </router-link> -->
                                  <v-btn class="blue" dark samll :fab="false" @click="crearPreguntas(articulo.id)">Administrar preguntas</v-btn>
                                  <v-btn class="mx-5 orange"  dark samll :fab="false" @click="formEditar(articulo.id,articulo.title)"><v-icon>edit</v-icon></v-btn>
                                  <v-btn class="red" dark samll :fab="false" @click="borrar(articulo.id)"><v-icon>delete</v-icon></v-btn>
                                </td>
                            </tr>
                        </tbody>  
                      </template>
                  </v-simple-table>
              </v-card>
              
              <!-- Componente  de dialogo para CREAR y EDITAR -->
              <v-dialog v-model="dialog" max-width="500" >
                <v-card>
                  <v-card-title class="purple darken-4 white--text" v-if="operacion == 'crear'">Nueva categoría</v-card-title>
                  <v-card-title class="purple darken-4 white--text" v-if="operacion == 'editar'">Editar categoría</v-card-title>
                  <v-form>
                      <v-card-text>   
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label for="input-valid">Tema:</label>
                              </b-col>
                              <b-col sm="3" lg="9">
                                <div>
                                  <b-form-input v-model="articulo.name"  placeholder="Nombre del tema"></b-form-input>
                                </div> 
                              </b-col>
                            </b-row>  
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="dialog=false" color="blue-grey" dark>Cancelar</v-btn>
                        <v-btn @click="guardar()" type="Submit" color="blue accent-3" dark>Guardar</v-btn>
                      </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
          </v-main>
      </v-app> 
</template>
<script>
import axios from "axios";
import swal from 'sweetalert2';

// let url = 'https://new.ialotteryplay.com/subjects/list/';
let url = 'https://latam.ialotteryplay.com/subjects/list/';

export default {
    name:'QuestionList',  
    data() {
        return {        
            articulos : [],
            dialog : false,
            operacion : '',            
            articulo :{
                id : '',
                name : '' 
            }          
        }
       },
       created(){               
            this.mostrar()
       },  
       methods:{
            async mostrar(){
              let datos = await axios.get(url)
              //console.log(datos.data);
                this.articulos = datos.data;                   
            },
            async crear(){
                event.preventDefault();
                let parametros = {name:this.articulo.name};                
                await axios.post(url, parametros);
                // console.log(datos);
                this.mostrar();  
                this.articulo.id="";   
                this.articulo.name=""; 
            },                        
            async editar(){
                event.preventDefault();
                let parametros = {name:this.articulo.name};                        
                await axios.put(url+this.articulo.id, parametros);     
                this.mostrar();
                this.articulo.id="";   
                this.articulo.name=""; 
            },
            async borrar(id){ 
             swal.fire({
                title: '¿Confirma eliminar el registro?',   
                confirmButtonText: `Confirmar`,                  
                showCancelButton: true,                          
              }).then(async (result) => {                
                if (result.isConfirmed) {      
                      //procedimiento borrar
                      await axios.delete(url+id);
                      this.mostrar();  
                      this.articulo.id="";   
                      this.articulo.name=""; 
                      swal.fire('¡Eliminado!', '', 'success')
                } else if (result.isDenied) {        
                  //console.log('Cancelado');          
                }
              });              
            },
            guardar(){
              if(this.operacion=='crear'){
                this.crear();                
              }
              if(this.operacion=='editar'){ 
                this.editar();                           
              }
              this.dialog=false;                        
            }, 
            formNuevo() {
              this.dialog=true;
              this.operacion='crear';
              this.articulo.name='';    
            },
            formEditar(id, name){              
              this.articulo.id = id;
              this.articulo.name = name;                       
              this.dialog=true;                            
              this.operacion='editar';
            },
            crearPreguntas(id){
              //console.log(id);
              //  window.location.href = `http://localhost:8080/#/admin/questions/${id}`;
               window.location.href = `https://latam.ialotteryplay.com/#/admin/questions/${id}`;
            }
       }
}
// console.log(resOrder.data.links[1].href);  
//        
</script>

<style>
/* 
  .swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
    top: 2.375em;
    right: -0.5em;
    width: 2.9375em;
    transform: rotate(-45deg);
}

.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
    top: 2.875em;
    left: -0.1875em;
    width: 1.5625em;
    transform: rotate(45deg);
} */
</style>