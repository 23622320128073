<template>
    <div >
        <div v-if="existeSubscription === false" >
                <div>
                    <div> <p class="styleTextOne"> SUSCRÍBETE Y TEN ACCESO A : </p> </div>
                    <div>
                        <div style=" display: flex; flex-direction: column;" >
                            <div style="display: flex">
                                <div><i class="fas fa-gem"></i></div>
                                <div style="margin-left:4px"><p class="styleTextTwo">Menú de Resultados.</p> </div>
                            </div>
                            <div style="display: flex">
                                <div><i class="fas fa-gem"></i></div>
                                <div style="margin-left:4px"><p class="styleTextTwo">Menú de Analítica.</p> </div>
                                </div>
                            <div style="display: flex">
                                <div><i class="fas fa-gem"></i></div>
                                <div style="margin-left:4px"><p class="styleTextTwo">Menú de Pronóstico Mix.</p> </div>
                            </div>
                        </div>
                            <div>
                                <div style="background: #00974D; padding: 15px; border-radius: 5px; border: 5px solid white; cursor: pointer;display: flex; align-items: center; justify-content: center;" @click="activePay()">
                                <div style="color: white;"><i class="fas fa-bags-shopping"></i></div>
                                <div style="margin-left: 10px;"><h4 style="color: white; font-weight: 500;font-size: inherit;margin: 0px;">Suscribirse</h4></div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    <div v-if="existeSubscription === true">
         
        <div class="toogle-forecast  "  v-bind:class="{ close_toogle_forecast: isActiveToggle, open_toogle_forecast: !isActiveToggle }">
            <div  v-if="isShowFlecha"  v-bind:class="{ onFlecha: true }"  style=" position: absolute;right: 360px;display:flex; align-items: center; width: 200px;justify-content: center;"  >
                <div style="width: 70px" > <img style=" object-fit: cover" :src="'/uploads/indicator.png'"   title="Pulsar números jugados"   /> </div>
                <!-- <div class="rectacgulo"></div>
                <div class=" triangulo" ></div> -->
            </div>
            <div @click="isActiveToggle = !isActiveToggle; isShowFlecha = false" v-bind:class="{ onFlashToggle: isActiveFlashToggle }" style="box-shadow: 0px 0px 4px black;cursor: pointer;transform: rotate( 180deg); width: 40px; background: #11A51F; height: 230px; border-radius: 0px 10px 10px 0px; display: flex; justify-content: center; align-items: center;opacity: 0.84;" >
                <p  style="writing-mode: vertical-lr; text-orientation: use-glyph-orientation; margin: 0px 0 0px; font-weight:  bold; font-size: 18px; color:white; text-align: center;">
                    NÚMEROS JUGADOS
                </p>
            </div>
                <!-- height: 570px; -->
                <!-- 330 -->
                <!-- 370 -->
            <div  :style="tamanoToggle()" >
                    <div style="  position: absolute; right: 455px; top: 0px; display: flex;flex-direction: column;  ;z-index: -1;    align-items: flex-start;">
                            
                            <div  style="width: 48px !important;margin-bottom: 0px"  class="jugarPageOptions "   @click="toTour"  >
                                        <div ><p class="styleTextOne" >?</p>   </div>
                            </div> 
                            <div style="height:4px;"> </div>
                             
                            <div  style="width: 48px !important; margin-bottom: 0px"  class="jugarPageOptions tooltip-leyenda-pronostico"  @click="$bvModal.show('m-history')"  >
                                        <div ><i class="fas fa-book"></i></div> 
                            </div> 

                            <div style="height:8px;"> </div>  
                            <div  style="width: 48px !important; margin-bottom: 0px"  class="jugarPageOptions tooltip-boton-agregar" v-on:click="adicionarPronosticos()">
                                    <div ><i class="fas fa-plus"></i></div>
                            </div> 
                            <div style="height:4px" ></div>
                            <div  style="width: 48px !important; margin-bottom: 0px"  class="jugarPageOptions tooltip-boton-limpiar" v-on:click="  tapOption(4), selectCards()">
                                <div ><i class="fa fa-eraser"></i></div> 
                                
                            </div>
                            <div style="height:4px" ></div>   
                            <div  style="width: 48px !important; margin-bottom: 0px"  class="jugarPageOptions tooltip-boton-limpiar" v-on:click="  tapOption(5), selectCards()">
                                <div ><i class="fas fa-trash-alt"></i></div> 
                                
                            </div> 
                             

                        </div>

                <div style=" display: flex;justify-content: center; height: 85px">
                    <div >  <img :src="lotteryForecast.info.image" style="height: 100%; object-fit: cover" alt="">  </div>
                </div>
                <div style="position: absolute; overflow-x: auto; height: 49vh; display:flex; flex-direction: column; align-items: center;margin-bottom: 25px;margin-top: 25px;">
                    <!-- <div class="numerosSidebarStyle" v-for="(item, index) of numerosSidebar" :key="index"> -->
                    <div class="numerosSidebarStyle" v-for="(item, index9) of listNumbersToggle" :key="index9">
                        <div class="vinetaToggleStyle" >
                            <div><p style="margin: 0px 0 0px;font-weight: 700; font-size: larger;"> <i class="fas fa-ball-pile"></i> </p></div>
                        </div>
                        <div    >
                            <div><p style="margin: 0px 0 0px; "> {{formatoFecha(item.date_lottery)}} </p></div>
                        </div>
                        <div class="numeroToggleStyle" v-for="(item2, index10) of item.numbers" :key="index10">
                            <div><p style="margin: 0px 0 0px;font-weight: 700; font-size: larger;">{{item2}}</p></div>
                        </div> 
                        <div class="numeroToggleStyle" style="background: #ff3535 !important;" v-if="item.bExtra != null">
                            <div><p style="margin: 0px 0 0px;font-weight: 700; font-size: larger; color:white"> {{item.bExtra}} </p></div>
                        </div>
                        
                        <!-- <div class="vinetaToggleStyle" style="cursor: pointer;" v-on:click="deleteOneNumbers(index9,item.id)" >
                            <div><p style="margin: 0px 0 0px;font-weight: 700; font-size: larger;"> <i class="fas fa-times "></i> </p></div>
                        </div> -->
                        <div style="width:10px" ></div>
                        <div class="copiarStyleForecast" v-on:click="deleteOneNumbers(index9,item.id)">
                            <div style="cursor: pointer;padding: 0px 6px;background: rgb(236, 231, 231);border-radius: 6px;box-shadow: rgb(193 182 182) 5px 6px;" class="styleTextTwo"> <i class="fas fa-times "></i> </div>
                        </div>
                    </div>
                </div>
            </div>
                <div style="width: 392px; position: absolute; bottom: 0px;right: 0px; display: flex;flex-wrap: wrap; "  >
                    <div  class="opcionesEliminarTodo" style=" width:46% ;justify-content: center;background: #D9473A; padding: 10px;" v-on:click="deleteAllNumbers()" >
                        <div style="color: white;"><i class="fas fa-trash-alt"></i></div>
                        <div style="margin-left: 10px; "><p style="color: white;margin-bottom: 0px;"> Limpiar todo </p></div>
                    </div>
                    <!-- <div  class="opcionesEliminarTodo" style=" width:46% ;justify-content: center;background: #F7F7F7; padding: 10px;box-shadow: 0px 0px 2px black;" v-on:click="goCheckout()" >
                        <div style="color: black;"><i class="fas fa-shopping-cart"></i></div>
                        <div style="margin-left: 10px; "><p style="color: black;margin-bottom: 0px;"> Abrir carrito </p></div>
                    </div> -->
                    <div  class="opcionesEliminarTodo" style=" width:46% ;justify-content: center;background: #6D6E70; padding: 10px;" v-on:click="exportPDF()" >
                        <div style="color: white;"><i class="fas fa-file-alt"></i></div>
                        <div style="margin-left: 10px; "><p style="color: white;margin-bottom: 0px;font-size: 15px;"> Exportar PDF </p></div>
                    </div>
                    <div  class="opcionesEliminarTodo" style=" width:46% ;justify-content: center;background: #217446; padding: 10px;" v-on:click="exportExcel()" >
                        <div style="color: white;"><i class="fas fa-file-excel"></i></div>
                        <div style="margin-left: 10px; "><p style="color: white;margin-bottom: 0px;font-size: 14px;"> Exportar Excel </p></div>
                    </div>
                </div>
        </div>
                   
        <div style=" display: flex;  flex-direction: column;  ">
            <div>
                <p style="text-decoration-line: underline;" class="styleTextOne">Juegos</p>
            </div>
                        <div style="display:flex; flex-direction:row; flex-wrap: wrap">
                            <!--    <div class="jugarPageOptions tooltip-boton-pm" @click="selectedsCard ?  null: $bvModal.show('m-boot'), selectedsCard ?  null: tapOption(0)" >
                                  <div ><i class="fas fa-calendar-day"></i></div>
                                  <div style="margin-left: 10px;"><p class="styleTextOne">BOT</p></div>
                              </div>
                              <div style="width:8px" ></div>-->
                <div class="jugarPageOptions tooltip-pronostico-mayor"    @click=" selectedsCard ?  null: $bvModal.show('m-prognostic-forecast'), selectedsCard ? null:tapOption(1)" >
                    <div ><i class="fas fa-calendar-day"></i></div>
                    <div style=" margin-left: 10px;"><p class="styleTextOne">PRONÓSTICO MAYOR</p></div>
                </div>
                <div style="width:8px" ></div>
<!--                <div class="jugarPageOptions tooltip-boton-numerologia" @click="selectedsCard ?  null: show2=true,selectedsCard ?  null: tapOption(2)">
                    <div><i class="far fa-star"></i></div>
                    <div style="margin-left: 10px;"><p class="styleTextOne" >JUGAR CON NUMEROLOGÍA</p></div>
                </div>-->
<!--                <div style="width:8px" ></div>
                <div class="jugarPageOptions tooltip-boton-porcentaje" @click="selectedsCard ?  null: $bvModal.show('m-percentage'), selectedsCard ?  null: tapOption(3)">
                    <div><i class="fas fa-percentage"></i></div>
                    <div style="margin-left: 10px;"><p class="styleTextOne" >PRONÓSTICO PORCENTAJE</p></div>
                </div>-->
                <div style="width:8px" ></div>
                <div class="jugarPageOptions tooltip-boton-porcentaje" @click=" selectedsCard ?  null: zonePosible(11)">
                    <div><i class="fas fa-grip-horizontal"></i></div>
                    <div style="margin-left: 10px;"><p class="styleTextOne" >ZONAS POSIBLES</p></div>
                </div> 
            </div>
            <div>
                <p style="text-decoration-line: underline;" class="styleTextOne">Analítica</p>
            </div>
            <div style="display:flex; flex-direction:row; flex-wrap: wrap">
                <div @click="$bvModal.show('m-previous-plays')" class="jugarPageOptionsBot btn-boot tooltip-boton-jas"  style="background: #218838; display: flex; align-items: center; justify-content: center; width: 50px"  >
                        <p style="font-size: 13px;font-weight: 700;margin: 0px;color: white; ">JAS</p>
                </div> 
                <div  v-on:click="selectClickJA(index5)" :style="{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50px',     background: (selectedColorJa(index5))? '#294977' : ''}" v-for="(item, index5) in 10" :key="index5" :class="['jugarPageOptionsBot', 'btn-boot',  'tooltip-boton-ja'+item]"   > 
                        <p :style="{fontSize: '14px',fontWeight: 700,margin: '0px', color: (selectedColorJa(index5))? 'white': ''}">JA{{item}}</p>  
                </div>

                <button @click="eraserJAS( )" class="jugarPageOptionsBot btn-boot tooltip-boton-ja-borrador" style="background: #17a2b8; color: white; display: flex; align-items: center; justify-content: center; width: 50px"  >
                        <i aria-hidden="true" class="fa fa-eraser" style="font-size: 18px;"></i>
                        <p style="font-size: 12px;font-weight: 700;margin: 0px;"> </p>
                    </button>  
            </div> 
            <div>
                <p style="text-decoration-line: underline;" class="styleTextOne">Cartillas</p>
            </div>
            <div style="display:flex; flex-direction:row; flex-wrap: wrap" v-if="selectedsCard">
                <div   class="jugarPageOptions tooltip-boton-limpiar" v-on:click="functionSwitch()">
                    <div ><i class="fas fa-check"></i></div>
                    <div style="margin-left: 10px;"><p  class="styleTextOne">EJECUTAR</p></div>
                </div> 
                <div style="width:8px" ></div>
                <div   class="jugarPageOptions tooltip-boton-limpiar" v-on:click="selectCards()">
                    <div ><i class="fas fa-ban"></i></div>
                    <div style="margin-left: 10px;"><p  class="styleTextOne">CANCELAR</p></div>
                </div>  
            </div>
            <div class="content-cards-lottery" style="padding: 4px;" >
                <div style="display: flex;flex-direction: row;  flex-wrap: wrap">
                    <!-- ??? -->
                     <!-- TARJETA MOVIBLE DE SUGERENCIA -->
                    <div  v-if="showSuggestion" @mousedown="moveSuggestion" @mouseup="stopSuggestion" id="figur_1" style=" cursor: move;box-shadow: 0px 0px 10px black;position: absolute; background: white; width: 300px;  height: max-content; z-index: 3; border-radius: 5px; display: flex;flex-direction: column; text-align: center; padding: 10px;  ">
                            <div >
                                <div class="crossSuggestionCartillaJugar" v-on:click="closeSuggestionForecast()" >
                                <div><i class="fas fa-times-circle"></i></div>
                                </div>
                                
                                <div> <p class="styleTextTwoSugestion"  > ¿Desea agregar la siguiente sugerencia? </p> </div>
                            <div> <p class="styleTextTwo" > Sugerencias de pares con: {{numberSuggestion[0]}} </p> </div>
                
                                <div>
                                    <div style=" display: flex; justify-content: space-evenly; align-items: center;" v-for="(jugadas, index6) in suggestionJugadas" :key="index6" >
                                        
                                            <div style="display: flex;flex-wrap: wrap; justify-content: space-evenly; margin: 5px 0px;">
                                                <div class="numeroSuggestionStyle" v-for="(bolilla, index7) in jugadas.balls" :key="index7" >
                                                        <p class="styleTextTwo"> {{bolilla}}  </p>
                                                </div>
                                            </div>
                                            <div class="copiarStyleForecast" v-on:click="copySuggestionForecast(index6)">
                                                <div style=" font-size: 20px; cursor: pointer; padding: 3px 6px; background: #ece7e7; border-radius: 6px; box-shadow: 5px 6px #c1b6b6;"><p class="styleTextTwo"> Si </p> </div>
                                                
                                            </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                <div style="position: relative;display: flex;flex-direction: column; margin-right: 8px;" v-for="(item, index1) in procartillas.length" :key="index1"  >
                   <!-- CREAR CARTILLA --> 
                   <div v-if="!selectedsCard" :style="tamanoCross()">
                            <div class="crossCartillaJugar" v-on:click="cardDelete(index1)" >
                                <div><i class="fas fa-times-circle"></i></div> 
                            </div>
                        </div>
                        <div :style="tamanoCartilla()" v-if="selectedsCard" v-on:click="activeCard(index1)" >
                            <div v-if="procartillas[index1].selectedCard " style="displa:flex; justify-content: center">
                                <img src="/uploads/check2.png" style=" width: 100px;">
                            </div>
                            <div v-else style="displa:flex; justify-content: center">
                                Marcar cartilla
                            </div>
                        </div>
                        <div :id="(index1 == 0)?'cartilla':''" style=" z-index: 1; ">
                            <div class="cartillaSelectedJugar tooltip-cartilla-principal-pronostico"   :style="{width:validarLoteriaTest()}">
                                <div style="display:flex;flex-wrap: wrap;justify-content: center;padding-top: 8px; padding-bottom: 8px;position: relative; ">
                                    <!-- CREAR BOLILLAS -->
                                    <div class="numeros-test" :class="{desactiveBoton:jugarListCartillaTestStyle(i, index1)}"  v-for="(i, index) in ballForecast" :key="index" v-on:click="activeBallTest(index1,i)" >
                                            
                                            <div  :style=" validarColorAgrupaciones(i) " >  </div> 
                                            <!--CODE DINAMIC-->
                                            <div v-if="drawCardGroupJA( index1 ,i, 0)" style="position: absolute;z-index:1; position: absolute; z-index: 1; background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3"></i></div>
                                            <div v-if="drawCardGroupJA( index1 ,i, 2)" style="position: absolute;z-index:1; background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                            <div v-if="drawCardGroupJA( index1 ,i, 3)" style="position: absolute;z-index:1; background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3naranja" ></i> </div>
                                            <div v-if="drawCardGroupJA( index1 ,i, 1)" style="position: absolute;z-index:1; background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>

                                            <!--CODE DINAMIC-->
                                            <div v-if="activeCrossForecastHot(i, index1)"  > <i class="fas fa-times  activehot" ></i> </div>
                                            <div v-if="activeCrossForecastCold(i, index1)"  > <i class="fas fa-times  activecold" ></i> </div>
                                            <div v-if="activeCrossForecastPar(i, index1)"  > <i class="fas fa-times  activepar" ></i> </div>    
                                            <div v-if="activeCrossTest(i, index1)" > <i style="font-size: 48px; margin: 3px;" class="fas fa-times  activeCross" ></i> </div>
                                            <div v-if="activeNumeroTest(i, index1)" style="position: absolute;"> <p class="numero-test" >{{i}}</p> </div>
                                    </div>
                                </div>
                            </div>
                            <div style="height: 8px;" ></div>
                            <div class="cartillaSelectedJugar tooltip-cartilla-secundaria-pronostico" v-if="extraballForecast != 0" :style="{width:validarLoteriaTest(), paddingBottom: '8px'}">
                                <div><p style="text-align: center;font-size: 20px;  margin: 0px 0 0px; color: rgb(80, 100, 102);  font-weight: 600;"> {{subnameForecast}} </p></div>
                                <div style="display:flex;flex-wrap: wrap;justify-content: center;">
                                    <div class="numeros-test"  :class="{ desactiveBoton:jugarSubListCartillaTestStyle(i, index1) }" v-for="(i, index2) in extraballForecast" :key="index2" v-on:click="activeBallExtratest(index1,i)">   
                                        <div  :style=" validarColorAgrupacionesExtra(i) " >  </div>
                                            <!--DINAMIC CODE-->
                                                <div v-if="drawCardExtraGroupJAE( index1 ,i, 0)" style="position: absolute;background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class="  activeM3naranja" ></i> </div>
                                                <div v-if="drawCardExtraGroupJAE( index1 ,i, 3)" style="position: absolute;background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                                <div v-if="drawCardExtraGroupJAE( index1 ,i, 2)" style="position: absolute;background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                                <div v-if="drawCardExtraGroupJAE( index1 ,i, 1)" style="position: absolute;background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                            <!--DINAMIC CODE-->
                                        <div v-if="activeCrossExtraTest(i, index1)"> <i style="    font-size: 48px; margin: 3px;" class="fas fa-times  activeCross" ></i> </div>
                                        <div v-if="activeNumeroExtraTest(i, index1)"><p class="numero-test" >{{i}}</p></div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                         <div  style="height:16px" ></div>
                    
                </div> 
                
                
            </div>
            </div>             
            
                
                <div style=" margin-left: 20px; display: flex; flex-direction: column; align-items: center;" :style="{width:validarLoteriaTest()}">

                <div style="width: 100%;">  
                    <div> <p class="styleTextThree"  :class="{ dark: picked_ }">Tu apuesta</p></div>
                    <div style="display: flex; justify-content: space-between;">
                        <div > <p class="styleTextTwo" :class="{ dark: picked_ }">Tarifa del boleto   </p> </div>
                        <div > <p class="styleTextTwo" :class="{ dark: picked_ }"> $2.50</p>  </div>
                    </div>
                </div>
                </div>
            </div>
                    <!-- LISTA DE CARTILLAS SCROLL + BOTON JUGAR --> 
                      <div style="display: flex; width: 100%; justify-content: space-around; flex-direction: row; border-top-style: solid; border-color: rgb(90, 107, 141);">
                          <div class="informacionTarifaJugar">
                            <div style="display: flex;justify-content: space-around;"> <p class="styleTextThree" :class="{ dark: picked_ }">Monto total</p> </div>
                            <div style="display: flex;justify-content: space-around;">
                                <div> <p class="styleTextTwo" :class="{ dark: picked_ }"> {{countTotal}} - Tarifas de boleto: </p> </div>
                                <div> <p class="styleTextTwo" :class="{ dark: picked_ }">$ {{total.toFixed(2)}}</p> </div>
                            </div>
                          </div>
                      </div>
        </div>
       
                    <div>
                        <b-sidebar  :visible="showSidebar()"   id="sidebar-no-header" aria-labelledby="sidebar-no-header-title" right no-header shadow>
                        <template  >
                            <div style="padding:20px">
                                <div style="display: flex;justify-content: center; ">
                                <div >  <img :src="lotteryForecast.info.image" style="width: 265px; object-fit: cover" alt="">  </div>
                                </div>
                                <div style="display: flex;  justify-content: center;  margin-top: 5px">
                                <h1 id="sidebar-no-header-title" style="    color: rgb(41, 73, 119); font-weight: 500; font-size: revert;">Números seleccionados</h1>
                                </div>
                                <div style="display:flex; flex-direction: column; align-items: center;margin-bottom: 25px;margin-top: 25px;">
                                    <div class="numerosSidebarStyle" v-for="(item, index) of numerosSidebar" :key="index">
                                    
                                        <div class="vinetaSidebarStyle" >
                                            <div><p style="margin: 0px 0 0px;font-weight: 700; font-size: larger;"> <i class="fas fa-ball-pile"></i> </p></div>
                                        </div>
                                        <div class="numeroSidebarStyle" v-for="(item2, index2) of item.numbers" :key="index2">
                                            <div><p style="margin: 0px 0 0px;font-weight: 700; font-size: larger;"> {{item2}} </p></div>
                                        </div>
                                        <div class="numeroSidebarStyle" style="background: #ff3535 !important;" v-if="item.bExtra != null">
                                            <div><p style="margin: 0px 0 0px;font-weight: 700; font-size: larger; color:white"> {{item.bExtra}} </p></div>
                                        </div>
                                        
                                    </div>
                                </div>
                            <div class="opcionesEliminarTodo"   style="justify-content: center; background: #294977; padding: 10px;" v-on:click="irCarrito()" >
                                    <div style="color: white;"><i class="fas fa-shopping-cart"></i></div>
                                    <div style="margin-left: 10px; "><h4 style="color: white;margin-bottom: 0px;"> Abrir carrito</h4></div>
                            </div>
                            <!-- <b-button variant="primary" block @click="hide">Comprar ahora</b-button> -->
                            </div>
                        </template>
                        </b-sidebar>
                    </div>
               
                    <!-- -------------------------------------- MODAL HOROSCOPO -->
                    <b-modal v-model="show2"  title="Jugar con numerología" :header-bg-variant="headerBgVariant"  :header-text-variant="headerTextVariant" >
                        <b-container fluid>
                            <b-row class="mb-1 text-center" style="margin-bottom: 8px;">
                                <b-col cols="12">
                                <div class="modalRegenerarTitulo" style="">Ingrese los datos requeridos</div>
                                </b-col>
                            
                            </b-row>

                            <div style="display: flex;flex-wrap: wrap;flex-direction: row; justify-content: center;">
                                <div style=" width:100%">
                                    <div style="    display: flex;justify-content: space-between;flex-wrap: wrap;">
                                           
                                            <div class="form-group" style="margin-bottom: 0rem !important;width: 180px;">
                                                <label  style="margin-bottom: 0;">Nacimiento</label>
                                                <input type="date" class="form-control" v-model="date.birth">
                                            </div>
                                            <div class="form-group" style="margin-bottom: 0rem !important;width: 180px;">
                                                <label style="margin-bottom: 0;">Sorteo</label>
                                                <input type="date" class="form-control"  v-model="lotteryDate" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                            </div>
 
                                    </div>
                                    <div class="input-group-append" style="display: flex; justify-content: center;" > 
                                         <button class="btn-send" type="button" @click="selectCards(), show2=false">ENVIAR</button>
                                    </div>
                                </div>
                            </div>
                        </b-container>

                        <template #modal-footer>
                            <div class="w-100">
                                <div style="cursor: pointer;" @click="show2=false" class="float-right">
                                Cerrar
                                </div>
                            </div>
                        </template>
                    </b-modal>
                    
                    <!-- LA LEYENDA -->
                    <b-modal id="m-history" hide-footer title="LEYENDA">
                         <div class="d-block text-center">
                             <div>
                                 <div style="display: flex;flex-direction: column "  >
                                        <div  v-for="(item, index8) in leyenda" :key="index8" style="display: flex;  flex-direction: row; align-items: center; ">
                                            <div v-if="!item.cross" style="border-style: double;height:20px; width:30px; margin-right: 5px;" v-bind:style="{ background: item.color }" > </div>
                                            <div v-if="item.cross" style="border-style: double; width:30px; margin-right: 5px;font-size: 25px; text-align: center;" v-bind:style="{ color: item.color }" ><i class="fas fa-times "></i> </div>
                                            <div style="width: 265px;"> <p class="styleTextTwo"  style="font-size: 15px; font-weight: 700;"> {{ item.name }}</p> </div>
                                        </div>
                                    </div>
                             </div>
                         </div>

                         <div style="display: flex; justify-content: flex-end">
                             <b-button class="mt-3" variant="outline-gray"   @click="$bvModal.hide('m-history')">Cerrar</b-button> 
                         </div>
                     </b-modal>
                    <!--MODALS PERCENTAGE FORECAST BOOT-->
                     <b-modal id="m-boot" hide-footer title="Escoja un BOT">
                         <div class="d-block text-center">
                             <div>
                                 <div  style="display:flex; justify-content: center; margin-top: 10px;flex-wrap: wrap;" >
                                     
                                     <div style="padding: 4px;" class="jugarPageOptionsBot btn-boot" v-for=" (boot, key) in boot_buttons" v-bind:key="'boot_buttons' + key" v-on:click="selectedPMounthForest(key)" :class="[boot_buttons[key].isSelected ? 'active' : '']">
                                         <div ><i class="fas fa-dice"></i></div> 
                                         BOT {{ boot.key }}
                                         <div style="margin-left: 2px;"><p class="styleTextOne" style="font-size: 13px;"></p></div>
                                     </div>
                                 </div>
                             </div>
                         </div>

                         <div style="display: flex; justify-content: flex-end">
                             <b-button class="mt-3" variant="outline-gray"   @click="$bvModal.hide('m-boot')">Cerrar</b-button>
                             <b-button class="mt-3" variant="outline-success"   id='btnSuccessBoot' @click="selectCards"  >Confirmar</b-button>
                         </div>
                     </b-modal>
                     <b-modal id="m-percentage" hide-footer title="Eliga una opción">
                         <div class="d-block text-center">
                             <div>
                                 <div class="btn-group" data-toggle="buttons-radio"  style="display:flex; justify-content: center; margin-top: 10px;flex-wrap: wrap;" >
                                     <div class="jugarPageOptionsBot btn-percentage  " v-for=" (percentage, key) in percentage_buttons" v-bind:key="'percentage_buttons' + key" v-on:click="pPercentageForecast(percentage.key)" :class="[percentage_buttons[key].isSelected ? 'active' : '']"   >
                                         <div ><i class="fas fa-dice"></i></div>
                                         OPCIÓN {{ percentage.key }}
                                     </div>
                                 </div>
                             </div>
                         </div>
                         <div class=" " style="display: flex; justify-content: flex-end">
                             <b-button class="mt-3" variant="outline-gray"   @click="$bvModal.hide('m-percentage')">Cerrar</b-button>
                             <b-button class="mt-3" variant="outline-success"   id='btnSuccessPercentage' @click="selectCards() ">Confirmar</b-button>
                         </div>
                     </b-modal>
                     <b-modal id="m-prognostic-forecast"   title="PRONÓSTICO (Mayor Probabilidad)">
                         <loading :active.sync="isLoadingForecast" :is-full-page="fullPageForecast" style="top: -50px !important;"/>
                         <div class="d-block text-center">
                             <div>
                                 <label> Número de jugadas anteriores</label>
                                 <select v-model="play_number" class="form-control">
                                     <option value="0" selected >JA1</option>
                                     <option value="1">JA2</option>
                                     <option value="2">JA3</option>
                                     <option value="3">JA4</option>
                                     <option value="4">JA5</option>
                                     <option value="5">JA6</option>
                                     <option value="6">JA7</option>
                                     <option value="7">JA8</option>
                                     <option value="8">JA9</option>
                                     <option value="9">JA10</option>
                                 </select>
                                 <label> Números a obtener </label>
                                 <input type="number"   v-model="number_count" :min='0' :max='5'   :maxlength="1" @input="validateInput()" />
                                 Desea obtener el número PowerBall de las jugadas anteriores
                                 <div class="custom-checkbox-forecast">
                                     <b-form-checkbox v-model="checked_extra" name="check-button" switch>
                                     </b-form-checkbox>
                                 </div>
                             </div>
                         </div>
                         <div class=" " style="display: flex; justify-content: flex-end">
                             <b-button class="mt-3" variant="outline-gray" @click="$bvModal.hide('m-prognostic-forecast')">Cerrar</b-button>
                             <b-button class="mt-3" variant="outline-success " id='btnSuccessForecast' @click="selectCards">Confirmar</b-button>
                         </div>
                     </b-modal>
                     <b-modal id="m-previous-plays" title="Jugadas Anteriores">
                         <div class="d-block text-center">
                             <div class="" style="display: flex; flex-direction: column; align-items: center">
                                 <button class="btn btn-success mb-2" style="width: 250px" @click="$bvModal.show('m-plays-count')"> Numero de Fechas</button>
                                 <button class="btn btn-success mb-2" style="width: 250px" @click="$bvModal.show('m-plays-range')"> Rango</button>
                             </div>
                         </div>
                         <div class=" " style="display: flex; justify-content: flex-end">
                             <b-button class="mt-3" variant="outline-gray" id="btnClosePlayPrevious"  @click="$bvModal.hide('m-previous-plays')">Cerrar</b-button>
                          </div>
                     </b-modal>
                     <b-modal id="m-plays-range" title="Jugadas Anteriores">
                         <loading :active.sync="isLoadingRange" :is-full-page="fullPageRange" style="top: -50px !important;"/>
                         <div class="d-block text-center">
                             <div style="display: flex; flex-direction: column; align-items: center">
                               <div class="mb-3">
                                 <p v-if="errors.length" style="margin: 0 0 0px;">
                                   <b >Por favor, corrija el(los) error(es):</b>
                                 <ul style="margin-top:10px; margin-bottom:10px">
                                   <li style="list-style-type: none;" v-for="(error, key) in errors" v-bind:key="'error' + key">
                                     <label for="">{{ error }}</label></li>
                                 </ul>
                                 </p>
                               </div>
                                 <div class="mb-3">
                                   <label class="form-label" style="text-align: left;">Desde*</label>
                                   <date-picker
                                           :class="[ errors.from.state ? 'play-range-error' : '']"
                                           v-model="plays.from"
                                           :disabled-date="disabledRange">
                                   </date-picker>
                                    <label class="error-label" v-if="errors.from.state" :class="[ errors.from.state ? 'play-range-error' : '']">
                                     {{errors.from.value}}
                                    </label>
                                     <!--<input type="date" class="form-control" v-model="plays.from" >-->
                                 </div>
                                 <div class="mb-3">
                                     <label class="form-label" style="text-align: left;">Hasta*</label>
                                     <date-picker
                                            :class="[ errors.from.state ? 'error' : '']"
                                             v-model="plays.to"
                                             :disabled-date="disabledRange">
                                     </date-picker>
                                     <label class="error-label" v-if="errors.to.state" :class="[ errors.to.state ? 'play-range-error' : '']">
                                       {{errors.to.value}}
                                     </label>

                                 </div>

                             </div>

                         </div>
                         <div class=" " style="display: flex; justify-content: flex-end">
                             <b-button class="mt-3" variant="outline-gray" @click="$bvModal.hide('m-plays-range')">Cerrar</b-button>
                             <b-button class="mt-3" variant="outline-success " id="btnConfirmPlayRange" @click="confirmPlayRange">Confirmar</b-button>
                         </div>
                     </b-modal>
                     <b-modal id="m-plays-count" title="Jugadas Anteriores">
                         <loading :active.sync="isLoadingCount" :is-full-page="fullPageCount" style="top: -50px !important;"/>
                         <div>
                             <div class="d-block text-center">
                                 <div class="" style="display: flex; flex-direction: column; align-items: center">
                                     Seleccione el numero de jugadas anteriores
                                     <input :min='1' :max='10' :maxlength="2" type="number" v-model="plays.count" @input="validateInputJAS()">
                                 </div>
                             </div>
                             <div class=" " style="display: flex; justify-content: flex-end">
                                 <b-button class="mt-3" variant="outline-gray" @click="$bvModal.hide('m-plays-count')">Cerrar</b-button>
                                 <b-button class="mt-3" variant="outline-success " id="btnConfirmPlayCount"  @click="confirmPlayCount">Confirmar</b-button>
    
                             </div>
                         </div>
                     </b-modal>
                    <!--MODALS PERCENTAGE FORECAST BOOT-->
           </div>
</template>

<script>
    import analyticAPI from "../../../../api/analytic";
    import { EventBus }  from '../../../../plugins/event-bus';
    import { strings }  from '../../../helpers/strings';

    import { mapActions, mapState,mapMutations } from 'vuex';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    import Excel from 'exceljs'
    import {saveAs} from "file-saver";
    import Logo from '../../../../../public/uploads/logoPdf.png'
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'

    import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css' 
    import VueCookie from 'vue-cookie'
    import swal from 'sweetalert2';
    import moment from 'moment' 
    export default {
        name: 'PronosticoPage',
        components: {  
            DatePicker, 
        },
        data() {
            return {
                cartillasForecast: [], 
                selectedsCard:false,
                jaSelected:-1,
                errors: {
                  from: {
                    value: 'El campo desde es requerido',
                    state: false
                  },
                  to:  {
                    value: 'El campo hasta es requerido',
                    state: false
                  }
                },
                result_last: {},
                plays: {
                    from:'',
                    to:'',
                    count: null
                },
                percentage_buttons: [
                    { isSelected: false, key: 1 },
                    { isSelected: false, key: 2 },
                    { isSelected: false, key: 3 },
                ],
                boot_buttons: [
                    { isSelected: false, key: 0 },
                    { isSelected: false, key: 1 },
                    { isSelected: false, key: 2 },
                    { isSelected: false, key: 3 },
                    { isSelected: false, key: 4 },
                    { isSelected: false, key: 5 },
                    { isSelected: false, key: 6 },
                    { isSelected: false, key: 7 },
                ],
                result_listForecast: {},
                selectFunction:null, 
                mBoot: null,
                mPercentage:null,
                checked_extra: false,
                number_count:0,
                play_number: 0,
                desk:false,
                date: { birth: '2021-08-04', lottery: '2021-08-04' },
                statusClickGroupJA: [
                    { status: false, click: 0 },
                    { status: false, click: 0 },
                    { status: false, click: 0 },
                    { status: false, click: 0 },
                    { status: false, click: 0 },
                    { status: false, click: 0 },
                    { status: false, click: 0 },
                    { status: false, click: 0 },
                    { status: false, click: 0 },
                    { status: false, click: 0 },
                    { status: false, click: 0 },
                ],
                clicksJA10: 0,
                statusClickAgrupacionJA10: false ,
                clicksJA9: 0,
                statusClickAgrupacionJA9:false ,
                clicksJA8: 0,
                statusClickAgrupacionJA8:false ,
                clicksJA7: 0,
                statusClickAgrupacionJA7:false ,
                clicksJA6: 0,
                statusClickAgrupacionJA6:false ,
                clicksJA5: 0,
                statusClickAgrupacionJA5:false ,
                clicksJA4: 0,
                statusClickAgrupacionJA4:false ,
                clicksJA3: 0,
                statusClickAgrupacionJA3:false ,
                clicksJA2: 0,
                statusClickAgrupacionJA2:false ,
                clicksJA1: 0,
                statusClickAgrupacionJA1:false ,
                statusClickAgrupacionOjo: false,
                statusClickAgrupacionS2: false,
                statusClickAgrupacionS3:false,
                statusClickAgrupacionS4:false ,
                clicksS4: 0,
                clicksS3: 0,
                clicksS2 : 0,
                clicksOjo: 0,
                firstClickTime : 0,
                clicksNeeded : 2,
                timeSpan : 1000,

                isShowFlecha: false,
                isActiveFlashToggle: false,
                isActiveToggle:true,
                displayPM: false, //PARA VISUALIZAR LOS 3 BOTONE DE PM
                displayPP: false, //PARA VISUALIZAR LOS BOTONES DE PRONOSTICO PORCENTAJE
                disabled: true,
                show4:false,
                selectedOjito:[], //LOS SELECCIONADOS DESPUES DEL FILTRO DE MAS CALIENTES
                ojitoList:{}, //LOS NUMEROS DE LA ULTIMA JUGADA
                stateLeyenda:false,
                leyenda: [],
                
                showSuggestion:false,
                showAnaliticaAparition:false,
                numberAnaliticaAparition: 0,
                numberSuggestion: [],
                numerosSidebar:[],
                delete:false,
                show: false, //Modal de ESTADISTICA
                show2: false, //Modal de horóscopo
                show3: false, //Modal pago
                variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'],
                headerBgVariant: 'primary',
                headerTextVariant: 'light',
                bodyBgVariant: 'light',
                bodyTextVariant: 'dark',
                footerBgVariant: 'light',
                footerTextVariant: 'dark',
                
                paginadorForecast:0,
                r: 0,
                stateSidebar:false,
                procartillas:[  ], //Las nuevas cartillas aleatorias
                max:69,
                min:1,
                maxExtra:26,
                temporal:[], //Mantiene un grupo de resultados
                temporalhot:[],
                temporalcold:[],
                temporalPar:[],
                stateCartilla: 0,
                carritoForecast:[],
                showAleatotio:0,
                total: 0,
                countTotal:0,
                modal_list:[]
            };
        },
        computed: {
            ...mapState({
                lottery_: state => state.lottery.lottery,
                lotteryLast: state => state.lottery.lotteryLast,
                vcartillas: state => state.lottery.vcartillas,
                existeSubscription: state =>state.subscription.existeSubscription,
                suggestionJugadas: state => state.forecast.suggestionJugadas,
                // ???
                lotteryForecast: state => state.forecast.lotteryForecast,
                birthday: state => state.forecast.birthday,
                
                ballForecast :state => state.forecast.ballForecast,//cantidad principal ejem 69
                extraballForecast: state => state.forecast.extraballForecast, //cantidad secundaria ejem 25
                columnasForecast:state => state.forecast.columnasForecast,
                numberBallsForecast:state => state.forecast.numberBallsForecast,
                
                subnameForecast: state =>       state.forecast.subnameForecast ,
                descriptionForecast: state =>       state.forecast.descriptionForecast,
                nombreForecast: state =>        state.forecast.nombreForecast ,
                listColdForecast: state =>      state.forecast.listColdForecast ,
                listHotForecast: state =>       state.forecast.listHotForecast ,
                numbers_frequency: state =>       state.forecast.numbers_frequency ,

                pares :state => state.forecast.pares,
                procartillasNormal: (state)=> state.forecast.procartillasNormal,
                procartillasLaggards: (state)=> state.forecast.procartillasLaggards,
                listNumbersToggle: (state)=> state.forecast.listNumbersToggle,
                picked_: state => state.costumer.picked,

            }),
             lotteryDate:{
                get(){
                    return this.$store.state.lottery.lotteryLast
                },
                set( value ){
                    this.$store.commit('UPDATE_LOTTERY_DATE', value)
                }
            }
        },

        async mounted() {
            this.result_last = await this.getLotteryResult({
                id: this.$route.params.id,
                name: this.$route.params.name,
                type: 1
            })
        },
        methods: {
            ...mapMutations({
                RELOAD_PAGINADOR: 'RELOAD_PAGINADOR',
                ONE_NOTIFICATION: 'ONE_NOTIFICATION',
                SHOW_MODAL_NOTIFICATION:'SHOW_MODAL_NOTIFICATION',
                SHOW_ANALITICA_RESULTADO: 'SHOW_ANALITICA_RESULTADO', //REDIRECCIONA A ANALITICA
                BALL_APPEARANCE: 'BALL_APPEARANCE',  //ENVIA UN NUMERO PARA ANALITICA,
                BALL_APPEARANCE_PRONOSTICO: 'BALL_APPEARANCE_PRONOSTICO',
                CLEAR_LISTNUMBERSTOGGLE: 'CLEAR_LISTNUMBERSTOGGLE'
                
            }),
            ...mapActions({
                listOneCheckout:'listOneCheckout', 
                getLotteryResult: "getLotteryResult",
                listForecast:'listForecast',
                listForecastRange: 'listForecastRange'
            }),
            selectCards(){
                this.$root.$emit('bv::hide::modal', 'm-boot', '#btnSuccessBoot');
                this.$root.$emit('bv::hide::modal', 'm-prognostic-forecast', '#btnSuccessForecast');
                this.$root.$emit('bv::hide::modal', 'm-percentage', '#btnSuccessPercentage');
                if ( this.selectedsCard ) {
                    this.selectCardsOff();
                } else {
                    this.selectedsCard = true;    
                }  
            },  
            async selectCardsOff(){
                this.selectedsCard = false;  
                
                await this.takePlayForecast();

                for (let i = 0; i < this.procartillas.length; i++) {
                    this.procartillas[i].selectedCard = false;
                    
                }
            },
            tapOption(option){ 
                this.selectFunction = option;
            },
            eraserJAS(){ 
                this.jaSelected = -1;
            },
            validateInputJAS() {
                let regex = /^[+]?\d+(\d+)?$/
                if(!regex.exec(this.plays.count)) this.plays.count = null
            },
            async confirmPlayRange(){
               this.isLoadingRange = true
               setTimeout(async () => {
                   if (this.plays.from && this.plays.to) {
                       let data = await this.listForecastRange({
                           id: this.$route.params.id,
                           name: this.$route.params.name,
                           type: 0,
                           from: moment(this.plays.from).format('YYYY-MM-DD'),
                           to: moment(this.plays.to).format('YYYY-MM-DD')
                       })  
                       this.plays.count = null
                       this.$root.$emit('bv::hide::modal', 'm-previous-plays', '#btnConfirmPlayRange')
                       this.$root.$emit('bv::hide::modal', 'm-plays-range', '#btnConfirmPlayCount')
 
                    this.lotteryForecast.ja[10] = data.previous_plays[data.previous_plays.length-1]
                    this.lotteryForecast.jae[10] = data.previous_plays_extra[data.previous_plays_extra.length-1]
                    this.jaSelected = 10; 

                   } else {
        
                       this.errors.from.state = null
                       let myElements = document.querySelectorAll(".mx-input");
        
                       if (!this.plays.from)  {
                           myElements[0].style.setProperty('border', '1px solid red', 'important');
                           this.errors.from.state = true
                       } else {
                           myElements[0].style.setProperty('border', '1px solid #ccc', 'important');
                           this.errors.from.state = false
                       }
                       if (!this.plays.to) {
                           myElements[1].style.setProperty('border', '1px solid red', 'important');
                           this.errors.to.state = true
                       } else {
                           myElements[1].style.setProperty('border', '1px solid #ccc', 'important');
                           this.errors.to.state = false
                       }
                   }
                   this.isLoadingRange = false
               }, 2000)
            },
             async confirmPlayCount() {  
                 this.isLoadingCount = true
                 let data = await  this.listForecast({
                    id: this.$route.params.id,
                    name: this.$route.params.name,
                    type: 0,
                    limit: this.plays.count
                })
              
               
    
                  this.$root.$emit('bv::hide::modal', 'm-previous-plays', '#btnClosePlayPrevious')
                  this.$root.$emit('bv::hide::modal', 'm-plays-count', '#btnConfirmPlayCount')
                  this.isLoadingCount = false
                  this.lotteryForecast.ja[10] = data.previous_plays[data.previous_plays.length-1]
                    this.lotteryForecast.jae[10] = data.previous_plays_extra[data.previous_plays_extra.length-1]
                    this.jaSelected = 10; 
            },
            

             async zonePosible( i ) {  

                if (i != this.jaSelected ) {
                    this.isLoadingCount = true
                  
                  this.$root.$emit('bv::hide::modal', 'm-previous-plays', '#btnClosePlayPrevious')
                  this.$root.$emit('bv::hide::modal', 'm-plays-count', '#btnConfirmPlayCount')
                  this.isLoadingCount = false 
                var rangeB = [];
                var rangeBextra = [];
                var rangeA = [];
                // var rangeAextra = [];
                for (let i = 0; i < this.lotteryForecast.ja[5].length; i++) {
                    const element = this.lotteryForecast.ja[5][i];
                        rangeB.push(element.balls);       
                } 
                

                for (let i = 0; i < this.lotteryForecast.ja[0].length; i++) {
                    const element = this.lotteryForecast.ja[0][i];
                    rangeA.push(element.balls);
                } 

                

                // console.log("rangeB: ");
                // console.log(rangeB);
                // console.log(rangeBextra);
                // console.log("rangeA: ");
                // console.log(rangeA);
                // console.log(rangeAextra);

                // console.log(this.ballForecast);
                // console.log(this.extraballForecast);

                // ------------------------- validacion del medio-----------------------------------
                var outputNew0 = [];
                var output0 = []; 
                var start = 17;
                var zoneJa1object0 =[];
                        while ( start <= 47 ) {
                            // Array.from({length: max - min + 1}, (v, i) => i + min);
                            outputNew0 = Array.from({length: (start + 2) - start + 1}, (v, i) => i + start);
                            
                            output0 = output0.concat(outputNew0);
                            start =  start + 7;
                        } 

                var JA0 = [];
                    for (let elemento of this.lotteryForecast.ja[0]) {  
                            JA0.push(elemento.balls);
                    }

                var count0 = 0;
                for (let i = 0; i < output0.length; i++) {
                    const element = output0[i];
                    if ( JA0.indexOf(element) != -1   ) {
                        count0++;
                    }
                    
                }
                // console.log("output: ", output0);
                // console.log("count : ", count0);
                if (count0 > 3) {
                    console.log("aa");
                    for (let i = 1; i <= this.ballForecast; i++) {
                         if (output0.indexOf(i) == -1) {
                            zoneJa1object0.push({
                                    balls:i,
                                    count: 0
                                })
                         }
                        
                    }
                     this.lotteryForecast.ja[11] = zoneJa1object0;
                    this.lotteryForecast.jae[11] = [];
                    this.jaSelected = 11; 

                    this.lotteryForecast.jae[11] = [];
                  
 
                  if (this.extraballForecast != 0) {
                            
                        rowNums = 0;
                    columnNum = 0;
                    columnMiddle = 0;
                    rowMiddle = 0;
                    cuadrante = [];
                    rowInitialOne = 1;
                    final =   this.extraballForecast;
                    columnNum = 7;
                    
                    if (final % columnNum != 0) {
                        final = final + (columnNum - (final % columnNum))
                    }
                    rowNums = final/columnNum; 
                    rowMiddle = rowNums / 2; 
                    columnMiddle = Math.round(columnNum/2);  //4
                    // console.log("rowNums: ", rowNums);
                    // console.log("columnMiddle: ", columnMiddle);

                     //PRIMER CUADRANTE 
                        outputNew = [];
                        output = [];
                        while ( rowInitialOne <= columnNum * rowNums ) {
                            // Array.from({length: max - min + 1}, (v, i) => i + min);
                            outputNew = Array.from({length: (rowInitialOne + (columnMiddle - 2)) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 
                    //2DO CUADRANTE 
                        outputNew = [];
                        output = [];
                        rowInitialOne = 1 + (columnMiddle - 1)
                        while ( rowInitialOne <= columnNum * rowNums ) {
                            outputNew = Array.from({length: (rowInitialOne + (columnMiddle - 1)) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 
                        // console.log("cuadrante", cuadrante);
                        //zone

                        // ----------------------------------------
                        // console.log("this.lotteryForecast.jae[0], ", this.lotteryForecast.jae[5]);
                        var contarv1 = 0;
                        var contarv2 = 0;
                        for (let i = 0; i < this.lotteryForecast.jae[5].length; i++) {
                            const element = this.lotteryForecast.jae[5][i];
                            rangeBextra.push(element.balls); 
                            if (cuadrante[0].indexOf(element.balls) != -1) {
                                contarv1 = contarv1 + 1 + element.count; 
                            }

                            if (cuadrante[1].indexOf(element.balls) != -1) {
                                contarv2 = contarv2 + 1 + element.count;
                            }
                        }  
                        // ----------------------------------------




                        zoneJa1object = [];
                        for (let elemento of cuadrante[(contarv1 > contarv2) ? 1 : 0 ]) { 
                            zoneJa1object.push({
                                balls:elemento,
                                count: 0
                            })
                        }  
                        console.log("zoneJa1object");
                        this.lotteryForecast.jae[11] = zoneJa1object;
                  
                  } 

                    return;
                }
                var rowNums = 0;
                    var columnNum = 0;
                    var columnMiddle = 0;
                    var rowMiddle = 0;
                    var cuadrante = [];
                    rowInitialOne = 1;
                    final =   this.ballForecast;
                    columnNum = 7;
                    
                    if (final % columnNum != 0) {
                        final = final + (columnNum - (final % columnNum))
                    }
                    rowNums = final/columnNum; 
                    rowMiddle = rowNums / 2; 
                    columnMiddle = Math.round(columnNum/2);  //4
                    // console.log("rowNums: ", rowNums);
                    // console.log("columnMiddle: ", columnMiddle);

                outputNew0 = [];
                output0 = []; 
                start = 1;
                zoneJa1object0 =[];
                var v = 7;

                var fila0 = 1; 
                        while ( start <= this.ballForecast ) {
                            // Array.from({length: max - min + 1}, (v, i) => i + min);
                            if ( fila0 == 1 || fila0 == rowNums ) {
                                outputNew0 = Array.from({length: (start + columnNum - 1) - start + 1}, (v, i) => i + start);
                                output0 = output0.concat(outputNew0);
                                start =  start + 7;
                            }else{
                                output0 = output0.concat([start, start + columnNum - 1 ]);
                                start =  start + 7;
                            } 
                            fila0++;
                        } 

                        // console.log("output02: ", output0);
                 count0 = 0;
                for (let i = 0; i < output0.length; i++) {
                    const element = output0[i];
                    if ( JA0.indexOf(element) != -1   ) {
                        count0++;
                    }
                    
                }

                if (count0 > 3) {
                    for (let i = 1; i <= this.ballForecast; i++) {
                         if (output0.indexOf(i) == -1) {
                            zoneJa1object0.push({
                                    balls:i,
                                    count: 0
                                })
                         }
                        
                    }
                     this.lotteryForecast.ja[11] = zoneJa1object0;
                    this.lotteryForecast.jae[11] = [];
                    this.jaSelected = 11;
                    
                    this.lotteryForecast.jae[11] = [];
                  
 
                  if (this.extraballForecast != 0) {
                            
                        rowNums = 0;
                    columnNum = 0;
                    columnMiddle = 0;
                    rowMiddle = 0;
                    cuadrante = [];
                    rowInitialOne = 1;
                    final =   this.extraballForecast;
                    columnNum = 7;
                    
                    if (final % columnNum != 0) {
                        final = final + (columnNum - (final % columnNum))
                    }
                    rowNums = final/columnNum; 
                    rowMiddle = rowNums / 2; 
                    columnMiddle = Math.round(columnNum/2);  //4
                    // console.log("rowNums: ", rowNums);
                    // console.log("columnMiddle: ", columnMiddle);

                     //PRIMER CUADRANTE 
                        outputNew = [];
                        output = [];
                        while ( rowInitialOne <= columnNum * rowNums ) {
                            // Array.from({length: max - min + 1}, (v, i) => i + min);
                            outputNew = Array.from({length: (rowInitialOne + (columnMiddle - 2)) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 
                    //2DO CUADRANTE 
                        outputNew = [];
                        output = [];
                        rowInitialOne = 1 + (columnMiddle - 1)
                        while ( rowInitialOne <= columnNum * rowNums ) {
                            outputNew = Array.from({length: (rowInitialOne + (columnMiddle - 1)) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 
                        // console.log("cuadrante", cuadrante);
                        //zone

                        // ----------------------------------------
                        // console.log("this.lotteryForecast.jae[0], ", this.lotteryForecast.jae[5]);
                          contarv1 = 0;
                          contarv2 = 0;
                        for (let i = 0; i < this.lotteryForecast.jae[5].length; i++) {
                            const element = this.lotteryForecast.jae[5][i];
                            rangeBextra.push(element.balls); 
                            if (cuadrante[0].indexOf(element.balls) != -1) {
                                contarv1 = contarv1 + 1 + element.count; 
                            }

                            if (cuadrante[1].indexOf(element.balls) != -1) {
                                contarv2 = contarv2 + 1 + element.count;
                            }
                        }  
                        // ----------------------------------------




                        zoneJa1object = [];
                        for (let elemento of cuadrante[(contarv1 > contarv2) ? 1 : 0 ]) { 
                            zoneJa1object.push({
                                balls:elemento,
                                count: 0
                            })
                        }   
                        this.lotteryForecast.jae[11] = zoneJa1object;
                  
                  } 
                  
                    return;
                }
                // ------------------------------------------------------------
                i = 1;
                var rows = [];
                while ( i <= this.ballForecast) { 
                    // console.log("???part1");
                    var bandera = true; 
                     v = 7; 
                    var range = 0;
                    var rowInitialOne = 0;
                    var zoneAdd = 1;
                    var output = [];
                    var outputTemp = [];
                    var outputNew = [];

                    var inicio = i - (v + zoneAdd);
                    var final = i + (v + zoneAdd);
                        // CUADRANTE VIABLE ?
                        if ( rangeA.indexOf(i) == -1 && inicio >= 1 && final <= this.ballForecast && (i % 7) != 0  && (i % 7) != 1  ) {
                            // console.log("i = ",i);
                            while (bandera) {
                            //    console.log("???part2");
                                rowInitialOne = inicio;
                                range = ((i - v) + zoneAdd) - inicio;
                                // CUADRANTE DETECTADO
                                
                                
                                while ( rowInitialOne < final ) {
                                    outputNew = Array.from({length: (rowInitialOne + range) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                                    // console.log("output: ", output);
                                    // console.log("outputNew: ",outputNew);
                                    output = output.concat(outputNew);
                                    rowInitialOne =  rowInitialOne + 7;
                                }
                                outputTemp.push(output);
                                // console.log("outputTemp: ",outputTemp);
                                // console.log("CUADRANTE: ", output );
                                // CUADRANTE SIN JA1 ?
                                if (!output.some(valorABuscar => rangeA.includes(valorABuscar) && inicio >= 1 && final <= this.ballForecast)) {
                                //    console.log("CUADRANTE LIMPIO DE: ", rangeA );
                                    zoneAdd++;
                                    v = v * 2;
                                    inicio = i - (v + zoneAdd);
                                    final = i + (v + zoneAdd);
                                    if (inicio < 1 || final > this.ballForecast) {
                                        bandera = false; 
                                        output = output.reduce((acc,item)=>{
                                            if(!acc.includes(item)){
                                            acc.push(item);
                                            }
                                            return acc;
                                        },[])
                                        rows.push(output)
                                    }
                                } else {
                                    bandera = false;
                                    if ( zoneAdd == 1 ) {
                                        rows.push([i])
                                    } else {
                                        output = outputTemp[zoneAdd - 2].reduce((acc,item)=>{
                                                if(!acc.includes(item)){
                                                acc.push(item);
                                                }
                                                return acc;
                                            },[])
                                        rows.push(output)
                                        // console.log("output local: ", output);
                                    } 
                                }


                            } 
                        }else{
                            rows.push([i,-1])
                        }
                    i++;
                }

                // console.log("rows: ",rows); 

                // encontrar los arreglos maximos
                     let totales  = [];
                    for(let palabra of rows) {
                    totales.push(palabra.length);
                    }
                    
                    let maximo = Math.max.apply(null, totales);
                    
                    var rowsTop = [];

                    for (let elemento of rows) {
                        if (elemento.length === maximo) { 
                            rowsTop = rowsTop.concat(elemento);
                        }
                    }
                    var zoneJa1 =[];
                    zoneJa1 = rowsTop.reduce((acc,item)=>{
                                            if(!acc.includes(item)){
                                            acc.push(item);
                                            }
                                            return acc;
                                        },[])

                                                        
                    // console.log("rowsTop: ", zoneJa1); 
                    // console.log("zoneJa1object: ");
                    // console.log(zoneJa1object);

                    rowNums = 0;
                    columnNum = 0;
                    columnMiddle = 0;
                    rowMiddle = 0;
                    cuadrante = [];
                    rowInitialOne = 1;
                    final =   this.ballForecast;
                    columnNum = 7;
                    
                    if (final % columnNum != 0) {
                        final = final + (columnNum - (final % columnNum))
                    }
                    rowNums = final/columnNum; 
                    rowMiddle = rowNums / 2; 
                    columnMiddle = Math.round(columnNum/2);  //4
                    // console.log("rowNums: ", rowNums);
                    // console.log("columnMiddle: ", columnMiddle);

                    //PRIMER CUADRANTE 
                        outputNew = [];
                        output = [];
                        while ( rowInitialOne <= columnNum * rowMiddle ) {
                            outputNew = Array.from({length: (rowInitialOne + (columnMiddle - 1)) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 
                    //2DO CUADRANTE 
                        outputNew = [];
                        output = [];
                        rowInitialOne = 1 + (columnMiddle - 1)
                        while ( rowInitialOne <= columnNum * rowMiddle ) {
                            outputNew = Array.from({length: (rowInitialOne + (columnMiddle - 1)) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 
                    //3ER CUADRANTE 
                        outputNew = [];
                        output = [];
                        rowInitialOne = (final / 2) + 1
                        while ( rowInitialOne <= columnNum * rowNums ) {
                            outputNew = Array.from({length: (rowInitialOne + (columnMiddle - 1)) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 
                    //4TO CUADRANTE 
                        outputNew = [];
                        output = [];
                        rowInitialOne = (final / 2) + 1 + (columnMiddle - 1)
                        while ( rowInitialOne <= this.ballForecast) {
                            if (rowInitialOne + columnNum > this.ballForecast ) {
                                outputNew = Array.from({length: this.ballForecast - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            }else{
                                outputNew = Array.from({length: (rowInitialOne + (columnMiddle - 1)) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            }
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 
                        // console.log("cuadrante: ", cuadrante); 
                        // console.log("this.lotteryForecast.ja[5]: ", this.lotteryForecast.ja[5]); 
                         
                    //ASIGNAMOS VALORES DE JA6 A CADA CUADRANTE
                    // var cuadranteJA6 = [[],[],[],[]];
                    for (let elemento of this.lotteryForecast.ja[5]) { 
                        if (cuadrante[0].indexOf(elemento.balls) != -1) { 
                            // eliminamos numeros del cuadrante para obtener las zonas vacias de cada cuadrante
                            cuadrante[0] = cuadrante[0].filter((item) => item !== elemento.balls); 
                            
                        }
                        if (cuadrante[1].indexOf(elemento.balls) != -1) {  
                            cuadrante[1] = cuadrante[1].filter((item) => item !== elemento.balls); 
                        }
                        if (cuadrante[2].indexOf(elemento.balls) != -1) {  
                            cuadrante[2] = cuadrante[2].filter((item) => item !== elemento.balls); 
                        }
                        if (cuadrante[3].indexOf(elemento.balls) != -1) {  
                            cuadrante[3] = cuadrante[3].filter((item) => item !== elemento.balls); 
                        } 
                    } 
                    // console.log("cuadrante:", cuadrante);
                    var posibleZone = [[],[],[],[]];

                    for (let elemento of zoneJa1) { 
                        if (cuadrante[0].indexOf(elemento) != -1) {  
                            posibleZone[0].push(elemento) 
                        }
                        if (cuadrante[1].indexOf(elemento) != -1) {  
                            posibleZone[1].push(elemento) 
                        }
                        if (cuadrante[2].indexOf(elemento) != -1) {  
                            posibleZone[2].push(elemento) 
                        }
                        if (cuadrante[3].indexOf(elemento) != -1) {  
                            posibleZone[3].push(elemento) 
                        } 
                    }
                    // console.log("posibleZone", posibleZone);
                    var zone = 0;
                    var temp = [];
                    var arriba = true;
                    // -------------------------------


                    // la mitad de arriba esta mas vacia o la de abajo
                    rowNums = 0;
                    columnNum = 0;
                    columnMiddle = 0;
                    rowMiddle = 0;
                    cuadrante = [];
                    rowInitialOne = 1;
                    final =   this.ballForecast;
                    columnNum = 7;
                    
                    if (final % columnNum != 0) {
                        final = final + (columnNum - (final % columnNum))
                    }
                    rowNums = final/columnNum; 
                    rowMiddle = rowNums / 2; 
                    columnMiddle = Math.round(columnNum/2);  //4
                    // console.log("rowNums: ", rowNums);
                    // console.log("columnMiddle: ", columnMiddle);

                    //  CUADRANTE ARRIBA 
                        outputNew = [];
                        output = [];
                        while ( rowInitialOne <= columnNum * rowMiddle ) {
                            outputNew = Array.from({length: (rowInitialOne + (columnMiddle + 2)) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 
                    //  CUADRANTE  ABAJO
                        outputNew = [];
                        output = [];
                        rowInitialOne = (final / 2) + 1
                        while ( rowInitialOne <= this.ballForecast ) {
                            outputNew = Array.from({length: (rowInitialOne + (columnMiddle + 2)) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 

                        // console.log("cuadrante cuadrante: ", cuadrante);

                    var arribaCont = 0;
                    var abajoCont = 0;
                    for (let i = 0; i < this.lotteryForecast.ja[0].length; i++) {
                            const element = this.lotteryForecast.ja[0][i];
                           
                            if (cuadrante[0].indexOf(element.balls) != -1) {
                                arribaCont = arribaCont + 1 + element.count; 
                            }

                            if (cuadrante[1].indexOf(element.balls) != -1) {
                                abajoCont = abajoCont + 1 + element.count;
                            }
                        } 
                        // console.log("arribaCont", arribaCont);
                        // console.log("abajoCont", abajoCont);
                    arriba = (arribaCont > abajoCont) ? false : true;
                     
                        // ---------------------------

                        
                    if (arriba) {
                            for (let i = 0; i < posibleZone.length - 2; i++) {
                            if (posibleZone[i].length > temp.length) {
                                temp = posibleZone[i];
                                zone = i;
                            }
                            
                        }
                    }else{
                        for (let i = 2; i < posibleZone.length; i++) {
                            if (posibleZone[i].length > temp.length) {
                                temp = posibleZone[i];
                                zone = i;
                            }
                            
                        }
                    }
                    

                    var zoneJa1object = [];
                    // -------------------------------------------
                    rowNums = 0;
                    columnNum = 0;
                    columnMiddle = 0;
                    rowMiddle = 0;
                    cuadrante = [];
                    rowInitialOne = 1;
                    final =   this.ballForecast;
                    columnNum = 7;
                    
                    if (final % columnNum != 0) {
                        final = final + (columnNum - (final % columnNum))
                    }
                    rowNums = final/columnNum; 
                    rowMiddle = rowNums / 2; 
                    columnMiddle = Math.round(columnNum/2);  //4
                    // console.log("rowNums: ", rowNums);
                    // console.log("columnMiddle: ", columnMiddle);

                     //PRIMER CUADRANTE 
                        outputNew = [];
                        output = [];
                        while ( rowInitialOne <= columnNum * (rowMiddle + 2) ) {
                            // Array.from({length: max - min + 1}, (v, i) => i + min);
                            outputNew = Array.from({length: (rowInitialOne + (columnMiddle + 1)) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 
                    //2DO CUADRANTE 
                        outputNew = [];
                        output = [];
                        rowInitialOne = 1 + (columnMiddle - 3)
                        while ( rowInitialOne <= columnNum * (rowMiddle + 2) ) {
                            outputNew = Array.from({length: (rowInitialOne + (columnMiddle + 1)) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 
                    //3ER CUADRANTE 
                        outputNew = [];
                        output = [];
                        rowInitialOne = (final / 2) + 1 - (columnNum * 2);
                        while ( rowInitialOne <= columnNum * rowNums ) {
                            outputNew = Array.from({length: (rowInitialOne + (columnMiddle + 1)) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 
                    //4TO CUADRANTE 
                        outputNew = [];
                        output = [];
                        rowInitialOne = (final / 2) + 1 + (columnMiddle - 3)  - (columnNum * 2);
                        while ( rowInitialOne <= columnNum * rowNums ) {
                            outputNew = Array.from({length: (rowInitialOne + (columnMiddle + 1 )) - (rowInitialOne) + 1}, (v, i) => i + rowInitialOne);
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 
                        // console.log("cuadrante: ", cuadrante); 
                    // -------------------------------------------------
                    //zone
                    for (let elemento of cuadrante[zone]) { 
                        zoneJa1object.push({
                            balls:elemento,
                            count: 0
                        })
                    } 

                    // console.log("cuadranteJA6: ",cuadranteJA6);
                    // console.log("zoneJa1: ",zoneJa1);
                    // console.log("posibleZone: ",posibleZone);
                    // console.log("zone: ",zone);

                  this.lotteryForecast.ja[11] = zoneJa1object;
                  this.lotteryForecast.jae[11] = [];
                  
 
                  if (this.extraballForecast != 0) {
                            
                        rowNums = 0;
                    columnNum = 0;
                    columnMiddle = 0;
                    rowMiddle = 0;
                    cuadrante = [];
                    rowInitialOne = 1;
                    final =   this.extraballForecast;
                    columnNum = 7;
                    
                    if (final % columnNum != 0) {
                        final = final + (columnNum - (final % columnNum))
                    }
                    rowNums = final/columnNum; 
                    rowMiddle = rowNums / 2; 
                    columnMiddle = Math.round(columnNum/2);  //4
                    // console.log("rowNums: ", rowNums);
                    // console.log("columnMiddle: ", columnMiddle);

                     //PRIMER CUADRANTE 
                        outputNew = [];
                        output = [];
                        while ( rowInitialOne <= columnNum * rowNums ) {
                            // Array.from({length: max - min + 1}, (v, i) => i + min);
                            outputNew = Array.from({length: (rowInitialOne + (columnMiddle - 2)) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 
                    //2DO CUADRANTE 
                        outputNew = [];
                        output = [];
                        rowInitialOne = 1 + (columnMiddle - 1)
                        while ( rowInitialOne <= columnNum * rowNums ) {
                            outputNew = Array.from({length: (rowInitialOne + (columnMiddle - 1)) - rowInitialOne + 1}, (v, i) => i + rowInitialOne);
                            
                            output = output.concat(outputNew);
                            rowInitialOne =  rowInitialOne + columnNum;
                        } 
                        cuadrante.push(output) 
                        // console.log("cuadrante", cuadrante);
                        //zone

                        // ----------------------------------------
                        // console.log("this.lotteryForecast.jae[0], ", this.lotteryForecast.jae[5]);
                          contarv1 = 0;
                          contarv2 = 0;
                        for (let i = 0; i < this.lotteryForecast.jae[5].length; i++) {
                            const element = this.lotteryForecast.jae[5][i];
                            rangeBextra.push(element.balls); 
                            if (cuadrante[0].indexOf(element.balls) != -1) {
                                contarv1 = contarv1 + 1 + element.count; 
                            }

                            if (cuadrante[1].indexOf(element.balls) != -1) {
                                contarv2 = contarv2 + 1 + element.count;
                            }
                        }  
                        // ----------------------------------------




                        zoneJa1object = [];
                        for (let elemento of cuadrante[(contarv1 > contarv2) ? 1 : 0 ]) { 
                            zoneJa1object.push({
                                balls:elemento,
                                count: 0
                            })
                        }   
                        this.lotteryForecast.jae[11] = zoneJa1object;
                  
                  } 
                  this.jaSelected = 11; 

                }else{
                    this.jaSelected = -1;
                }


                  
            },

            drawCardGroupJA(index, i, condition){
                if (this.procartillas[index].ballGroupJA) {
                    for(let j = 0; j < this.procartillas[index].ballGroupJA.length; j++){
                        if(i === this.procartillas[index].ballGroupJA[j].balls && this.procartillas[index].ballGroupJA[j].count === condition ){
                            return true
                        }
                        if(i === this.procartillas[index].ballGroupJA[j].balls && this.procartillas[index].ballGroupJA[j].count >= condition ){
                            return true
                        }
                    }
                }
            },
            drawCardExtraGroupJAE(index, i, condition) {
                if (this.procartillas[index].ballGroupJAE) {
                    for(let j = 0; j < this.procartillas[index].ballGroupJAE.length; j++){
                        if(i === this.procartillas[index].ballGroupJAE[j].balls && this.procartillas[index].ballGroupJAE[j].count === condition){
                            return true
                        }
                        if(i === this.procartillas[index].ballGroupJAE[j].balls && this.procartillas[index].ballGroupJAE[j].count >= condition){
                            return true
                        }
                    }
                }
            },
            disabledRange: function (date) {
                const day = new Date(date).getDay()
                const days = JSON.parse(this.lottery_.days);
                const array = [0,1,2,3,4,5,6,7]
                let YY = parseInt(moment( (this.lottery_.date_opening)).format('YYYY'))
                let MM = parseInt(moment( (this.lottery_.date_opening)).format('MM')) - 1
                let DD = parseInt(moment( (this.lottery_.date_opening)).format('DD'))

                let YY_ = parseInt(moment( (this.result_last.date)).format('YYYY'))
                let MM_ = parseInt(moment( (this.result_last.date)).format('MM')) - 1
                let DD_ = parseInt(moment( (this.result_last.date)).format('DD'))

                let difference = array.filter(x => !days.includes(x));

                let [one, two, three, four, five] = difference

                return date > new Date(YY_, MM_, DD_)
                    || date < new Date(YY, MM, DD)
                    || day === one
                    || day === two
                    || day === three
                    || day === four ? five : one
                    || day === five ? five : one
            },
            validateInput() {
                let regex = /^([0-5]){1}$/
                if(!regex.exec(this.number_count) ) {
                    this.number_count = null
                }
            },
            
             
            confirmForecast() { 

                for (let index = 0; index < this.procartillas.length; index++) {
                    if (this.procartillas[index].selectedCard) {
                        this.procartillas[index].balls = [];
                        this.procartillas[index].ballhot = [];
                        this.procartillas[index].ballcold = [];
                        this.procartillas[index].ballpar = [];
                        this.procartillas[index].ballcartilla = [];
                        this.procartillas[index].bExtra = null;
                        this.procartillas[index].ballGroupJA = []
                        this.procartillas[index].ballGroupJAE = []  
                    }
                }  

                const play_number = parseInt(this.play_number); 
                let remaining = null
                let numbers = null
                
                let last_balls = ( this.lotteryForecast.ja.length - 1 >= play_number ) ? this.lotteryForecast.ja[play_number] : [];
                this.jaSelected = play_number;
                for (let q = 0; q < this.procartillas.length; q++) {
                    if (this.procartillas[q].selectedCard) {
                            this.isLoadingForecast = true
                           
                                let last_move = []
                                let last_move_extra = []
                                for (let i = 0; i < last_balls.length; i++ ) {
                                    last_move.push(last_balls[i].balls)
                                }
                
                                numbers =  [...last_move]
                                    .sort(()=> Math.random() > 0.5 ? 1: -1)
                                    .slice(0,  this.number_count);
                
                                let array_origin = Array.from({ length: parseInt(this.ballForecast) }, (v, k) => k+1);
                
                                for (let i = 0; i < last_move.length; i++) {
                                    let index = array_origin.indexOf(last_move[i]);
                                    if (index !== -1) {
                                        array_origin.splice(index, 1);
                                    }
                                }
                                let remaining_diff = 5 - parseInt(this.number_count)
                
                                remaining = [...array_origin]
                                    .sort(()=> Math.random() > 0.5 ? 1: -1)
                                    .slice(0,  remaining_diff);
                
                                this.procartillas[q].balls.push(...numbers)
                                this.procartillas[q].balls.push(...remaining)
                
                                let numbers_extra = null
                                let remaining_extra = null
                                let last_balls_extra = ( this.lotteryForecast.jae.length - 1 >= play_number ) ? this.lotteryForecast.jae[play_number] : [];
                
                                for (let i = 0; i < last_balls_extra.length; i++ ) {
                                    last_move_extra.push(last_balls_extra[i].balls)
                                }
                                numbers_extra =  [...last_move_extra]
                                    .sort(()=> Math.random() > 0.5 ? 1: -1)
                                    .slice(0,  1);
                
                                let array_origin_extra = Array.from({ length: parseInt(this.extraballForecast) }, (v, k) => k+1);
                                for (let i = 0; i < numbers_extra.length; i++) {
                                    let index = array_origin_extra.indexOf(numbers_extra[i]);
                                    if (index !== -1) {
                                        array_origin_extra.splice(index, 1);
                                    }
                                }
                                remaining_extra = [...array_origin_extra]
                                    .sort(()=> Math.random() > 0.5 ? 1: -1)
                                    .slice(0,  1);
                
                                if (this.checked_extra) {
                                    this.procartillas[q].bExtra = numbers_extra[0]
                                } else {
                                    this.procartillas[q].bExtra = remaining_extra[0]
                                }
                                this.isLoadingForecast = false
                                this.$root.$emit('bv::hide::modal', 'm-prognostic-forecast', '#btnSuccessForecast')
                           

                    }
                }
                this.selectCardsOff();
            },
            confirmPercentage() {
                 
                if (this.mPercentage) {
                    switch (this.mPercentage) {
                        case 1:
                            this.pPercentageColdModaHotForecast();
                            break;
                        case 2:
                            this.pPercentageColdHotForecast();
                            break;
                        case 3:
                            // this.procartillas[this.paginadorForecast].xJugada = 1;
                            this.pPercentageDetailThreeForecast();

                            break;
                        default:
                            break;
                    }
                }
                this.$root.$emit('bv::hide::modal', 'm-percentage', '#btnSuccessPercentage')
            },
            functionSwitch(){
                var band = false;
                for (let index = 0; index < this.procartillas.length; index++) {
                    if (this.procartillas[index].selectedCard) {
                            band = true;
                    }
                }
                if (!band ) {
                    swal.fire({
                        position: 'top-center',
                        icon: 'warning',
                        title: 'Tienes que seleccionar al menos una cartilla.',
                        showConfirmButton: false,
                        timer: 3000
                    })
                    return;
                }

                    switch (this.selectFunction) {
                        case 0:  
                            this.btnSuccessBoot()
                        break; 
                        case 1:
                            this.confirmForecast()
                        break;
                        case 2:
                             this.generateNumerología()
                        break;
                        case 3:
                             this.confirmPercentage()
                        break;
                        case 4:
                             this.limpiarJugar()
                        break;
                        case 5:
                            this.eliminateCards()
                        break; 

                        default:
                            break;
                    }
            },
            btnSuccessBoot() {
                 
                let pm  = this.mBoot;
                switch(pm) {
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        this.pMounthForecast(pm)
                        break;
                    case 6:
                        this.pMounthForecastExtra(true)
                        break;
                    case 7:
                        this.pMounthForecastExtra(false)
                        break;
                }
            },
            mobile(){
                /* Storing user's device details in a variable*/
                    let details = navigator.userAgent;
                    /* Creating a regular expression
                    containing some mobile devices keywords
                    to search it in details string*/
                    let regexp = /android|iphone|kindle|ipad/i;
                    /* Using test() method to search regexp in details
                    it returns boolean value*/
                    let isMobileDevice = regexp.test(details);
                    if (isMobileDevice) {
                        return true;
                    }
                    return false;
            },
            toTour() {
                const text= {
                    boton_jas:'Muestra los números entre un rango de jugadas o número de fechas.',
                    boton_ja1:'Muestra los números de la última jugada.',
                    boton_ja2:'Muestra los números de las 2 últimas jugadas.',
                    boton_ja3:'Muestra los números de las 3 últimas jugadas.',
                    boton_ja4:'Muestra los números de las 4 últimas jugadas.',
                    boton_ja5:'Muestra los números de las 5 últimas jugadas.',
                    boton_ja6:'Muestra los números de las 6 últimas jugadas.',
                    boton_ja7:'Muestra los números de las 7 últimas jugadas.',
                    boton_ja8:'Muestra los números de las 8 últimas jugadas.',
                    boton_ja9:'Muestra los números de las 9 últimas jugadas.',
                    boton_ja10:'Muestra los números de las 10 últimas jugadas.',
                    boton_ja_borrador:'Borra los números marcados de las últimas jugadas.',
                    boton_s3:'Muestra los números jugados en las últimas tres semanas.',
                    boton_s2:'Muestra los números jugados en las últimas dos semanas.',
                    // boton_sm1:'Muestra los números jugados en la última semana.',
                    boton_ojo:'Muestra los números de la última jugada.',
                    // boton_tabla:' Muestra los posibles números que saldrán en la siguiente jugada.',
                    // posible_jugada_pronostico: 'Lista de posibles números que pueden acompañar al número marcado, podemos copiar cualquier par.',
                    leyenda_pronostico:'Cada marcación de cartillas es evaluado por colores distintos.',
                    cartilla_principal_pronostico:'Cartilla principal, elija los números a jugar.',
                    cartilla_secundaria_pronostico:'Cartilla secundaria, elija un número extra a su jugada.',
                    // boton_pronostico_mix: 'Pinta la cartilla con números calientes, frios o pares.',
                    boton_bot_pronostico:'Genera nuevas jugadas en base a las últimas jugadas.',
                    boton_pronostico_mayor:'Genera nuevas jugadas en base a jugadas personalizadas.',
                     // boton_analitica_pronostico: 'Muestra una lista de consejos de estadística, al hacerles click se pinta la cartilla.',
                    boton_numerologia_pronostico:  'Genera nuevas jugadas en base a las fechas de su nacimiento y la última jugada.',
                    boton_porcentaje_pronostico:  'Genera nuevas jugadas en base a los números mas frecuentes, menos frecuentes, la moda y las últimas 3 jugadas.',
                    boton_limpiar_pronostico: '  Limpia la cartilla en caso que hubiese números marcados.',
                    lista_cartilla_pronostico: '   Seleccione una cartilla a jugar.',
                    boton_agregar_pronostico: 'Crea nuevas cartillas para marcar nuevas jugadas.',
                    // boton_jugar_pronostico: 'Registra las jugadas de las cartillas al carrito de compras.'
                    
                }
                this.$nextTick(() => {
                    const tour = this.$shepherd({
                        useModalOverlay: true,
                        cancelIcon: {
                            enabled: true
                        },
                        classes: 'class-1 class-2',
                        scrollTo: { behavior: 'smooth', block: 'center' }
                    });
                    tour.addStep({
                            attachTo: { element: ".tooltip-boton-pm", on: 'left' },
                            text: text.boton_bot_pronostico,
                            classes: 'shepherd-theme-arrows',
                            buttons: [

                                 {
                                text: 'Salir',
                                action: tour.cancel
                            },
                            {
                                text: 'Sig.',
                                action: tour.next
                            }
                            ]
                    });
                    
                    tour.addStep({
                            attachTo: { element: ".tooltip-pronostico-mayor", on: 'left' },
                            text: text.boton_pronostico_mayor,
                            classes: 'shepherd-theme-arrows',
                            buttons: [
                                {
                                    action: tour.back,
                                    classes: 'shepherd-button-secondary',
                                    text: 'Ant.'
                                },
                                {
                                    text: 'Sig.',
                                    action: tour.next
                                }
                            ]
                    });

                    tour.addStep({
                            attachTo: { element: ".tooltip-boton-numerologia", on: 'left' },
                            text: text.boton_numerologia_pronostico,
                            classes: 'shepherd-theme-arrows',
                            buttons: [
                                {
                                action: tour.back,
                                classes: 'shepherd-button-secondary',
                                text: 'Ant.'
                                },
                                {
                                text: 'Sig.',
                                action: tour.next
                                }
                            ]
                    });

                    tour.addStep({
                            attachTo: { element: ".tooltip-boton-porcentaje", on: 'left' },
                            text: text.boton_porcentaje_pronostico ,
                            classes: 'shepherd-theme-arrows',
                            buttons: [
                                {
                                action: tour.back,
                                classes: 'shepherd-button-secondary',
                                text: 'Ant.'
                                },
                                {
                                text: 'Sig.',
                                action: tour.next
                                }
                            ]
                    });

                    tour.addStep({
                        attachTo: { element: ".tooltip-boton-jas", on: 'left' },
                        text: text.boton_jas,
                        classes: 'shepherd-theme-arrows',
                        buttons: [ 
                                {
                                action: tour.back,
                                classes: 'shepherd-button-secondary',
                                text: 'Ant.'
                                },
                                {
                                text: 'Sig.',
                                action: tour.next
                                }
                        ]
                    });
                    tour.addStep({
                        attachTo: { element: ".tooltip-boton-ja1", on: 'left' },
                        text: text.boton_ja1,
                        classes: 'shepherd-theme-arrows',
                        buttons: [ 
                                {
                                action: tour.back,
                                classes: 'shepherd-button-secondary',
                                text: 'Ant.'
                                },
                                {
                                text: 'Sig.',
                                action: tour.next
                                }
                        ]
                    });
                    tour.addStep({
                        attachTo: { element: ".tooltip-boton-ja2", on: 'left' },
                        text: text.boton_ja2,
                        classes: 'shepherd-theme-arrows',
                        buttons: [
                            {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                            },
                            {
                            text: 'Sig.',
                            action: tour.next
                            }
                        ]
                    });
                    tour.addStep({
                    attachTo: { element: ".tooltip-boton-ja3", on: 'left' },
                    text: text.boton_ja3,
                    classes: 'shepherd-theme-arrows',
                    buttons: [
                        {
                        action: tour.back,
                        classes: 'shepherd-button-secondary',
                        text: 'Ant.'
                        },
                        {
                        text: 'Sig.',
                        action: tour.next
                        }
                    ]
                    });
                    tour.addStep({
                        attachTo: { element: ".tooltip-boton-ja4", on: 'left' },
                        text: text.boton_ja4,
                        classes: 'shepherd-theme-arrows',
                        buttons: [
                            {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                            },
                            {
                            text: 'Sig.',
                            action: tour.next
                            }
                        ]
                    });
                    tour.addStep({
                        attachTo: { element: ".tooltip-boton-ja5", on: 'left' },
                        text: text.boton_ja5,
                        classes: 'shepherd-theme-arrows',
                        buttons: [
                            {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                            },
                            {
                            text: 'Sig.',
                            action: tour.next
                            }
                        ]
                    });
                    tour.addStep({
                        attachTo: { element: ".tooltip-boton-ja6", on: 'left' },
                        text: text.boton_ja6,
                        classes: 'shepherd-theme-arrows',
                        buttons: [
                            {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                            },
                            {
                            text: 'Sig.',
                            action: tour.next
                            }
                        ]
                    });
                    tour.addStep({
                        attachTo: { element: ".tooltip-boton-ja7", on: 'left' },
                        text: text.boton_ja7,
                        classes: 'shepherd-theme-arrows',
                        buttons: [
                            {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                            },
                            {
                            text: 'Sig.',
                            action: tour.next
                            }
                        ]
                    });
                    tour.addStep({
                        attachTo: { element: ".tooltip-boton-ja8", on: 'left' },
                        text: text.boton_ja8,
                        classes: 'shepherd-theme-arrows',
                        buttons: [
                            {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                            },
                            {
                            text: 'Sig.',
                            action: tour.next
                            }
                        ]
                    });
                    tour.addStep({
                        attachTo: { element: ".tooltip-boton-ja9", on: 'left' },
                        text: text.boton_ja9,
                        classes: 'shepherd-theme-arrows',
                        buttons: [
                            {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                            },
                            {
                            text: 'Sig.',
                            action: tour.next
                            }
                        ]
                    });
                    tour.addStep({
                        attachTo: { element: ".tooltip-boton-ja10", on: 'left' },
                        text: text.boton_ja10,
                        classes: 'shepherd-theme-arrows',
                        buttons: [
                            {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                            },
                            {
                            text: 'Sig.',
                            action: tour.next
                            }
                        ]
                    });
                    tour.addStep({
                        attachTo: { element: ".tooltip-boton-ja-borrador", on: 'left' },
                        text: text.boton_ja_borrador,
                        classes: 'shepherd-theme-arrows',
                        buttons: [
                            {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                            },
                            {
                            text: 'Sig.',
                            action: tour.next
                            }
                        ]
                    });
                    
                    tour.addStep({
                            attachTo: { element: ".tooltip-leyenda-pronostico", on: 'left' },
                            text: text.leyenda_pronostico,
                            classes: 'shepherd-theme-arrows',
                            buttons: [
                                {
                                action: tour.back,
                                classes: 'shepherd-button-secondary',
                                text: 'Ant.'
                                },
                                {
                                text: 'Sig.',
                                action: tour.next
                                }
                            ]
                    });
                    tour.addStep({
                            attachTo: { element: ".tooltip-boton-agregar", on: 'left' },
                            text: text.boton_agregar_pronostico,
                            classes: 'shepherd-theme-arrows',
                            buttons: [
                                {
                                action: tour.back,
                                classes: 'shepherd-button-secondary',
                                text: 'Ant.'
                                },
                                {
                                text: 'Sig.',
                                action: tour.next
                                }
                            ]
                    });
                    tour.addStep({
                            attachTo: { element: ".tooltip-boton-limpiar", on: 'left' },
                            text: text.boton_limpiar_pronostico,
                            classes: 'shepherd-theme-arrows',
                            buttons: [
                                {
                                action: tour.back,
                                classes: 'shepherd-button-secondary',
                                text: 'Ant.'
                                },
                                {
                                text: 'Sig.',
                                action: tour.next
                                }
                            ]
                    });
                    
                    tour.addStep({
                            attachTo: { element: ".tooltip-cartilla-principal-pronostico", on: 'left' },
                            text: text.cartilla_principal_pronostico,
                            classes: 'shepherd-theme-arrows',
                            buttons: [
                                {
                                action: tour.back,
                                classes: 'shepherd-button-secondary',
                                text: 'Ant.'
                                },
                                {
                                text: 'Sig.',
                                action: tour.next
                                }
                            ]
                    }); 
                    tour.addStep({
                            attachTo: { element: ".tooltip-cartilla-secundaria-pronostico", on: 'left' },
                            text: text.cartilla_secundaria_pronostico,
                            classes: 'shepherd-theme-arrows',
                            buttons: [
                                {
                                action: tour.back,
                                classes: 'shepherd-button-secondary',
                                text: 'Ant.'
                                },
                                {
                                text: 'Hecho',
                                action: tour.complete
                                }
                            ]
                    }); 

                    // tour.addStep({
                    //         attachTo: { element: ".tooltip-lista-cartilla", on: 'left' },
                    //         text: text.lista_cartilla_pronostico,
                    //         classes: 'shepherd-theme-arrows',
                    //         buttons: [
                    //             {
                    //             action: tour.back,
                    //             classes: 'shepherd-button-secondary',
                    //             text: 'Ant.'
                    //             },
                    //             {
                    //             text: 'Sig.',
                    //             action: tour.next
                    //             }
                    //         ]
                    // });
                    // tour.addStep({
                    //         attachTo: { element: ".tooltip-boton-agregar", on: 'left' },
                    //         text: text.boton_agregar_pronostico,
                    //         classes: 'shepherd-theme-arrows',
                    //         buttons: [
                    //             {
                    //             action: tour.back,
                    //             classes: 'shepherd-button-secondary',
                    //             text: 'Ant.'
                    //             },
                    //             {
                    //             text: 'Hecho',
                    //             action: tour.complete
                    //             }
                    //         ]
                    // });
    
                    console.log('tour', tour)
                    tour.start();
                });
            },
            generateNumerología(){
                 
                let birth =     this.date.birth.split("-")
                let lottery =   this.lotteryDate.split("-")
                let element;
                let numerologiaTotal;

                for (let index = 0; index < this.procartillas.length; index++) {
                    if (this.procartillas[index].selectedCard) {
                        let total = 0;
                        for (let i = 0; i < birth.length; i++) {
                            element = birth[i];
                            total = total + parseInt(element, 10);
                        }
                        for (let i = 0; i < lottery.length; i++) {
                            element = lottery[i];
                            total = total + parseInt(element, 10);
                        }
                        numerologiaTotal = total.toString().split("");
                        total = 0;
                        for (let i = 0; i < numerologiaTotal.length; i++) {
                            element = numerologiaTotal[i];
                                total = total + parseInt(element, 10);
                        }
                            this.procartillas[index].bExtra = total;
                    }
                }
                     
                this.selectCardsOff();
            },
            exportPDF(){
                if (this.listNumbersToggle.length === 0) {
                       swal.fire('¡ Lista vacía !', '', 'success');
                       return;
                }
                let doc = new jsPDF();
                let img = new Image();
                img.src = Logo;
                
                doc.addImage (img, "PNG", 65, 10, 70, 25)

                    let data= [];
                    let fila = [];
                    for (let index = 0; index < this.listNumbersToggle.length; index++) {
                            fila.push( index.toString().padStart(4, "0") );
                            
                            fila.push(  this.descriptionForecast   );
                            
                            let balls = '';
                            for (let index3 = 0; index3 < this.listNumbersToggle[index].numbers.length; index3++) {
                                 let ball = this.listNumbersToggle[index].numbers[index3];
                                    if ( ball > 0 && ball < 10 ) {
                                        ball = '0' + ball;
                                    }
                                    if ( index3 === 0) {
                                        balls = balls + ball;
                                    }else{
                                        balls = balls + '         ' + ball;
                                    }
                            }
                            fila.push( balls );
                            let ball = this.listNumbersToggle[index].bExtra
                            if ( ball != null  ) {
                                if (  ball > 0 && ball < 10 ) {
                                        ball = '0' + ball;
                                    }
                                 
                                 fila.push( '             ' + ball );
                            }else{
                                    fila.push( '              -');
                            }
                            
                            data.push( fila );
                            fila = [];
                    }
                doc.setProperties({
                    title: 'LISTA DE JUGADAS'
                });
                doc.autoTable({
                    theme: 'grid',
                    startY: 40,
                    headStyles:{ halign: 'center'},
                    columnStyles: { 0: { halign: 'center'}, 1: { halign: 'center'}, 2: { halign: 'center'}  },
                    head: [['LISTA DE JUGADAS']],
                    
                });

                doc.autoTable({
                    theme: 'striped',
                    headStyles:{ halign: 'center'},
                    columnStyles: { 0: { halign: 'center'}, 1: { halign: 'center'}, 2: { halign: 'center'}  },
                    head: [['#', 'Lotería', 'Números', 'Extra ball']],
                    body: data,
                });
                doc = this.addWaterMark(doc);
               
                doc.output('dataurlnewwindow');
            },
            addWaterMark:function(doc) {
                var totalPages = doc.internal.getNumberOfPages();

                for (let i = 1; i <= totalPages; i++) {
                    doc.setPage(i);
                    doc.saveGraphicsState();
                    doc.setGState(new doc.GState({opacity: 0.4}));
                    doc.setTextColor(200);
                    doc.setFontSize(50);
                    doc.text(60, 200 , 'IA LOTTERY PLAY', null, 45);
                    doc.restoreGraphicsState();
                }
                return doc;
            },
            async exportExcel(){
                if (this.listNumbersToggle.length == 0) {
                       swal.fire('¡ Lista vacía !', '', 'success');
                       return;
                }
                    let hoy = new Date();
                    let hora = hoy.getHours() + '-' + hoy.getMinutes() + '-' + hoy.getSeconds();
                    
                    const workbook = new Excel.Workbook();

                    workbook.creator = 'Diego Armando Condori Cabrera';
                    workbook.lastModifiedBy = 'Diego Armando Condori Cabrera';
                    workbook.created = new Date(2021, 7, 15);
                    workbook.modified = new Date();
                    workbook.lastPrinted = new Date(2021, 7, 15);

                    const worksheet = workbook.addWorksheet('Página 1', {
                                    properties:{defaultColWidth:15, tabColor:{argb:'FF00FF00'}},
                                    // pageSetup:{horizontalCentered : true, verticalCentered:true}
                        });


                    worksheet.addRow(
                    { package_name: "ABC", author_name: "Author 1" }
                    );

                    let filas = [];
                let fila = [];

                //ESCRITURA
                // let valor;
                // let count;
                // let x = 0;
                // for ( x = 0; x < jugadas.length; x++) {
                    
                    // let columnaNombrePosition = (x == 0) ? 2 : ((this.columnasForecast * x) + 2* x) + 2 ;
                    filas.push(...[ ["B1","B2","B3","B4","B5","B6"] ]);
                    for (let i = 0; i < this.listNumbersToggle.length; i++) {
                        // Lunes 26 de Julio del 2021
                        let element = this.listNumbersToggle[i];
                            fila.push(element.numbers[0]);
                            fila.push(element.numbers[1]);
                            fila.push(element.numbers[2]);
                            fila.push(element.numbers[3]);
                            fila.push(element.numbers[4]);
                            fila.push(element.bExtra == null ? element.numbers[5]:element.bExtra);
                            
                            filas.push(fila);
                                fila = [];
                    }
                    
                    worksheet.addTable({
                        // name: 'MyTable2'+columnaPosition[columnaNombrePosition],
                        name: 'MyTable2',
                        ref: 'C5',
                        headerRow: false,
                        style: {
                        showRowStripes: false,
                        },
                        columns: [
                        {name: '___',  filterButton: false},
                        {name: '____',  filterButton: false},
                        {name: '_____',    filterButton: false},
                        {name: '______',  filterButton: false},
                        {name: '_______',  filterButton: false},
                        {name: '________',   filterButton: false},
                        // {name: '_________',   filterButton: false},
                        // {name: '__________',   filterButton: false}
                        ],
                        rows: filas,
                    });
                    
                    //       filas = [];
                //       count = x;
                // }
                worksheet.getCell('A1').value = `IA LOTTERY PLAY `;

                worksheet.getCell('A1').font = {
                    name: 'Arial Black',
                    color: { argb: 'FFFFFF' },
                    family: 2,
                    size: 14,
                    italic: true
                    };
                worksheet.getCell('A1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
                worksheet.getCell('B1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
                // worksheet.getCell('C1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
                
                worksheet.getCell('C5').font = { name: 'Arial Black', color: { argb: 'FFFFFF' }, family: 2, size: 10  };
                worksheet.getCell('C5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "0D274A" }, bgColor: { argb: "0D274A" } }

                worksheet.getCell('D5').font = { name: 'Arial Black', color: { argb: 'FFFFFF' }, family: 2, size: 10  };
                worksheet.getCell('D5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "0D274A" }, bgColor: { argb: "0D274A" } }

                worksheet.getCell('E5').font = { name: 'Arial Black', color: { argb: 'FFFFFF' }, family: 2, size: 10  };
                worksheet.getCell('E5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "0D274A" }, bgColor: { argb: "0D274A" } }

                worksheet.getCell('F5').font = { name: 'Arial Black', color: { argb: 'FFFFFF' }, family: 2, size: 10  };
                worksheet.getCell('F5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "0D274A" }, bgColor: { argb: "0D274A" } }

                worksheet.getCell('G5').font = { name: 'Arial Black', color: { argb: 'FFFFFF' }, family: 2, size: 10  };
                worksheet.getCell('G5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "0D274A" }, bgColor: { argb: "0D274A" } }

                worksheet.getCell('H5').font = { name: 'Arial Black', color: { argb: 'FFFFFF' }, family: 2, size: 10  };
                worksheet.getCell('H5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "0D274A" }, bgColor: { argb: "0D274A" } }
              
            worksheet.getCell('D2').value = `LISTADO DE JUGADAS MARCADAS`;
                
                worksheet.getCell('D2').font = {
                    name: 'Arial Black',
                    family: 4,
                    size: 16,
                    underline: true,
                    bold: true
                };


                    const buffer = await workbook.xlsx.writeBuffer();
                    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    const fileExtension = '.xlsx';

                    const blob = new Blob([buffer], {type: fileType});
                    saveAs(blob, `IALOTTERYPLAY ${hora} ` + fileExtension   );
            },
            goCheckout(){
                location.href =`/checkout/${this.$route.params.id}/${this.$route.params.name}`;
                // this.$route.params.name,
                // this.$route.params.id,
            },
            deleteAllNumbers(){
                if (this.listNumbersToggle.length != 0) {
                            swal.fire({
                            title: '¿Confirma eliminar todos los registros?',
                            confirmButtonText: `Confirmar`,
                            cancelButtonText:  `Cancelar`,
                            showCancelButton: true,
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                 //procedimiento borrar
                                
                                for (let i = 0; i < this.listNumbersToggle.length; i++) {
                                    let element = this.listNumbersToggle[i];
                                    await this.deleteOneCheckout( element.id );
                                }
                                this.CLEAR_LISTNUMBERSTOGGLE();
                                await this.listCheckout();
                                swal.fire('¡Eliminado!', '', 'success')

                                
                            } else if (result.isDenied) {

                              console.log('')
                            }
                        });
                } else {
                    swal.fire('¡ Carrito vacio !', '', 'success')
                }
            },
            formatoFecha(fecha){ 
                return moment(String(fecha)).format('MM-DD');
            },
            deleteOneNumbers( index, id ){
                swal.fire({
                    title: '¿Confirma eliminar el registro?',
                    confirmButtonText: `Confirmar`,
                    cancelButtonText:  `Cancelar`,
                    showCancelButton: true,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        //procedimiento borrar
                        await this.deleteOneCheckout( id );
                        this.listNumbersToggle.splice(index,1);
                        await this.listCheckout();
                        swal.fire('¡Eliminado!', '', 'success')
                    } else if (result.isDenied) {
                      console.log('')

                    }
                });
            },
              
            async takePlayForecastBall(index){
  
                        let i = index;
                        let bolillas = [];
                        let bExtra= null ;
                                for (let j = 0; j < this.procartillas[i].balls.length; j++) {
                                    bolillas.push(this.procartillas[i].balls[j]);
                                
                                }
                                for (let j = 0; j < this.procartillas[i].ballhot.length; j++) {
                                bolillas.push(this.procartillas[i].ballhot[j]);
                                }
                                for (let j = 0; j < this.procartillas[i].ballcold.length; j++) {
                                bolillas.push(this.procartillas[i].ballcold[j]);
                                }
                                for (let j = 0; j < this.procartillas[i].ballpar.length; j++) {
                                bolillas.push(this.procartillas[i].ballpar[j]);
                                }
                                
                                bolillas = bolillas.sort(function(a, b){return a - b});
                                bExtra = this.procartillas[i].bExtra;
                                let param1 = JSON.stringify(bolillas) ;
                                // console.log("bolillas: ", param1);
                                let continuar = false;
                                for (let k = 0; k < this.listNumbersToggle.length; k++) {
                                    let element = JSON.stringify(this.listNumbersToggle[k].numbers);
                                  
                                    if (element == param1 && this.listNumbersToggle[k].bExtra == bExtra ) {
                                        continuar = true; 
                                        
                                    }
                                } 
                                if (continuar) {
                                    return;
                                } 

                        
                        let register = false;
                        //REGISTRA LA JUGADA A "NUMEROS JUGADOS"
                        
                            if (this.extraballForecast != 0) {
                                 
                                if ( this.procartillas[i].balls.length + this.procartillas[i].ballpar.length + this.procartillas[i].ballcold.length + this.procartillas[i].ballhot.length==  this.numberBallsForecast && this.procartillas[i].bExtra != null) {
                                  
                                    register = true;
                                    await this.registrarCartilla( i );
                                }
                            } else {
                                
                                if ( this.procartillas[i].balls.length + this.procartillas[i].ballpar.length + this.procartillas[i].ballcold.length + this.procartillas[i].ballhot.length ==  this.numberBallsForecast) {
                                   
                                    register = true;
                                    await this.registrarCartilla( i );
                                }

                            }

                            if (!register) { 
                                return;
                            }
                            else{ 
                                this.updateLeyenda(this.paginadorForecast);
                                
                                // --------------------------------------------

                                this.isActiveFlashToggle = true;
                                    let timer = this;
                                    await setTimeout(() => {
                                        
                                        timer.isActiveFlashToggle = false;
                                    }, 5000);
                                if ( this.isActiveToggle ) {
                                    this.isShowFlecha = true;
                                    
                                }
                                
                            } 
            },
            async takePlayForecast(){

                 for (let index = 0; index < this.procartillas.length; index++) {
                    
                    if (this.procartillas[index].selectedCard) { 
                        let i = index;
                        let bolillas = [];
                        let bExtra= null ;
                                for (let j = 0; j < this.procartillas[i].balls.length; j++) {
                                    bolillas.push(this.procartillas[i].balls[j]);
                                
                                }
                                for (let j = 0; j < this.procartillas[i].ballhot.length; j++) {
                                bolillas.push(this.procartillas[i].ballhot[j]);
                                }
                                for (let j = 0; j < this.procartillas[i].ballcold.length; j++) {
                                bolillas.push(this.procartillas[i].ballcold[j]);
                                }
                                for (let j = 0; j < this.procartillas[i].ballpar.length; j++) {
                                bolillas.push(this.procartillas[i].ballpar[j]);
                                }
                                
                                bolillas = bolillas.sort(function(a, b){return a - b});
                                bExtra = this.procartillas[i].bExtra;
                                let param1 = JSON.stringify(bolillas) ;
                                // console.log("bolillas: ", param1);
                                let continuar = false;
                                for (let k = 0; k < this.listNumbersToggle.length; k++) {
                                    let element = JSON.stringify(this.listNumbersToggle[k].numbers);
                                  
                                    if (element == param1 && this.listNumbersToggle[k].bExtra == bExtra ) {
                                        continuar = true; 
                                        
                                    }
                                } 
                                if (continuar) {
                                    continue;
                                } 

                        
                        let register = false;
                        //REGISTRA LA JUGADA A "NUMEROS JUGADOS"
                        
                            if (this.extraballForecast != 0) {
                                 
                                if ( this.procartillas[i].balls.length + this.procartillas[i].ballpar.length + this.procartillas[i].ballcold.length + this.procartillas[i].ballhot.length==  this.numberBallsForecast && this.procartillas[i].bExtra != null) {
                                  
                                    register = true;
                                    await this.registrarCartilla( i );
                                }
                            } else {
                                
                                if ( this.procartillas[i].balls.length + this.procartillas[i].ballpar.length + this.procartillas[i].ballcold.length + this.procartillas[i].ballhot.length ==  this.numberBallsForecast) {
                                   
                                    register = true;
                                    await this.registrarCartilla( i );
                                }

                            }

                            if (!register) { 
                                continue;
                            }
                            else{ 
                                this.updateLeyenda(this.paginadorForecast);
                                
                                // --------------------------------------------

                                this.isActiveFlashToggle = true;
                                    let timer = this;
                                    await setTimeout(() => {
                                        
                                        timer.isActiveFlashToggle = false;
                                    }, 5000);
                                if ( this.isActiveToggle ) {
                                    this.isShowFlecha = true;
                                    
                                }
                                
                            }
                    }
                } 
            },

            pPercentageForecast( pp ){
            
            /**
             * OBTENGO 'PM' EN LAS ULTIMAS 'XJUGADA' JUGADAS
             *
             */
                for (let i = 0; i < this.percentage_buttons.length; i++  ) {
                    this.percentage_buttons[i].isSelected = (parseInt(this.percentage_buttons[i].key)) === pp
                }
                switch (pp) {
                    case 1:
                        this.mPercentage = 1
                       // this.pPercentageColdModaHotForecast();
                        break;
                    case 2:
                        this.mPercentage = 2
                        //this.pPercentageColdHotForecast();
                        break;
                    case 3:
                        this.mPercentage = 3
                        // this.procartillas[this.paginadorForecast].xJugada = 1;
                        //this.pPercentageDetailThreeForecast();
                        break;
                
                    default:
                        break;
                }

            },
            pPercentageColdModaHotForecast(){
                
                let colds = this.listColdForecast;
                let hots = this.listHotForecast;
                 
                let botSelected = this.lotteryForecast.bot[2]  ;  //EL JA3
                let pm = 0; //CANTIDAD DE BOLILLAS A ELEGIR DENTRO DEL JA3


                let numbers_frequency = this.numbers_frequency;
                var hot_percentage = numbers_frequency.slice(0, 41);
                var colds_percentage = numbers_frequency.slice(60, 69);
                var hp = [];
                var hc = [];
                for (let i = 0; i < hot_percentage.length; i++) {
                    hp.push(hot_percentage[i].ball)  ;
                }

                for (let i = 0; i < colds_percentage.length; i++) {
                    hc.push(colds_percentage[i].ball)  ;
                }

                var moda = this.modal_list.slice(0, 20);
                var list_total = [...hp, ...hc, ...moda];

                var whithout_list_total = [];

                for (let i = 0; i < list_total.length; i++) {
                    if (!whithout_list_total.includes(list_total[i])) {
                        whithout_list_total.push(list_total[i]);
                    }
                    
                }
                
                 for (let index = 0; index < this.procartillas.length; index++) {
                    if (this.procartillas[index].selectedCard) {
                        let i = 1;
                        let balls = []; //LAS 5 O 6 BOLILLAS QUE SE IRAN FORMANDO
                        while ( i <=  this.numberBallsForecast) { //I <= NUMERO DE BOLILLAS A ELEGIR 5 O 6

                                    let number_i = Math.floor(Math.random()*(whithout_list_total.length-this.min)+this.min);
                                    let number = whithout_list_total[number_i]
                                    if ( i <= this.numberBallsForecast - pm ) { //NO DEBE ESTAR INCLUIDO EL ARREGLO DE JA3
                                        if ( !botSelected.includes(number) && !balls.includes(number) ) {
                                            balls.push(number)
                                            i++;
                                        }
                                    }

                                    if ( i > this.numberBallsForecast - pm ) { //DEBE ESTAR INCLUIDO EL ARREGLO DE JA3
                                        if ( botSelected.includes(number) && !balls.includes(number)) {
                                            balls.push(number)
                                            i++;
                                        }
                                    }

                            }

                            this.procartillas[index].balls = [];
                                this.procartillas[index].ballhot = [];
                                this.procartillas[index].ballcold = [];
                                this.procartillas[index].ballpar = [];
                                this.procartillas[index].ballcartilla = [];
                                this.procartillas[index].bExtra = null;
                            
                                    
                                        this.procartillas[index].balls.push(...balls);
                                        
                                //VALIDANDO PARES
                                for (let i = 0; i < this.procartillas[index].balls.length - 1; i++) { //vertical
                                    // console.log('???13');
                                    for (let j = i + 1; j < this.procartillas[index].balls.length; j++) { //horizontal
                                        // console.log('???14');
                                        for (let k = 0; k < this.pares.length; k++) {
                                        // console.log('???15');
                                        if ( this.pares[k].numeros.includes( this.procartillas[index].balls[i] ) &&  this.pares[k].numeros.includes( this.procartillas[index].balls[j] ) ) {
                                            let numero2 = this.procartillas[index].balls[j];
                                            let posicion1 =  this.procartillas[index].balls.indexOf(this.procartillas[index].balls[i]);
                                                this.procartillas[index].ballpar.push( this.procartillas[index].balls[posicion1] );
                                                this.procartillas[index].balls.splice(posicion1,1);
                                            let posicion2 =  this.procartillas[index].balls.indexOf(numero2);
                                                this.procartillas[index].ballpar.push( numero2 );
                                                this.procartillas[index].balls.splice(posicion2,1);

                                        }
                                        }
                                    }
                                }

                                //VALIDANDO FRIOS
                                for (let k = 0; k <  colds.length; k++) {
                                let number = parseInt(colds[k].ball, 10);
        // console.log('???16');
                                        if ( this.procartillas[index].balls.includes(number) &&  ! this.procartillas[index].ballhot.includes(number) && !this.procartillas[index].ballpar.includes(number) ) {
                                            
                                                let posicion1 =  this.procartillas[index].balls.indexOf(number);
                                                this.procartillas[index].ballcold.push( this.procartillas[index].balls[posicion1] );
                                                this.procartillas[index].balls.splice(posicion1,1);
                                        }
                                }

                                //VALIDANDO CALIENTES
                                for (let k = 0; k <  hots.length; k++) {
                                    // console.log('???17');
                                let number = parseInt(hots[k].ball, 10);
                                        if ( this.procartillas[index].balls.includes(number) &&  !this.procartillas[index].ballcold.includes(number) && !this.procartillas[index].ballpar.includes(number) ) {
                                                let posicion1 =  this.procartillas[index].balls.indexOf(number);
                                                this.procartillas[index].ballhot.push( this.procartillas[index].balls[posicion1] );
                                                this.procartillas[index].balls.splice(posicion1,1);
                                        }
                                }
                        
        
                        let botSelectedExtra =  this.lotteryForecast.bote[2]  ;
                    
                        this.procartillas[index].bExtra = null;
                            
                            let o = 0;
                            
                            if ( this.extraballForecast != 0 ) {
        
                                    while (o<1) {
                                        // console.log('???18');
                                        let number = Math.floor(Math.random()*(this.extraballForecast-this.min+1)+this.min);
                                        
                                            if ( botSelectedExtra.includes(number)   ) {
                                                this.procartillas[index].bExtra = number;
                                                o++;
                                            }
                                    }
                                
                            }

                            //ACTUALIZAR LEYENDA
                            this.updateLeyenda(index);

                    }
                } 
                
                this.relaodPrice();
                this.selectCardsOff();
                 
            },
            pPercentageColdHotForecast(){ 
                let colds = this.listColdForecast;
                let hots = this.listHotForecast; 
                let botSelected = this.lotteryForecast.bot[2]  ;  //EL JA3
                let pm = 0; //CANTIDAD DE BOLILLAS A ELEGIR DENTRO DEL JA3
               

                for (let index = 0; index < this.procartillas.length; index++) {
                    if (this.procartillas[index].selectedCard) {
                        let i = 1;
                        let balls = []; //LAS 5 O 6 BOLILLAS QUE SE IRAN FORMANDO

                        while ( i <=  this.numberBallsForecast) { //I <= NUMERO DE BOLILLAS A ELEGIR 5 O 6

                            let number = Math.floor(Math.random()*(this.ballForecast-this.min+1)+this.min);
                            
                            if ( i <= this.numberBallsForecast - pm ) { //NO DEBE ESTAR INCLUIDO EL ARREGLO DE JA3
                                if ( !botSelected.includes(number) && !balls.includes(number) ) {
                                    balls.push(number)
                                    i++;
                                }
                            }

                            if ( i > this.numberBallsForecast - pm ) { //DEBE ESTAR INCLUIDO EL ARREGLO DE JA3
                                if ( botSelected.includes(number) && !balls.includes(number)) {
                                    balls.push(number)
                                    i++;
                                }
                            }

                        }

                        this.procartillas[index].balls = [];
                        this.procartillas[index].ballhot = [];
                        this.procartillas[index].ballcold = [];
                        this.procartillas[index].ballpar = [];
                        this.procartillas[index].ballcartilla = [];
                        this.procartillas[index].bExtra = null;
                      
                            
                                 this.procartillas[index].balls.push(...balls);
                                 
                        //VALIDANDO PARES
                        for (let i = 0; i < this.procartillas[index].balls.length - 1; i++) { //vertical
                            // console.log('???13');
                            for (let j = i + 1; j < this.procartillas[index].balls.length; j++) { //horizontal
                                // console.log('???14');
                                for (let k = 0; k < this.pares.length; k++) {
                                // console.log('???15');
                                if ( this.pares[k].numeros.includes( this.procartillas[index].balls[i] ) &&  this.pares[k].numeros.includes( this.procartillas[index].balls[j] ) ) {
                                    let numero2 = this.procartillas[index].balls[j];
                                    let posicion1 =  this.procartillas[index].balls.indexOf(this.procartillas[index].balls[i]);
                                        this.procartillas[index].ballpar.push( this.procartillas[index].balls[posicion1] );
                                        this.procartillas[index].balls.splice(posicion1,1);
                                    let posicion2 =  this.procartillas[index].balls.indexOf(numero2);
                                        this.procartillas[index].ballpar.push( numero2 );
                                        this.procartillas[index].balls.splice(posicion2,1);

                                }
                                }
                            }
                        }

                        //VALIDANDO FRIOS
                        for (let k = 0; k <  colds.length; k++) {
                        let number = parseInt(colds[k].ball, 10);
// console.log('???16');
                                if ( this.procartillas[index].balls.includes(number) &&  ! this.procartillas[index].ballhot.includes(number) && !this.procartillas[index].ballpar.includes(number) ) {
                                    
                                        let posicion1 =  this.procartillas[index].balls.indexOf(number);
                                        this.procartillas[index].ballcold.push( this.procartillas[index].balls[posicion1] );
                                        this.procartillas[index].balls.splice(posicion1,1);
                                }
                        }

                        //VALIDANDO CALIENTES
                        for (let k = 0; k <  hots.length; k++) {
                            // console.log('???17');
                        let number = parseInt(hots[k].ball, 10);
                                if ( this.procartillas[index].balls.includes(number) &&  !this.procartillas[index].ballcold.includes(number) && !this.procartillas[index].ballpar.includes(number) ) {
                                        let posicion1 =  this.procartillas[index].balls.indexOf(number);
                                        this.procartillas[index].ballhot.push( this.procartillas[index].balls[posicion1] );
                                        this.procartillas[index].balls.splice(posicion1,1);
                                }
                        }
                  
 
                let botSelectedExtra =  this.lotteryForecast.bote[2]  ;
               
                this.procartillas[index].bExtra = null;
                    
                    let o = 0;
                    
                    if ( this.extraballForecast != 0 ) {
 
                            while (o<1) {
                                // console.log('???18');
                                let number = Math.floor(Math.random()*(this.extraballForecast-this.min+1)+this.min);
                                 
                                    if ( botSelectedExtra.includes(number)   ) {
                                        this.procartillas[index].bExtra = number;
                                        o++;
                                    }
                            }
                        
                    }

                      //ACTUALIZAR LEYENDA
                    this.updateLeyenda(index);
                    }
                }
                 
                    this.selectCardsOff();
                    this.relaodPrice();

                // console.log("numbers_frequency: ", numbers_frequency);
                // console.log("hots: ", hot_percentage);
                // console.log("colds: ", colds_percentage);
  
            },
            pPercentageDetailThreeForecast(){
                
                
                let colds = this.listColdForecast;
                let hots = this.listHotForecast; 
                let pm = 1;
                // console.log("this.procartillas[index].xJugada: ", this.procartillas[index].xJugada);
                // console.log("this.lotteryForecast.bot[ this.procartillas[index].xJugada]", this.lotteryForecast.bot[ this.procartillas[index].xJugada]);
                let botSelected = this.lotteryForecast.bot[2]  ;
                if ( botSelected.length == 0) {
                    
                    pm = 0;
                }
                for (let index = 0; index < this.procartillas.length; index++) {
                    if (this.procartillas[index].selectedCard) {
                        let i = 1; //EMPIEZA DE 1 HASTA LA CANTIDAD DE BOLILLAS A MARCAR 5 O 6
                        let balls = [];
                        while ( i <=  this.numberBallsForecast) {

                            let number = Math.floor(Math.random()*(this.ballForecast-this.min+1)+this.min);
                            
                            if ( i <= this.numberBallsForecast - pm ) { //NO DEBE ESTAR INCLUIDO EL ARREGLO DE BOT
                                if ( !botSelected.includes(number) && !balls.includes(number) ) {
                                    balls.push(number)
                                    i++;
                                }
                            }

                            if ( i > this.numberBallsForecast - pm ) { //DEBE ESTAR INCLUIDO EL ARREGLO DE BOT
                                if ( botSelected.includes(number) && !balls.includes(number)) {
                                    balls.push(number)
                                    i++;
                                }
                            }

                    }
                    

                
                
                
                this.procartillas[index].balls = [];
                        this.procartillas[index].ballhot = [];
                        this.procartillas[index].ballcold = [];
                        this.procartillas[index].ballpar = [];
                        this.procartillas[index].ballcartilla = [];
                        this.procartillas[index].bExtra = null;
                      
                            
                                 this.procartillas[index].balls.push(...balls);
                                 
                        //VALIDANDO PARES
                        for (let i = 0; i < this.procartillas[index].balls.length - 1; i++) { //vertical
                            // console.log('???13');
                            for (let j = i + 1; j < this.procartillas[index].balls.length; j++) { //horizontal
                                // console.log('???14');
                                for (let k = 0; k < this.pares.length; k++) {
                                // console.log('???15');
                                if ( this.pares[k].numeros.includes( this.procartillas[index].balls[i] ) &&  this.pares[k].numeros.includes( this.procartillas[index].balls[j] ) ) {
                                    let numero2 = this.procartillas[index].balls[j];
                                    let posicion1 =  this.procartillas[index].balls.indexOf(this.procartillas[index].balls[i]);
                                        this.procartillas[index].ballpar.push( this.procartillas[index].balls[posicion1] );
                                        this.procartillas[index].balls.splice(posicion1,1);
                                    let posicion2 =  this.procartillas[index].balls.indexOf(numero2);
                                        this.procartillas[index].ballpar.push( numero2 );
                                        this.procartillas[index].balls.splice(posicion2,1);

                                }
                                }
                            }
                        }

                        //VALIDANDO FRIOS
                        for (let k = 0; k <  colds.length; k++) {
                        let number = parseInt(colds[k].ball, 10);
// console.log('???16');
                                if ( this.procartillas[index].balls.includes(number) &&  ! this.procartillas[index].ballhot.includes(number) && !this.procartillas[index].ballpar.includes(number) ) {
                                    
                                        let posicion1 =  this.procartillas[index].balls.indexOf(number);
                                        this.procartillas[index].ballcold.push( this.procartillas[index].balls[posicion1] );
                                        this.procartillas[index].balls.splice(posicion1,1);
                                }
                        }

                        //VALIDANDO CALIENTES
                        for (let k = 0; k <  hots.length; k++) {
                            // console.log('???17');
                        let number = parseInt(hots[k].ball, 10);
                                if ( this.procartillas[index].balls.includes(number) &&  !this.procartillas[index].ballcold.includes(number) && !this.procartillas[index].ballpar.includes(number) ) {
                                        let posicion1 =  this.procartillas[index].balls.indexOf(number);
                                        this.procartillas[index].ballhot.push( this.procartillas[index].balls[posicion1] );
                                        this.procartillas[index].balls.splice(posicion1,1);
                                }
                        }
                  
 
                let botSelectedExtra =  this.lotteryForecast.bote[2]  ;
               
                this.procartillas[index].bExtra = null;
                    
                    let o = 0;
                    
                    if ( this.extraballForecast != 0 ) {
 
                            while (o<1) {
                                // console.log('???18');
                                let number = Math.floor(Math.random()*(this.extraballForecast-this.min+1)+this.min);
                                 
                                    if ( botSelectedExtra.includes(number)   ) {
                                        this.procartillas[index].bExtra = number;
                                        o++;
                                    }
                            }
                        
                    }

                      //ACTUALIZAR LEYENDA
                    this.updateLeyenda(index);
                    }
                }
                    

                    

                    this.selectCardsOff();
                    this.relaodPrice();
            },
            //OBTENGO 'PM' EN LAS ULTIMAS 'XJUGADA' JUGADAS
            selectedPMounthForest( pm ) {
                 

                for (let i = 0; i < this.boot_buttons.length; i++  ) {
                    this.boot_buttons[i].isSelected = (parseInt(this.boot_buttons[i].key)) === pm
                }  
                this.mBoot = pm
            },


            pMounthForecast(){
                 
                for (let index = 0; index < this.procartillas.length; index++) {
                    if (this.procartillas[index].selectedCard) {
                        //  let index = this.paginadorForecast;
                        let pm = this.mBoot;

                        let i = 1; //EMPIEZA DE 1 HASTA LA CANTIDAD DE BOLILLAS A MARCAR 5 O 6
                        let balls = [];
                        let colds = this.listColdForecast;
                        let hots = this.listHotForecast;
                        
                        // console.log("this.procartillas[index].xJugada: ", this.procartillas[index].xJugada);
                        // console.log("this.lotteryForecast.bot[ this.procartillas[index].xJugada]", this.lotteryForecast.bot[ this.procartillas[index].xJugada]);
                     
                        
                        let botSelected = this.jaSelected != -1 ? this.lotteryForecast.bot[this.jaSelected] : [];
                        if ( botSelected.length == 0) {
                            pm = 0;
                        }
                        
                            while ( i <=  this.numberBallsForecast) {

                                    let number = Math.floor(Math.random()*(this.ballForecast-this.min+1)+this.min);
                                    
                                    if ( i <= this.numberBallsForecast - pm ) { //NO DEBE ESTAR INCLUIDO EL ARREGLO DE BOT
                                        if ( !botSelected.includes(number) && !balls.includes(number) ) {
                                            balls.push(number)
                                            i++;
                                        }
                                    }

                                    if ( i > this.numberBallsForecast - pm ) { //DEBE ESTAR INCLUIDO EL ARREGLO DE BOT
                                        if ( botSelected.includes(number) && !balls.includes(number)) {
                                            balls.push(number)
                                            i++;
                                        }
                                    }

                            } 
                        
                        this.procartillas[index].balls = [];
                                this.procartillas[index].ballhot = [];
                                this.procartillas[index].ballcold = [];
                                this.procartillas[index].ballpar = [];
                                this.procartillas[index].ballcartilla = []; 

                                this.procartillas[index].balls.push(...balls);
                                
                                //VALIDANDO PARES
                                for (let i = 0; i < this.procartillas[index].balls.length - 1; i++) { //vertical
                                    // console.log('???13');
                                    for (let j = i + 1; j < this.procartillas[index].balls.length; j++) { //horizontal
                                        // console.log('???14');
                                        for (let k = 0; k < this.pares.length; k++) {
                                        // console.log('???15');
                                        if ( this.pares[k].numeros.includes( this.procartillas[index].balls[i] ) &&  this.pares[k].numeros.includes( this.procartillas[index].balls[j] ) ) {
                                            let numero2 = this.procartillas[index].balls[j];
                                            let posicion1 =  this.procartillas[index].balls.indexOf(this.procartillas[index].balls[i]);
                                                this.procartillas[index].ballpar.push( this.procartillas[index].balls[posicion1] );
                                                this.procartillas[index].balls.splice(posicion1,1);
                                            let posicion2 =  this.procartillas[index].balls.indexOf(numero2);
                                                this.procartillas[index].ballpar.push( numero2 );
                                                this.procartillas[index].balls.splice(posicion2,1);

                                        }
                                        }
                                    }
                                }

                                //VALIDANDO FRIOS
                                for (let k = 0; k <  colds.length; k++) {
                                let number = parseInt(colds[k].ball, 10);
        // console.log('???16');
                                        if ( this.procartillas[index].balls.includes(number) &&  ! this.procartillas[index].ballhot.includes(number) && !this.procartillas[index].ballpar.includes(number) ) {
                                            
                                                let posicion1 =  this.procartillas[index].balls.indexOf(number);
                                                this.procartillas[index].ballcold.push( this.procartillas[index].balls[posicion1] );
                                                this.procartillas[index].balls.splice(posicion1,1);
                                        }
                                }

                                //VALIDANDO CALIENTES
                                for (let k = 0; k <  hots.length; k++) {
                                    // console.log('???17');
                                let number = parseInt(hots[k].ball, 10);
                                        if ( this.procartillas[index].balls.includes(number) &&  !this.procartillas[index].ballcold.includes(number) && !this.procartillas[index].ballpar.includes(number) ) {
                                                let posicion1 =  this.procartillas[index].balls.indexOf(number);
                                                this.procartillas[index].ballhot.push( this.procartillas[index].balls[posicion1] );
                                                this.procartillas[index].balls.splice(posicion1,1);
                                        }
                                }
                    this.updateLeyenda(index);
                     }
                }
 
                    //ACTUALIZAR LEYENDA
                    this.selectCardsOff();
                    this.relaodPrice();
                    this.$root.$emit('bv::hide::modal', 'm-boot', '#btnSuccessBoot')
  
            },
            pMounthForecastExtra( pm ){
                for (let index = 0; index < this.procartillas.length; index++) {
                    if (this.procartillas[index].selectedCard) {
                        // let index = this.paginadorForecast;
                         
                        let botSelectedExtra = this.jaSelected != -1 ? this.lotteryForecast.bote[this.jaSelected] : [] ; 
                        this.procartillas[index].bExtra = null;
                        
                        let o = 0;

                        if ( this.extraballForecast != 0 ) {

                            if ( pm ) { //TOMAR TODO EXCEPTO DE LA JUGADA
                                while (o<1) {
                                    // console.log('???18');
                                    let number = Math.floor(Math.random()*(this.extraballForecast-this.min+1)+this.min);
                                    if (botSelectedExtra.length == 0) {
                                        this.procartillas[index].bExtra = number;
                                            o++;
                                    }else{
                                        if ( !botSelectedExtra.includes(number)   ) {
                                            this.procartillas[index].bExtra = number;
                                            o++;
                                        }
                                    }


                                }
                            }else{
                                while (o<1) {
                                    // console.log('???18');
                                    let number = Math.floor(Math.random()*(this.extraballForecast-this.min+1)+this.min);
                                    if (this.jaSelected == -1) {
                                        // this.procartillas[index].bExtra = number;
                                        //     o++;
                                            swal.fire({
                                                position: 'top-center',
                                                icon: 'warning',
                                                title: 'Seleccione una Jugada Anterior (JA)!',
                                                showConfirmButton: false,
                                                timer: 3000
                                            })
                                            return false;
                                    }else{
                                        if ( botSelectedExtra.includes(number)   ) {
                                            this.procartillas[index].bExtra = number;
                                            o++;
                                        }
                                    }


                                }
                            }

                        }
                        //ACTUALIZAR LEYENDA
                        this.updateLeyenda(index);
                    }
                }
                this.selectCardsOff();
                this.relaodPrice();
                this.$root.$emit('bv::hide::modal', 'm-boot', '#btnSuccessBoot')

            },
            alertDisable(){
                    swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: (this.disabled) ?  'Botón de ayuda desactivado!' : 'Botón de ayuda activado!',
                    showConfirmButton: false,
                    timer: 2000
                    })
           
                
            },
            moveAnaliticaAparition(e){
                var figur_2 = document.getElementById("figur_2");

                var left = parseInt( window.getComputedStyle(figur_2).getPropertyValue("left") );
                var top = parseInt( window.getComputedStyle(figur_2).getPropertyValue("top") );
                var mouseX = e.clientX;
                var mouseY = e.clientY;

                document.onmousemove = function (e) {
                    var dx = mouseX - e.clientX;
                    var dy = mouseY - e.clientY;

                    figur_2.style.left = left - dx + "px";
                    figur_2.style.top = top - dy + "px";
                };
            },
            moveSuggestion (e){
                var figur_1 = document.getElementById("figur_1");

                var left = parseInt( window.getComputedStyle(figur_1).getPropertyValue("left") );
                var top = parseInt( window.getComputedStyle(figur_1).getPropertyValue("top") );
                var mouseX = e.clientX;
                var mouseY = e.clientY;

                document.onmousemove = function (e) {
                    var dx = mouseX - e.clientX;
                    var dy = mouseY - e.clientY;

                    figur_1.style.left = left - dx + "px";
                    figur_1.style.top = top - dy + "px";
                };
            },
            stopSuggestion: function(){
                // console.log('stopSuggestion');
                document.onmousemove = null;
            },
            stopAnaliticaAparition: function(){
                // console.log('stopSuggestion');
                document.onmousemove = null;
            },
            copySuggestionForecast: function( i ){
                
                if( this.procartillas[this.paginadorForecast].ballpar.includes(this.suggestionJugadas[i].balls[0]) && this.procartillas[this.paginadorForecast].ballpar.includes(this.suggestionJugadas[i].balls[1]) ){
                    return;
                }
                this.procartillas[this.paginadorForecast].ballcold = [];
                this.procartillas[this.paginadorForecast].ballhot = [];
                this.procartillas[this.paginadorForecast].balls = [];
                // this.procartillas[this.paginadorForecast].ballpar = [];

                
                let primero = false;
                let segundo = false;
                let count = 0;

                if ( this.procartillas[this.paginadorForecast].ballpar.includes(this.suggestionJugadas[i].balls[0]) ) {
                    primero = true;
                }else
                {
                    count++;
                }

                if ( this.procartillas[this.paginadorForecast].ballpar.includes(this.suggestionJugadas[i].balls[1]) ) {
                    segundo = true;
                }else{
                    count++;
                }

                if (count != 0) { //SIEMPRE QUE TENGAMOS ALGO QUE INSERTAR
                    if (this.procartillas[this.paginadorForecast].ballpar.length + count <=  this.numberBallsForecast ) {
                      
                       if (!primero) {
                            this.procartillas[this.paginadorForecast].ballpar.push( this.suggestionJugadas[i].balls[0] );
                        }
                        if (!segundo) {
                            this.procartillas[this.paginadorForecast].ballpar.push(  this.suggestionJugadas[i].balls[1] );
                        }
                    }else{
                        //SOBREPASA LOS 5 O 6
                    
                        if ( this.procartillas[this.paginadorForecast].ballpar.length + count == this.numberBallsForecast + 1 ) {
                            
                            if ( count == 1 ) { //HAY 5 = UNO DE ELLOS YA SE ENCUENTRA EN EL ARREGLO, LO QUITAMOS PARA INSERTAR LOS DOS
                               
                                if (primero) {
                                    let posicionBall = this.procartillas[this.paginadorForecast].ballpar.indexOf(this.suggestionJugadas[i].balls[0]);
                                    this.procartillas[this.paginadorForecast].ballpar.splice(posicionBall,1);
                                    this.procartillas[this.paginadorForecast].ballpar.splice(0,1);
                                }else{
                                    if (segundo) {
                                        let posicionBall = this.procartillas[this.paginadorForecast].ballpar.indexOf(this.suggestionJugadas[i].balls[1]);
                                        this.procartillas[this.paginadorForecast].ballpar.splice(posicionBall,1);
                                        this.procartillas[this.paginadorForecast].ballpar.splice(0,1);
                                    }
                                }
                                this.procartillas[this.paginadorForecast].ballpar.push( ...this.suggestionJugadas[i].balls );
                                
                            }

                            if( count == 2 ){ //HAY 4 =  INSERTAR LOS DOS NUMEROS NUEVOS, NO ESTAN EN EL ARREGLO
                                 this.procartillas[this.paginadorForecast].ballpar.splice(0,1);
                                this.procartillas[this.paginadorForecast].ballpar.push( ...this.suggestionJugadas[i].balls );
                                
                            }
                        }else{
                            if ( this.procartillas[this.paginadorForecast].ballpar.length + count == this.numberBallsForecast + 2 ) {
                                this.procartillas[this.paginadorForecast].ballpar.splice(0,1);
                                this.procartillas[this.paginadorForecast].ballpar.splice(0,1);
                                this.procartillas[this.paginadorForecast].ballpar.push( ...this.suggestionJugadas[i].balls );
                            }
                        }

                        
                    }
                    
                }
                
                this.updateLeyenda(this.paginadorForecast);
                this.showSuggestion = false;
                this.numberSuggestion = [];
                // console.log('this.procartillas[this.paginadorForecast].ballpar: ', this.procartillas[this.paginadorForecast].ballpar);
            },
            closeSuggestionForecast: function( ){
                this.showSuggestion = false;
                this.numberSuggestion = [];
            },
            closeAnaliticaAparitionForecast:function(){
                this.showAnaliticaAparition = false;
                this.numberAnaliticaAparition = 0;
            },
            suggestionForecast: function( i ){
                let j;
                for(j = 0; j < this.procartillas[this.paginadorForecast].balls.length; j++){
                    if(i == this.procartillas[this.paginadorForecast].balls[j]){
                        return true;
                    }
                }
            },
            async activePay(){
                const authorization = VueCookie.get('costumer_token');
                    if (!authorization) {
                        this.$router.push('/login-client')
                        //this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
                        return;
                    }
                    location.href ='/perfil?suscription=true';
                    
            },
            
          
            selectedColorJa:function(i){ 
                if ( i == this.jaSelected ) {
                    return true;
                }
                return false;
                
            },
            selectClickJA:function( i ) {
                if (i != this.jaSelected ) {
                    this.jaSelected = i;
                }else{
                    this.jaSelected = -1;
                }
                 
            },
            
            numeroCaliente:function( ){

                let index = this.paginadorForecast;
                let hots = this.listHotForecast;  //Almacena La lista de arreglos de calientes
                this.temporal = []; //La lista de bolillas  de procartillas en temporal
                let count =  this.procartillas[index].balls.length + this.procartillas[index].ballhot.length + this.procartillas[index].ballcold.length + this.procartillas[index].ballpar.length;
            
                for (let i = 0; i < this.procartillas[index].balls.length; i++) { //5 bolillas de la cartilla aleatoria
                    this.temporal.push( this.procartillas[index].balls[i] );
                }
                
                this.temporalhot =[]; //Se almacenan los numeros calientes de nuestra procartilla
                for (let i = 0; i < this.procartillas[index].ballhot.length; i++) { //5 bolillas de la cartilla aleatoria
                    this.temporalhot.push( this.procartillas[index].ballhot[i] );
                }
            
                if (count >= this.numberBallsForecast ) { //No Hay espacio en las 5 o 6 bolillas
                        if (this.procartillas[index].balls.length > 0) {
                            this.temporal.splice(0,1); //Eliminar un numero de las 5 bolillas
                        } else {
                            if (this.procartillas[index].ballcold.length > 0) {
                            this.procartillas[index].ballcold.splice(0,1);
                            } else {
                            if (this.procartillas[index].ballpar.length > 0) {
                                this.procartillas[index].ballpar.splice(0,1);
                                this.procartillas[index].ballpar.splice(0,1);
                            } else {
                                this.temporalhot.splice(0,1);
                            }
                            }
                        }
                }

                let o = 0;
                while (o < 1) {
                    let k =Math.floor(Math.random()*((hots.length-1) - 0+1)+0);  //Elige el numero hot que se usara para el procartilla,
                    let number = parseInt(hots[k].ball, 10);
                    if ( !this.temporal.includes( number ) && !this.procartillas[index].ballhot.includes(number) && !this.procartillas[index].ballcold.includes(number) && !this.procartillas[index].ballpar.includes(number)) {
                        this.temporalhot.push(number);
                        this.procartillas[index].balls=[]; //Limpiamos la cartilla
                        this.procartillas[index].balls=this.temporal; //Colocamos los nuevos numeros
                        this.procartillas[index].ballhot = [];
                        this.procartillas[index].ballhot = this.temporalhot;//Agregar otro al arreglo de bolillas calientes
                        o++;
                    }
                }
                this.updateLeyenda(index);
                this.relaodPrice();
            },
            numeroFrio:function( ){
              
                let index = this.paginadorForecast;
                let colds = this.listColdForecast;  //SE ALMACENA LOS NUMEROS FRIOS DEL BACKEND
                this.temporal = []; //SE ALMACENAN LAS BOLILLAS NORMALES RESULTADOS DE PROCARTILLA
                let count =  this.procartillas[index].balls.length + this.procartillas[index].ballhot.length + this.procartillas[index].ballcold.length + this.procartillas[index].ballpar.length;
                for (let i = 0; i < this.procartillas[index].balls.length; i++) { //5 bolillas de la cartilla aleatoria
                    this.temporal.push( this.procartillas[index].balls[i] );
                }
                this.temporalcold =[]; //SE ALMACENAN LAS BOLILLAS FRIAS DE PROCARTILLAS SI TENDRIA
                for (let i = 0; i < this.procartillas[index].ballcold.length; i++) { //5 bolillas de la cartilla aleatoria
                    this.temporalcold.push( this.procartillas[index].ballcold[i] );
                }
                
                if (count >= this.numberBallsForecast ) { //No Hay espacio en las 5 o 6 bolillas
                        if (this.procartillas[index].balls.length > 0) {
                            this.temporal.splice(0,1); //Eliminar un numero de las 5 bolillas
                        } else {
                            if (this.procartillas[index].ballhot.length > 0) {
                            this.procartillas[index].ballhot.splice(0,1);
                            } else {
                            if (this.procartillas[index].ballpar.length > 0) {
                                this.procartillas[index].ballpar.splice(0,1);
                                this.procartillas[index].ballpar.splice(0,1);
                            } else {
                                this.temporalcold.splice(0,1);
                            }
                            }
                        }
                }

                let o = 0;
                while (o < 1) {
                    let k =Math.floor(Math.random()*((colds.length-1)-0+1)+0);  //Elige el numero cold que cambiara,
                    let number = parseInt(colds[k].ball, 10);
                    if ( !this.temporal.includes( number ) && !this.procartillas[index].ballhot.includes(number) && !this.procartillas[index].ballcold.includes(number) && !this.procartillas[index].ballpar.includes(number)) {
                        this.temporalcold.push(number); //Numero cambiado
                        this.procartillas[index].balls=[]; //Limpiamos la cartilla
                        this.procartillas[index].balls=this.temporal; //Colocamos los nuevos numeros
                        this.procartillas[index].ballcold = [];
                        this.procartillas[index].ballcold = this.temporalcold;//Agregar otro al arreglo de bolillas calientes
                        o++;
                    }
                }
                this.updateLeyenda(index);
                this.relaodPrice();
            },
            numeroPar:function( ){
                this.leyenda = [];
                this.leyenda = strings.pronosticoLeyenda;
                

                let index = this.paginadorForecast;
                let pars = this.pares;  //SE ALMACENA LOS NUMEROS FRIOS DEL BACKEND
                this.temporal = []; //SE ALMACENAN LAS BOLILLAS NORMALES RESULTADOS DE PROCARTILLA
                let count =  this.procartillas[index].balls.length + this.procartillas[index].ballhot.length + this.procartillas[index].ballcold.length + this.procartillas[index].ballpar.length;
                for (let i = 0; i < this.procartillas[index].balls.length; i++) { //5 bolillas de la cartilla aleatoria
                    this.temporal.push( this.procartillas[index].balls[i] );
                }
                this.temporalPar =[]; //SE ALMACENAN LAS BOLILLAS pares DE PROCARTILLAS SI TENDRIA
                for (let i = 0; i < this.procartillas[index].ballpar.length; i++) { //5 bolillas de la cartilla aleatoria
                    this.temporalPar.push( this.procartillas[index].ballpar[i] );
                }
                if (count >= this.numberBallsForecast ) { //No Hay espacio en las 5 o 6 bolillas
                        if (this.procartillas[index].balls.length > 1) {
                            this.temporal.splice(0,1); //Eliminar un numero de las 5 bolillas
                            this.temporal.splice(0,1); //Eliminar un numero de las 5 bolillas
                        } else {
                            if (this.procartillas[index].ballhot.length > 1) {
                            this.procartillas[index].ballhot.splice(0,1);
                            this.procartillas[index].ballhot.splice(0,1);
                            } else {
                            if (this.procartillas[index].ballcold.length > 1) {
                                this.procartillas[index].ballcold.splice(0,1);
                                this.procartillas[index].ballcold.splice(0,1);
                            } else {
                                if ( count - this.procartillas[index].ballpar.length == 3) {
                                    this.temporal.splice(0,1);
                                    if (this.procartillas[index].ballcold.length > 0) {
                                    this.procartillas[index].ballcold.splice(0,1);
                                    }else{
                                    this.procartillas[index].ballhot.splice(0,1);
                                    }
                                } else {
                                this.temporalPar.splice(0,1);
                                this.temporalPar.splice(0,1);
                                }
                                
                            }
                            }
                        }
                }

                if (count == this.numberBallsForecast - 1) { //Solo hay un espacio disponible
                if (this.procartillas[index].balls.length > 0) {
                            this.temporal.splice(0,1); //Eliminar un numero de las 5 bolillas
                        } else {
                            if (this.procartillas[index].ballhot.length > 0) {
                            this.procartillas[index].ballhot.splice(0,1);
                            } else {
                            if (this.procartillas[index].ballcold.length > 0) {
                                this.procartillas[index].ballcold.splice(0,1);
                            } else {
                                this.temporalPar.splice(0,1);
                                this.temporalPar.splice(0,1);
                            }
                            }
                        }
                }
                let o = 0;
                while (o < 1) {
                    let k =Math.floor(Math.random()*((pars.length-1) - 0+1)+0);  //Elige el numero cold que cambiara,
                    let number1 = parseInt(pars[k].numeros[0], 10);
                    let number2 = parseInt(pars[k].numeros[1], 10);

                    if (  !this.temporal.includes( number1 ) && !this.temporal.includes( number2 ) && !this.procartillas[index].ballhot.includes( number1 ) && !this.procartillas[index].ballhot.includes( number2 ) && !this.procartillas[index].ballcold.includes( number1 ) && !this.procartillas[index].ballcold.includes( number2 ) && !this.procartillas[index].ballpar.includes( number1 ) && !this.procartillas[index].ballpar.includes( number2 ) ) {
                        this.temporalPar.push(number1);
                        this.temporalPar.push(number2);
                        

                        this.procartillas[index].balls=[]; //Limpiamos la cartilla
                        this.procartillas[index].balls=this.temporal; //Colocamos los nuevos numeros
                        this.procartillas[index].ballpar = [];
                        this.procartillas[index].ballpar = this.temporalPar;//Agregar otro al arreglo de bolillas calientes
                        o++;
                    }
                }
                this.updateLeyenda(index);
                this.relaodPrice();
            },
            limpiarJugar:function(){
             
                this.stateLeyenda = false;
                for (let index = 0; index < this.procartillas.length; index++) {
                    if (this.procartillas[index].selectedCard) {
                        this.procartillas[index].balls = [];
                        this.procartillas[index].ballhot = [];
                        this.procartillas[index].ballcold = [];
                        this.procartillas[index].ballpar = [];
                        this.procartillas[index].ballcartilla = [];
                        this.procartillas[index].bExtra = null;
                        this.procartillas[index].ballGroupJA = []
                        this.procartillas[index].ballGroupJAE = [] 
                        this.updateLeyenda(index);
                    }
                }  
                this.relaodPrice();
                this.selectCardsOff();
            },

            eliminateCards:function(){ 
                for (let index = 0; index < this.procartillas.length; index++) {
                    if (this.procartillas[index].selectedCard) { 
                        const authorization = VueCookie.get('costumer_token'); 
                        if ( authorization && this.procartillas[index] != undefined ) { 
                                if (this.procartillas.length > 1) {
                                        this.procartillas.splice(index,1);
                                        this.eliminateCards();
                                }else{
                                    this.limpiarJugar();
                                }
                        } 
                    }
                }   
                this.relaodPrice();
                this.selectCardsOff();
            },
            showAnalitica:function( i ){
                this.showAnaliticaAparition = true;
                this.numberAnaliticaAparition = i;
                this.BALL_APPEARANCE_PRONOSTICO(i);
                // console.log('???showAnalitica: ', i);
            },
            async showAnaliticaDetail(i){
                this.BALL_APPEARANCE(i);
                    let searchParams = {
                    name: this.$route.params.name,
                    id: this.$route.params.id,
                    limit: 10,
                    type: 'month',
                    appearance: i,
                    from:  '2015-01',
                    to:   '2021-07',
                    };
                    await  EventBus.$emit('clicked',  searchParams)
            },
            activeCard:function( index ){

                if (this.procartillas[index].selectedCard) {
                    this.procartillas[index].selectedCard = false;

                  
                } else {
                    this.procartillas[index].selectedCard = true;
                } 

            },
            
            tamanoToggle:function(){

                return {
                    position: 'relative',
                    boxShadow: '0px 0px 4px black',
                    width: '412px',
                    height: (screen.height - (120 * 2)) + 'px',
                    background: '#F6F6F6', 
                    borderRadius: '10px 0px 0px 10px', 
                    padding: '10px'
                }; 
            },
            tamanoCross:function(){
                let element = document.getElementById('cartilla');
                if (element == null) {
                    return { }
                    
                } else{
                    return {
                        position: 'absolute', 
                        width: element.clientWidth+'px',  
                        zIndex: 2,
                        borderRadius: '17px',  
                        justifyContent: 'end',
                        display: 'flex',
                        alignItems: 'center',
                        right: '-3px',
                        top: '-3px'
                    }
                } 
            },
            tamanoCartilla:function(){
                let element = document.getElementById('cartilla');
                if (element == null) {
                    return { }
                    
                } else{
                    return {
                        position: 'absolute',
                        height: element.clientHeight+'px',
                        width: element.clientWidth+'px', 
                        background: '#eaebff', 
                        zIndex: 2,
                        borderRadius: '17px',
                        padding: '2px',
                        transition: '0.3s',
                        opacity: 0.9,
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }
                }

                
            },
            validarLoteriaTest:function(){
                if (this.nombreForecast != 'tinkas') {
                    return '250px';
                }else{
                    return '239px';
                }
            },
            topTest:function(){
                if (this.nombreForecast == 'florida') {
                    return '353px';
                }else{
                    return '376px';
                }
            },
            leftTest:function(){
                if (this.nombreForecast == 'florida') {
                    return '17px';
                }else{
                    return '-18px';
                }
            },
            formatDate(value) {
                return moment(String(value)).format('YYYY-MM-DD')
            },
            async activeSubtitleTest( index, name ){
                this.subtitleTest = name;
                if (this.subtitulosTest[index].subscription) {
                    const authorization = VueCookie.get('costumer_token');
                    if (authorization) {
                        await this.validateOneSubscription();
                        if (! this.existeSubscription) {
                            
                            await swal.fire( 'Solo para suscriptores!', 'Suscribte ahora o hazlo desde el menú de perfil del cliente!', 'success')
                            this.activePay();
                            return;
                        }
                    } else {
                        this.$router.push('/login-client')
                        //this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
                    }
                }
                },
                activeCartTest:function( i ){
                    if ( i == this.paginadorForecast) {
                    return true;
                    }
                },
                activeNumeroExtraTest:function(i, cartilla){
                    if(i != this.procartillas[cartilla].bExtra){
                            return true
                        }
                },
                activeCrossExtraTest:function(i, cartilla){
                
                    if(i == this.procartillas[cartilla].bExtra){
                            return true
                        }
                },
            jugarSubListCartillaTestStyle( i, index ){
                if (this.procartillas[index].bExtra  !=  null && this.procartillas[index].bExtra != i) {
                    return true;
                }
            },
            activeBallExtratest:function(index,j){
                if( this.procartillas[index].bExtra == null) { //Si la bola normal se encuentra la elimino sin aviso
             this.procartillas[index].bExtra = j;
                }else{
                    if ( this.procartillas[index].bExtra == j ) {
                        this.procartillas[index].bExtra = null;
                    } else {
                        if (this.procartillas[index].bExtra == null) {
                            this.procartillas[index].bExtra = j;
                        }
                    }
                }
                this.takePlayForecastBall(index);
                this.relaodPrice();
            },
            jugarListCartillaTestStyle(i, index){
                if (this.procartillas[index].balls.length  + this.procartillas[index].ballhot.length + this.procartillas[index].ballcold.length + this.procartillas[index].ballpar.length  ==  this.numberBallsForecast && !this.procartillas[index].balls.includes(i) && !this.procartillas[index].ballhot.includes(i) && !this.procartillas[index]. ballcold.includes(i) && !this.procartillas[index].ballpar.includes(i)) {
                    return true;
                }
            },
            async activeBallTest(index,i){
                // let posicionBallSuggestion = this.numberSuggestion.indexOf(i);
                let colds = this.listColdForecast;
                let hots = this.listHotForecast;
                let posicionBall = this.procartillas[index].balls.indexOf(i);
                let posicionBallhot = this.procartillas[index].ballhot.indexOf(i);
                let posicionBallcold = this.procartillas[index].ballcold.indexOf(i);
                let posicionBallpar = this.procartillas[index].ballpar.indexOf(i);
                let countBalls = this.procartillas[index].balls.length + this.procartillas[index].ballhot.length + this.procartillas[index].ballcold.length + this.procartillas[index].ballpar.length;
                let countBall = this.procartillas[index].balls.length;
                
                if (countBalls < this.numberBallsForecast && countBall < this.numberBallsForecast &&  i != this.procartillas[index].balls[posicionBall] &&   i != this.procartillas[index].ballhot[posicionBallhot] && i != this.procartillas[index].ballcold[posicionBallcold] && i != this.procartillas[index].ballpar[posicionBallpar] ) {
                
                    this.marcarNumero(index, i);
                    ///////////////// SUGERENCIA DE JUGADAS
                    // ????
                        if ( this.procartillas[index].ballpar.length < 3 && this.numberSuggestion.length <= 1 && this.procartillas[index].balls.length + this.procartillas[index].ballcold.length + this.procartillas[index].ballhot.length <= 1) {
                            // console.log('???entra');
                             
                                this.showSuggestion = true;
                                this.paginadorForecast = index;
                            this.numberSuggestion = [];
                             if (this.procartillas[index].balls.length == 1) {
                                 this.numberSuggestion.push(  ...this.procartillas[index].balls );
                             } else {
                                 if (this.procartillas[index].ballhot.length == 1) {
                                     this.numberSuggestion.push(  ...this.procartillas[index].ballhot );
                                 }else{
                                     this.numberSuggestion.push(  ...this.procartillas[index].ballcold );
                                 }
                             }
                             
                            let params ={};
                            params = {balls:this.numberSuggestion, name: this.$route.params.name }
                            await this.getSuggestionForecast( {...params, type:0} );
                        }
                    /////////////////////
                }
    
                //DESMARCADO DE LA BOLILLA
                if (posicionBall != -1) { //Si la bola normal se encuentra la elimino sin aviso
                    this.procartillas[index].balls.splice(posicionBall,1);
                    // if (this.procartillas[index].balls.length < 2 ) {
                    //         this.numberSuggestion.splice(posicionBallSuggestion,1);
                    //     }
                    
                }
                
                
            
                if (posicionBallhot != -1 || posicionBallcold != -1 || posicionBallpar != -1) {
                    swal.fire({
                        title: 'Estas seguro que quieres deseleccionar este número?',
                        confirmButtonText: `Confirmar`,
                        showCancelButton: true,
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            // let posicion = this.procartillas[index].balls.indexOf(i);
                            //       this.procartillas[index].balls.splice(posicion,1);
                                if (posicionBallhot > -1) {
                                    this.procartillas[index].ballhot.splice(posicionBallhot,1);
                                }  else{
                                    if (posicionBallcold > -1) {
                                        this.procartillas[index].ballcold.splice(posicionBallcold,1);
                                    } else {
                                        if (posicionBallpar > -1) {
                                            this.procartillas[index].ballpar.splice(posicionBallpar,1);
                                            let tag = false;
                                            if ( posicionBallpar % 2 == 1) {
                                                //  ????1
                                                posicionBallpar = posicionBallpar - 1;
                                            }
                                                
                                                    //VALIDANDO FRIOS
                                                        for (let k = 0; k <  colds.length; k++) {
                                                        let number = parseInt(colds[k].ball, 10);
                
                                                                if ( this.procartillas[index].ballpar[posicionBallpar] == number ) {
                                                                        tag = true;
                                                                        this.procartillas[index].ballcold.push( this.procartillas[index].ballpar[posicionBallpar] );
                                                                        this.procartillas[index].ballpar.splice(posicionBallpar,1);
                                                                }
                                                        }
                                                        //VALIDANDO CALIENTES
                                                        if (tag == false) {
                                                           for (let k = 0; k <  hots.length; k++) {
                                                                // console.log('???17');
                                                                let number = parseInt(hots[k].ball, 10);
                                                                    if ( this.procartillas[index].ballpar[posicionBallpar] == number  ) {
                                                                            tag = true;
                                                                            this.procartillas[index].ballhot.push( this.procartillas[index].ballpar[posicionBallpar] );
                                                                            this.procartillas[index].ballpar.splice(posicionBallpar,1);
                                                                    }
                                                            }
                                                        }
                                                        if (tag == false) {
                                                            this.procartillas[index].balls.push( this.procartillas[index].ballpar[posicionBallpar] );
                                                            this.procartillas[index].ballpar.splice(posicionBallpar,1);
                                                        }
                                                        
                                        }
                                    }
                                }
                                swal.fire('¡Deseleccionado!', '', 'success');
                                this.updateLeyenda(index);
                        } else if (result.isDenied) {
                            // console.log('Cancelado');
                        }
                    });
                }
                this.updateLeyenda(index);
                this.takePlayForecastBall(index);
                    this.relaodPrice();
                    // ???
            },
            //EL NUMERO MARCADO ES VALIDADO, SI ES CALIENTE FRIO O NORMAL
            marcarNumero:function(index, i){
                    let bandera = false;

                    
                    //VALIDANDO FRIOS
                        let colds = this.listColdForecast;
                        for (let k = 0; k <  colds.length; k++) {
                            let number = parseInt(colds[k].ball, 10);
                                if ( number ==  i && bandera == false) {
                                    bandera = true;
                                        this.procartillas[index].ballcold.push( i );
                                }
                        }

                        //VALIDANDO CALIENTES
                        let hots = this.listHotForecast;
                        for (let k = 0; k <  hots.length; k++) {
                            let number = parseInt(hots[k].ball, 10);
                                if ( number ==  i && bandera == false ) {
                                        bandera = true;
                                        this.procartillas[index].ballhot.push( i );
                                }
                        }

                        if ( bandera == false ) {
                            this.procartillas[index].balls.push(i);
                        }

            },
            activeNumeroTest:function( i, cartilla ){
                    if (!this.procartillas[cartilla].balls.includes( i ) && !this.procartillas[cartilla].ballpar.includes( i ) && !this.procartillas[cartilla].ballhot.includes( i ) && !this.procartillas[cartilla].ballcold.includes( i )) {
                        return true
                    }
            },
            activeNumeroTestScroll:function( index, i ){
                    if (!this.procartillas[index].balls.includes( i ) && !this.procartillas[index].ballpar.includes( i ) && !this.procartillas[index].ballhot.includes( i ) && !this.procartillas[index].ballcold.includes( i )) {
                        return true
                    }
            },
            activeCrossTest:function( i, cartilla ){
                let j;
                for(j = 0; j < this.procartillas[cartilla].balls.length; j++){
                    if(i == this.procartillas[cartilla].balls[j]){
                        return true;
                    }
                }
            },
            activeCrossTestScroll:function( index, i ){
                let j;
                for(j = 0; j < this.procartillas[index].balls.length; j++){
                    if(i == this.procartillas[index].balls[j]){
                        return true;
                    }
                }
            },
            activeCrossForecastPar:function(i, cartilla){
                let j;
                for(j = 0; j < this.procartillas[cartilla].ballpar.length; j++){
                    if(i == this.procartillas[cartilla].ballpar[j]){
                        return true;
                    }
                }
            },
            activeCrossForecastParScroll:function(index, i){
                let j;
                for(j = 0; j < this.procartillas[index].ballpar.length; j++){
                    if(i == this.procartillas[index].ballpar[j]){
                        return true;
                    }
                }
            },
            activeCrossForecastCold:function(i, cartilla){
                let j;
                for(j = 0; j < this.procartillas[cartilla].ballcold.length; j++){
                    if(i == this.procartillas[cartilla].ballcold[j]){
                        return true;
                    }
                }
            },
            activeCrossForecastColdScroll:function(index,i){
                let j;
                for(j = 0; j < this.procartillas[index].ballcold.length; j++){
                    if(i == this.procartillas[index].ballcold[j]){
                        return true;
                    }
                }
            },
            activeCrossForecastHot:function(i, cartilla){
                let j;
                if (this.procartillas.length) {
                    for(j = 0; j < this.procartillas[cartilla].ballhot.length; j++){
                        if(i == this.procartillas[cartilla].ballhot[j]){
                            return true;
                        }
                    }
                }
                
                
            },
            activeCrossForecastHotScroll:function(index,i){
                let j;
                for(j = 0; j < this.procartillas[index].ballhot.length; j++){
                    if(i == this.procartillas[index].ballhot[j]){
                        return true;
                    }
                }
            },
            pintarcartilla:function(index,i){
                let j;
                for(j = 0; j < this.procartillas[index].ballcartilla.length; j++){
                if(i == this.procartillas[index].ballcartilla[j]){
                    return true
                }
                }
            },
  
            validarColorAgrupacionesExtra:function(i){

                let colorBall= 'transparent'; 
                if (this.jaSelected == -1) {
                    colorBall = 'transparent';
                }else{ 
                    let j;
                    let jae  =  ( this.lotteryForecast.jae.length - 1 >= 0 ) ? this.lotteryForecast.jae[this.jaSelected] : [];
                    
                    for(j = 0; j < jae.length; j++){ 
                        if(i == jae[j].balls ){  
                            switch (jae[j].count) {
                                case 0: 
                                    colorBall = "#8f8f8f"
                                    break;
                                case 1: 
                                    colorBall = "#F76300"
                                    break;
                                case 2: 
                                    colorBall = "#DC001A"
                                    break;
                                case 3: 
                                    colorBall = "#660001"
                                    break; 
                                default:
                                    break;
                            }
                        }
                    } 
                }
                
                return {
                    position: "absolute",
                    background: colorBall, 
                    opacity: 0.8, 
                    borderRadius: "7px", 
                    height: "32px",
                    width: "32px"
                }
            },
            validarColorAgrupaciones:function(i){
                let colorBall= 'transparent'; 
                if (this.jaSelected == -1) {
                    colorBall = 'transparent';
                }else{ 
                    let j;
                    let ja  =  ( this.lotteryForecast.ja.length - 1 >= 0 ) ? this.lotteryForecast.ja[this.jaSelected] : [];
                    
                    for(j = 0; j < ja.length; j++){ 
                        if(i == ja[j].balls ){  
                            switch (ja[j].count) {
                                case 0: 
                                    colorBall = "#8f8f8f"
                                    break;
                                case 1: 
                                    colorBall = "#F76300"
                                    break;
                                case 2: 
                                    colorBall = "#DC001A"
                                    break;
                                case 3: 
                                    colorBall = "#660001"
                                    break;
                            
                                default:
                                    break;
                            }
                        }
                    } 
                }
 
                return {
                        position: 'absolute',
                        background: colorBall, 
                        opacity: 0.8,
                        height: '32px', 
                        width: '32px',
                        borderRadius: '7px', 
                        zIndex: 1, 
                    };
 
            },
  
            selectCartillaForecastTest:function( index3 ){
                        if (this.delete == false ) {
                            this.paginadorForecast = index3;
                        } else {

                            if ( this.procartillas.length > 1 ) {
                                if (index3 == 0 ) {
                                    this.paginadorForecast = index3;
                                }else{
                                    this.paginadorForecast = index3 - 1;
                                }
                            }else{
                                this.paginadorForecast = 0;
                            }
                            this.delete = false;
                        }
                        this.updateLeyenda(this.paginadorForecast);
            },
            linkClass(idx) {
                if (this.tabIndex === idx) {
                return ['bg-primary', 'text-light']
                } else {
                return ['bg-light', 'text-info']
                }
            },
            ...mapActions({
                getLotteryResult: "getLotteryResult",
                getProfile: 'getProfile',
                listCheckout: 'listCheckout', //Actualizo el conteo de cartillas registradas
                createCartLottery: 'createCartLottery',  // Subo las nuevas cartillas al checkout
                validateOneSubscription: 'validateOneSubscription',
                createOneSubscription: 'createOneSubscription',
                renovateOneSubscription: 'renovateOneSubscription',
                getSuggestionForecast: 'getSuggestionForecast',
                deleteOneCheckout: 'deleteOneCheckout',
                deleteAllCheckout: 'deleteAllCheckout',
            }),
            

            irCarrito:function(){
                 if (this.listNumbersToggle.length != 0) {
                            swal.fire({
                            title: '¿Confirma eliminar todas las jugadas?',
                            confirmButtonText: `Confirmar`,
                            cancelButtonText:  `Cancelar`,
                            showCancelButton: true,
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                this.listNumbersToggle = [];
                                await this.deleteAllCheckout();
                                await this.listCheckout();
                                swal.fire('¡Eliminado!', '', 'success')
                            } else if (result.isDenied) {
                                // console.log();
                            }
                        });
                } else {
                    swal.fire('¡ Lista vacía !', '', 'success')
                }

            },
            showSidebar:function(){
                return this.stateSidebar;
            },
                mostrarCartilla:function( index ){
                if (this.extraballForecast != 0) {
                    if ( this.procartillas[index].balls.length + this.procartillas[index].ballpar.length + this.procartillas[index].ballcold.length + this.procartillas[index].ballhot.length  >  0 || this.procartillas[index].bExtra != null ) {
                        return true;
                    }
                } else {
                    if ( this.procartillas[index].balls.length + this.procartillas[index].ballpar.length + this.procartillas[index].ballcold.length + this.procartillas[index].ballhot.length  >  0 ) {
                        return true;
                    }
                }

            
                },
                mostrarCartillaCross:function( index ){
                    let cantidad = this.procartillas.length;
                    let restante = cantidad - 5;

                    if ( index <= restante -1   ) {
                            return true;
                    } else {
                            return false;
                    } 
                },
                mostrarCartillaNumero:function( index ){
                    if (this.extraballForecast != 0) {
                        if ( this.procartillas[index].balls.length + this.procartillas[index].ballpar.length + this.procartillas[index].ballcold.length + this.procartillas[index].ballhot.length ==  0 && this.procartillas[index].bExtra == null ) {
                            return true;
                        }
                    } else {
                        if ( this.procartillas[index].balls.length + this.procartillas[index].ballpar.length + this.procartillas[index].ballcold.length + this.procartillas[index].ballhot.length ==  0) {
                            return true;
                        }
                    }

                
                },
                pintarhot:function(index,i){
                    let j;
                    for(j = 0; j < this.procartillas[index].ballhot.length; j++){
                        if(i == this.procartillas[index].ballhot[j]){
                            return true
                        }
                    }
        
                },
                pintarcold:function(index,i){
                    let j;
                    for(j = 0; j < this.procartillas[index].ballcold.length; j++){
                    if(i == this.procartillas[index].ballcold[j]){
                        return true
                    }
                    }
                },
                pintarpar:function(index,i){
                    let j;
                    for(j = 0; j < this.procartillas[index].ballpar.length; j++){
                    if(i == this.procartillas[index].ballpar[j]){
                        return true
                    }
                    }
                },
            limpiarPronostico:function(index){
                    this.procartillas[index].balls = [];
                        this.procartillas[index].ballhot = [];
                        this.procartillas[index].ballcold = [];
                        this.procartillas[index].ballpar = [];
                        this.procartillas[index].ballcartilla = [];
                        this.procartillas[index].bExtra = null;
            },
            adicionarPronosticos:function(){
                this.procartillas.push({  selectedCard:false, xJugada:null,balls:[] ,bExtra:null , ballhot:[], ballcold:[], ballpar:[], ballcartilla:[], ballcartillaExtra:null, ballAgrupacion:[], ballAgrupacionExtra:[], ballAgrupacionM1:[], ballAgrupacionM2:[], ballAgrupacionM3:[],ballAgrupacionS3:[],ballAgrupacionS4:[], ballAgrupacionM1e:[], ballAgrupacionM2e:[], ballAgrupacionM3e:[], ballAgrupacionS3e:[], ballAgrupacionS4e:[], ballAgrupacionJA1:[],ballAgrupacionJA1e:[], ballAgrupacionJA2:[],ballAgrupacionJA2e:[], ballAgrupacionJA3:[],ballAgrupacionJA3e:[], ballAgrupacionJA4:[],ballAgrupacionJA4e:[], ballAgrupacionJA5:[],ballAgrupacionJA5e:[], ballAgrupacionJA6:[],ballAgrupacionJA6e:[], ballAgrupacionJA7:[],ballAgrupacionJA7e:[], ballAgrupacionJA8:[],ballAgrupacionJA8e:[], ballAgrupacionJA9:[],ballAgrupacionJA9e:[], ballAgrupacionJA10:[],ballAgrupacionJA10e:[]  });
            },
            
            
             relaodPrice( ) {
                
                this.total = 0;
                for (let i = 0; i < this.procartillas.length; i++) {
                    if (this.extraballForecast != 0) {
                        
                        if ( this.procartillas[i].balls.length + this.procartillas[i].ballpar.length + this.procartillas[i].ballcold.length + this.procartillas[i].ballhot.length==  this.numberBallsForecast && this.procartillas[i].bExtra != null) {
                            this.total = this.total + 2.50;
                        }
                    } else {
                        
                        if ( this.procartillas[i].balls.length + this.procartillas[i].ballpar.length + this.procartillas[i].ballcold.length + this.procartillas[i].ballhot.length ==  this.numberBallsForecast) {
                            this.total = this.total + 2.50;
                        }

                    }
                    
                    
                }
                this.countTotal = this.total / 2.50;
            },
            async verifyFullCard( ) {
                  let i = this.paginadorForecast;
                    if (this.extraballForecast != 0) {
                        
                        if ( this.procartillas[i].balls.length + this.procartillas[i].ballpar.length + this.procartillas[i].ballcold.length + this.procartillas[i].ballhot.length==  this.numberBallsForecast && this.procartillas[i].bExtra != null) {
                            
                            await this.registrarCartilla( i );
                        }
                    } else {
                        
                        if ( this.procartillas[i].balls.length + this.procartillas[i].ballpar.length + this.procartillas[i].ballcold.length + this.procartillas[i].ballhot.length ==  this.numberBallsForecast) {
                            
                            await this.registrarCartilla( i );
                        }

                    }
            },
            
            
            updateLeyenda(index){
                    // ????4
                    this.leyenda = [];

                if ( this.procartillas[index].ballAgrupacionJA1.length > 0 ) {
                    this.leyenda.push(...strings.pronosticoJA1);
                }
                if ( this.procartillas[index].ballAgrupacionJA2.length > 0 ) {
                    this.leyenda.push(...strings.pronosticoJA2);
                }
                if ( this.procartillas[index].ballAgrupacionJA3.length > 0 ) {
                    this.leyenda.push(...strings.pronosticoJA3);
                }
                if ( this.procartillas[index].ballAgrupacionJA4.length > 0 ) {
                    this.leyenda.push(...strings.pronosticoJA4);
                }
                if ( this.procartillas[index].ballAgrupacionJA5.length > 0 ) {
                    this.leyenda.push(...strings.pronosticoJA5);
                }
                if ( this.procartillas[index].ballAgrupacionJA6.length > 0 ) {
                    this.leyenda.push(...strings.pronosticoJA6);
                }
                if ( this.procartillas[index].ballAgrupacionJA7.length > 0 ) {
                    this.leyenda.push(...strings.pronosticoJA7);
                }
                if ( this.procartillas[index].ballAgrupacionJA8.length > 0 ) {
                    this.leyenda.push(...strings.pronosticoJA8);
                }
                if ( this.procartillas[index].ballAgrupacionJA9.length > 0 ) {
                    this.leyenda.push(...strings.pronosticoJA9);
                }
                if ( this.procartillas[index].ballAgrupacionJA10.length > 0 ) {
                    this.leyenda.push(...strings.pronosticoJA10);
                }
 
                if ( this.procartillas[index].balls.length > 0 ) {
                    this.leyenda.push(strings.pronosticoLeyenda[0]);
                }
                if ( this.procartillas[index].ballhot.length > 0 ) {
                    this.leyenda.push(strings.pronosticoLeyenda[1]);
                }

                if ( this.procartillas[index].ballcold.length > 0 ) {
                    this.leyenda.push(strings.pronosticoLeyenda[2]);
                }

                    if ( this.procartillas[index].ballpar.length > 0 ) {
                    this.leyenda.push(strings.pronosticoLeyenda[3]);
                }
            },
            changeWidth:function(){
            if (this.nombreForecast != 'tinkas'){
                return 135 + 'px';
            } else {
                return 125 + 'px';
            }
            },
            jugarCartillasTestStyle(){
            // this.carritoForecast.push({  balls:this.procartillas[index].balls , ballhot:this.procartillas[index].ballhot, ballcold:this.procartillas[index].ballcold, ballpar:this.procartillas[index].ballpar, ballcartilla:this.procartillas[index].ballcartilla,bExtra:this.procartillas[index].bExtra });
            
                let x = 0;
                for (let i = 0; i < this.procartillas.length; i++) {
                    if ( this.extraballForecast != 0 ) {
                    if (this.procartillas[i].balls.length + this.procartillas[i].ballpar.length + this.procartillas[i].ballcold.length + this.procartillas[i].ballhot.length ==  this.numberBallsForecast && this.procartillas[i].bExtra != null) {
                        x++;
                    }
                    } else {
                    if (this.procartillas[i].balls.length + this.procartillas[i].ballpar.length + this.procartillas[i].ballcold.length + this.procartillas[i].ballhot.length ==  this.numberBallsForecast) {
                        x++;
                    }
                    }
                    
                }
                if (x == 0) {
                    return true;
                }
        
            },
            async jugarCartillasTest(){

            const authorization = VueCookie.get('costumer_token');
                    if (!authorization) {
                        //this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
                        this.$router.push('/login-client')
                        return;
                    }

            swal.fire({
                            title: 'Por favor, espere!',
                            allowOutsideClick: false,
                            
                            confirmButtonText: false,
                            showConfirmButton: false,
                            onBeforeOpen: () => {
                                swal.showLoading()
                            },
                        });
            this.numerosSidebar = [];
            let x = 0;
            for (let i = 0; i < this.procartillas.length; i++) {
                if (this.extraballForecast != 0) {
                    if (this.procartillas[i].balls.length + this.procartillas[i].ballpar.length + this.procartillas[i].ballcold.length + this.procartillas[i].ballhot.length ==  this.numberBallsForecast && this.procartillas[i].bExtra != null) {
                    await this.registrarCartilla( i );
                    x++;
                    }
                } else {
                    if (this.procartillas[i].balls.length + this.procartillas[i].ballpar.length + this.procartillas[i].ballcold.length + this.procartillas[i].ballhot.length ==  this.numberBallsForecast) {
                    await this.registrarCartilla( i );
                    x++;
                    }
                }
            }
            if (x == 0) {
                swal.fire({
                position: 'top-center',
                icon: 'warning',
                title: 'Campos de juego vacío!',
                showConfirmButton: false,
                timer: 2000
                })
                return false;
            }
            
                await this.listCheckout();
                this.vaciarPronosticosCompra();
                // --------------------------
                this.stateSidebar = true;
                setTimeout(() => {
                this.stateSidebar = false;
                }, 2000);
                // ----------------------------
                return true;
            },
            vaciarPronosticosCompra:function( ){
                for (let i = 0; i < this.procartillas.length; i++) { //Pronostico de 5 jugadas
                if (this.extraballForecast != 0) {
                    if (this.procartillas[i].balls.length + this.procartillas[i].ballpar.length + this.procartillas[i].ballcold.length + this.procartillas[i].ballhot.length ==  this.numberBallsForecast && this.procartillas[i].bExtra != null) {
                        this.procartillas[i].balls = [];
                        this.procartillas[i].ballhot = [];
                        this.procartillas[i].ballcold = [];
                        this.procartillas[i].ballpar = [];
                        this.procartillas[i].ballcartilla = [];
                        this.procartillas[i].bExtra = null;
                        
                   
                    }
                } else {
                    if (this.procartillas[i].balls.length + this.procartillas[i].ballpar.length + this.procartillas[i].ballcold.length + this.procartillas[i].ballhot.length ==  this.numberBallsForecast) {
                        this.procartillas[i].balls = [];
                        this.procartillas[i].ballhot = [];
                        this.procartillas[i].ballcold = [];
                        this.procartillas[i].ballpar = [];
                        this.procartillas[i].ballcartilla = [];
                        this.procartillas[i].bExtra = null;
                    }
                }
                }
                this.relaodPrice();
                
                
            },
            async registrarCartilla( index ){
                event.preventDefault();
                    let bolillas = [];
                        for (let i = 0; i < this.procartillas[index].balls.length; i++) {
                            bolillas.push(this.procartillas[index].balls[i]);
                        
                        }
                        for (let i = 0; i < this.procartillas[index].ballhot.length; i++) {
                        bolillas.push(this.procartillas[index].ballhot[i]);
                        }
                        for (let i = 0; i < this.procartillas[index].ballcold.length; i++) {
                        bolillas.push(this.procartillas[index].ballcold[i]);
                        }
                        for (let i = 0; i < this.procartillas[index].ballpar.length; i++) {
                        bolillas.push(this.procartillas[index].ballpar[i]);
                        }
                        
                        bolillas = bolillas.sort(function(a, b){return a - b});
                        let params ={};
                        let a = [];
                        a.push({numbers:[], bExtra:null, id:null })
                        a[0].numbers = bolillas;
                        a[0].bExtra = this.procartillas[index].bExtra;
                        //  ---------------------------
                        //AÑADE NUEMEROS AL SIDEBAR
                        // this.numerosSidebar.push(a[0]);
                        //AÑADE NUEMEROS AL TOGGLE
                        
                        // -------------------------------
                        params = { date_lottery: this.lotteryForecast.listLotteries[0].date_before ,loterie_id: this.$route.params.id , balls: a }
                        let info = await this.createCartLottery(params);
                        a[0].id = info.registered;
                        a[0].date_lottery = this.lotteryForecast.listLotteries[0].date_before;
                        this.listNumbersToggle.unshift(a[0])
                        // swal.fire({
                        //     position: 'top-center',
                        //     icon: 'success',
                        //     title: 'Añadido al carrito!',
                        //     showConfirmButton: false,
                        //     timer: 2000
                        // });
            },
        
                activeCrossForecast:function(  index, i ){
                let j;
                    for(j = 0; j < this.procartillas[index].balls.length; j++){
                        if(i == this.procartillas[index].balls[j]){
                            return true
                        }
                    }
                },
                selectCartillaForecast:function( index3 ){
                        if (this.paginadorForecast == index3) {
                            return '#c5c4c4 !important';
                        }
                },
                
                cardDelete:function( index ){
                     this.delete = true;  
                        this.paginadorForecast = index;
                        const authorization = VueCookie.get('costumer_token');
                        
                        if ( authorization && this.procartillas[index] != undefined ) {  
                                if (this.procartillas.length > 1) {
                                        this.procartillas.splice(index,1);                                   
                                }else{
                                    this.procartillas[index].selectedCard = true;
                                    this.limpiarJugar();
                                }
                        }     
                        this.relaodPrice(); 
                }, 

                validateType(){
                    
                    if( this.$route.params.val == "normal" ){
                        this.procartillas = this.procartillasNormal;
                    }else{
                        this.procartillas = this.procartillasLaggards;
                    }

                },
            async reloadCards(){
                    
                    this.validateType();
                    if (this.procartillas.length == 0) {
                        for (let i = 0; i < 5; i++) { //Pronostico de 5 jugadas
                                this.procartillas.push({  selectedCard: false,xJugada:null, balls:[] ,bExtra:null , ballhot:[], ballcold:[], ballpar:[], ballcartilla:[], ballcartillaExtra:null, ballAgrupacion:[], ballAgrupacionExtra:[], ballAgrupacionM1:[], ballAgrupacionM2:[], ballAgrupacionM3:[], ballAgrupacionS3:[], ballAgrupacionS4:[],ballAgrupacionM1e:[], ballAgrupacionM2e:[], ballAgrupacionM3e:[] , ballAgrupacionS3e:[], ballAgrupacionS4e:[], ballAgrupacionJA1:[],ballAgrupacionJA1e:[], ballAgrupacionJA2:[],ballAgrupacionJA2e:[], ballAgrupacionJA3:[],ballAgrupacionJA3e:[], ballAgrupacionJA4:[],ballAgrupacionJA4e:[], ballAgrupacionJA5:[],ballAgrupacionJA5e:[], ballAgrupacionJA6:[],ballAgrupacionJA6e:[], ballAgrupacionJA7:[],ballAgrupacionJA7e:[], ballAgrupacionJA8:[],ballAgrupacionJA8e:[], ballAgrupacionJA9:[],ballAgrupacionJA9e:[], ballAgrupacionJA10:[],ballAgrupacionJA10e:[]  });
                        }
                    }
                    let costumer =  await this.getProfile();
                    this.date.birth = moment(String(costumer.birthday)).format('YYYY-MM-DD');
                    // await this.getLotteryResult({ id: this.$route.params.id, name: this.$route.params.name })
                    // this.date.lottery = this.result.date_before;
                    
            },
            async mostrar(){     
                let datos = await this.listOneCheckout({ id: this.$route.params.id, name: this.$route.params.name });  
                // let add = false;
                this.cartillasForecast = datos.listCheckout;  
                // console.log(moment(String(this.cartillasForecast[0].date_lottery)).format('YYYY-MM-DD'));
                // for (let i = 0; i < this.cartillasForecast.length; i++) {
                //     this.cartillasForecast[i].balls =   JSON.parse(this.cartillasForecast[i].balls)  
                //     if ( moment(String(this.cartillasForecast[i].date_lottery)).format('YYYY-MM-DD')   != moment(String(datos.dateLottery)).format('YYYY-MM-DD') ) {
                //         add = true;
                //     }

                // }   
                let a = [];

                for (let i = 0; i < this.cartillasForecast. reverse().length; i++) {
                    a = [];
                   a.push({numbers:[], bExtra:null, id:null })
                        a[0].numbers = JSON.parse(this.cartillasForecast[i].balls);
                        a[0].bExtra = this.cartillasForecast[i].ball_red;
                        a[0].id = this.cartillasForecast[i].id;
                        a[0].date_lottery = this.cartillasForecast[i].date_lottery;
                        this.listNumbersToggle.push(a[0])
                }   
                        
                        

                this.relaodPrice();   

                
                // if ( add ) {
                //     swal.fire({
                //         title: '¿Desea continuar con las jugadas del sorteo pasado?',   
                //         confirmButtonText: `Continuar`,    
                //         cancelButtonText:  `Eliminar`,                
                //         showCancelButton: true,                          
                //     }).then(async (result) => {                
                //         if (result.isConfirmed) {   
                //                 // //procedimiento borrar 
                                
                //         } else if (result.dismiss == "cancel") { 
                //            for (let i = 0; i < this.cartillasForecast.length; i++) {  
                //                     if ( moment(String(this.cartillasForecast[i].date_lottery)).format('YYYY-MM-DD')   != moment(String(datos.dateLottery)).format('YYYY-MM-DD') ) {
                //                         await this.deleteOneCheckout( this.cartillasForecast[i].id );   
                //                         // this.cartillasForecast.splice(index,1);

                //                     }

                //                 }   
                //                 this.mostrar();
                            
                //         }
                //     }); 
                // }
                
                 

            }, 
        },
        
        async created(){
            this.reloadCards(); //Actualiza mis procartillas si hay datos en la store
            
            const { data: data } =  await analyticAPI.findModa({
                name: this.$route.params.name,
                id: this.$route.params.id,
                limit: 10,
                type: this.type,
            });
            for (let i = 0; i < data.labels.length; i++) {
                this.modal_list.push(parseInt(data.labels[i])) ;
            }

            this.mostrar(); 

        },
        
    }
</script>
<style scoped >
    .content-cards-lottery {
        height: 490px;
        overflow-y: auto;
    }

    @media screen and (max-height: 1440px) {
    .content-cards-lottery {
        height: 560px;
        overflow-y: auto;
    }

    }
    .vld-overlay.is-full-page {
        z-index: 1051 !important;
        position: absolute;
    }
    .play-range-error {
      border-color: red;
      color:red;
    }
    table  tr td:first-child {
        padding-left: 0 !important;
    }
    .btn-boot {
        width: 71px;
        height: 40px;
        padding: 10px;
        margin-right: 8px;
        margin-bottom: 8px;
        margin-bottom: 7px !important;
    }
    .btn-boot-extra {
        width: 71px;
        height: 40px;
        margin: 5px 5px !important;

    }
    .custom-checkbox-forecast {
        display: flex;
        justify-content: center;
    }
    input[type^="check"] {
        cursor: pointer;
        display: inline;
        width: auto;
        margin: 2px 8px 10px  10px !important;
        text-align: center;
        vertical-align: middle;
    }
    .btn-percentage {
        width: 90px;
        height: 40px;
        padding: 8px 4px !important;
        margin: 5px 5px !important;
    }
    @keyframes changewidth {
        from {
            transform: translateX(10px);
        }

        to {
            transform: translateX(30px);
        }
    }

    @keyframes flashToggle {
        from {
            background: #79F284;
        }

        to {
            background: #11A51F;
        }
    }
 
.onFlashToggle{
    animation-duration: 1s;
    animation-name: flashToggle;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
.onFlecha{
    animation-duration: 1s;
    animation-name: changewidth;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
.rectacgulo{
    width: 50px;
     height: 25px;
     background: #37b343;
     
}
 .triangulo {
     width: 0px;
     height: 0px;
     border-left: 30px solid #37b343;
     border-top: 20px solid transparent;
     border-bottom: 24px solid transparent;

     
}

.close_toogle_forecast{
    /* transform: translateX(330px); */
    transform: translateX(412px);
    transition-duration: 0.3s;
}
.open_toogle_forecast{
    transform: translateX(0px);
    transition-duration: 0.3s;
}
    .toogle-forecast{
        /* 370 */
        /* width: 370px; */
         height: inherit;
         position: fixed;
         right: 0;
         top: 120px;
         display:flex;
         z-index: 3;
        align-items: center;

    }
    .copiarStyleForecast{
        color: #626571;
        transition: 0.3s;
    }
    .copiarStyleForecast:hover div p{
        font-weight: 700;
    }
    .crossSuggestionCartillaJugar{
            background: #828b97;
            height: 24px;
            width: 22px;
            border-radius: 20px;
            text-align: center;
            cursor: pointer;
            box-shadow: 0px 0px 2px black;
            color: white;
            transition: 0.3s;
            position: absolute;
            top: -7px;
            right: -7px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 19px;
    }
    .error-label {
      text-align: right;
      font-size: 15px;
    }
    .mx-datepicker  {
      width: 300px !important;
    }
    .crossSuggestionCartillaJugar:hover{
        color: #294977;
        background: white;;
    }
    /* SCROLL CROSS SUBCARTILLAS */
    .crossCartillaJugar{ 
        background: #828b97;
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        text-align: center;
        padding-top: 1px;
        padding-left: 1px;
        cursor: pointer;
        box-shadow: 0px 0px 2px black;
        margin-left: 94px;
        color: white;
        transition: 0.3s;
    }
    .crossCartillaJugar:hover{
        color: #294977;
        background: white;;
    }
    /* ESTILOS DE LAS OPCIONES ABSOLUTE: OJITO */
    .ojoCartilla{
        cursor: pointer;
        box-shadow: 0px 0px 2px black;
        position: absolute;
        background: white;
        padding: 2px;
        border-radius: 15px;
        width: 30px;
        height: 30px;
        text-align: center;
        transition: 0.3s;
    }

     .ojoCartilla:hover{
      box-shadow: 0px 0px 2px black;
      background: #294977;
      color: white
      
    }

    .ojoCartilla:hover p{
      color: white
    }
    
    .ojoCartilla:hover i{
      
      color: white
      
    }
    .ojoCartilla i{
      
      color: black
      
    }
    /* ESTILOS DE MODAL PRONOSTICO */
    .modalRegenerarTitulo{
      color: #294977;
      color: rgb(41, 73, 119);
      font-size: larger;
      font-weight: 700;
    }
    .modalRegenerarColumna{
          cursor: pointer;
          text-align: -webkit-center;
          box-shadow: black 0px 0px 3px;
          border-radius: 7px;
          font-weight: 700;
          color: #294977;
          padding: inherit;
          width: 80px;
          height: 60px;
          margin: 9px;
          padding: 1px;
          transition: 0.3s;
    }
    .modalRegenerarColumna:hover {
          background: #294977;
          color: white;
    }

    .modalRegenerarColumnaEstadisticaCaliente{
          cursor: pointer;
          text-align: -webkit-center;
          box-shadow: black 0px 0px 3px;
          border-radius: 7px;
          font-weight: 700;
          color: #294977;
          padding: inherit;
          width: 80px;
          height: 80px;
          margin: 9px;
          padding: 1px;
          transition: 0.3s;
    }

    .modalRegenerarColumnaEstadisticaCaliente:hover {
          background: #5C0E2D;
    }

    .modalRegenerarColumnaEstadisticaCaliente:hover div{
          color: white;
    }

    .modalRegenerarColumnaEstadisticaFrio{
          cursor: pointer;
          text-align: -webkit-center;
          box-shadow: black 0px 0px 3px;
          border-radius: 7px;
          font-weight: 700;
          color: #294977;
          padding: inherit;
          width: 80px;
          height: 80px;
          margin: 9px;
          padding: 1px;
          transition: 0.3s;
    }
    
    .modalRegenerarColumnaEstadisticaFrio:hover {
          background: #027B76;
    }

    .modalRegenerarColumnaEstadisticaFrio:hover div{
          color: white;
    }

    .modalRegenerarColumnaEstadisticaPar{
          cursor: pointer;
          text-align: -webkit-center;
          box-shadow: black 0px 0px 3px;
          border-radius: 7px;
          font-weight: 700;
          color: #294977;
          padding: inherit;
          width: 80px;
          height: 80px;
          margin: 9px;
          padding: 1px;
          transition: 0.3s;
    }

    .modalRegenerarColumnaEstadisticaPar:hover {
          background: #AB853A;
    }

    .modalRegenerarColumnaEstadisticaPar:hover div{
          color: white;
    }

    /* ESTILOS DEL BOTON JUGAR */
    .styleBotonAgregarJugar{
        width: 90px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 72px;
        color: #294977;
        border-radius: 50px;
        box-shadow: 0px 0px 2px black;
        transition: 0.3s;
    }
    .styleBotonAgregarJugar:hover{
        color: white;
        background: #294977;
        
    }
    .botonPlayJugar{
        cursor: pointer;
        border-radius: 7px;
        width: 80%;
        height: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 2px black;
        transition: 0.3s;
    }

    .botonPlayJugar:hover{
        color: white;
        background: #294977;
    }
    .botonPlayJugar:hover p{
        color: white;
    }
    

    .styleTextThree{
        margin: 0px 0 0px;
        font-size: 18px;
        font-weight: 900;
    }
    .styleTextTwo{
        margin: 0px 0 0px;
        font-weight: 500;
        font-size: 18px;
    }
    .styleTextTwoSugestion{
        margin: 0px 0 0px;
        font-weight:  bold;
        font-size: 18px;
    }
    .styleTextOne{
        margin: 0px 0 0px;
        font-size: 12px;
        font-weight: 600;
        color:#294977
    }
    .cartillaSelectedJugar{
      box-shadow: 0px 0px 2px black;
      background:#eaebff;
      /* background: #EAF0F5;  */
      border-radius: 16px;
      /* padding: 2px; */
      transition: 0.3s;
    }
    .cartillaSelectedJugar:hover{
        background: #c5c4c4;
    }
    .cartillaOneJugar{
         background:white;
         cursor: pointer;
         transition: 0.3s;
    }
    .cartillaOneJugar:hover{
      
      background: #c5c4c4
      
    }
    .cartillaOneJugar:hover div p{
      
      color: white
      
    }
    .listNumerosJugar{
        margin: 2px;
        background: #dcdcf3;
        height: 10px;
        width: 10px;
        border-radius: 2px;
        align-items: center;
        display:flex
    }
    .inner-content:not(:first-of-type) {
        margin-left: 10px ;
    }
    .informacionTarifaJugar{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
    }
    .jugarPageOptions{
        cursor: pointer;
        background: white;
        text-align: center;
        margin-bottom: 8px  ;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        box-shadow: 0px 0px 2px black;
        padding: 10px;
        transition: 0.3s;
        width: 225px;
    }
    .jugarPageOptions:hover {
          background: #294977;
          color:white
    }
    .jugarPageOptions:hover p {
          color: white  ;
    }
    .jugarPageOptionsBot{
        cursor: pointer;
        background: #eaebff;
        text-align: center; 
        margin-bottom: 8px ;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        box-shadow: 1px 1px 2px black ;
        padding: 10px;
        font-size: 10px;
        font-weight: 600;
        transition: 0.3s;
    }
    .jugarPageOptionsBot.active{
        cursor: pointer;
        background: #294977;
        text-align: center; 
        margin-bottom: 8px ;
        color: white ;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        box-shadow: 1px 1px 2px black ;
        padding: 10px;
        font-size: 13px;
        font-weight: 600;
        transition: 0.3s;
    }
    .jugarPageOptionsBot:hover {
          background: #294977;
          color:white
    }
    .jugarPageOptionsBot:hover p {
          color: white  ;
    }
    .jugarPageOptionsBotExtra{
        box-shadow: 1px 1px 2px black ;
        cursor: pointer;
        background: #d0ffc5;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        padding: 8px 4px  ;
        margin: 0px 0px ;
        font-size: 13px;
        font-weight: 600;
        transition: 0.3s;
    }
    .mx-datepicker {
      width: 230px !important;
    }
    .jugarPageOptionsBotExtra.active{
        cursor: pointer;
        background: #019845;
        text-align: center;
        color: white ;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        box-shadow: 1px 1px 2px black ;
        padding: 8px 4px  ;
        font-size: 13px;
        font-weight: 600;
        transition: 0.3s;
    }
    .jugarPageOptionsBotExtra:hover {
          background: #019845;
          color:white
    }
    .jugarPageOptionsBotExtra:hover p {
          color: white  ;
    }
    .numero-test{
      font-size: 15px;
      margin: 0px 0 0px;
      color: rgb(80, 100, 102);
      font-weight: 600;
    }
    .botonJugarTest{
      width: 50%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .botonAdicionarTest{
        /* width: 20%;  */
        width: 100px;
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
    }
    .scrollYbotonJugar{
      display:flex;
      flex-direction: row;
    }
    .activeUnderline{
      text-decoration: underline
    }
    
    @media (max-width: 800px) {
        .botonJugarTest{
            width: 50%  ;
        }
        .informacionTarifaJugar{
            width: 50%;
        }
        
      .scrollYbotonJugar{
        flex-direction: row ;
      }
    }
    .horizontal-scroll {
        display: flex;
        /* width: 80%; */
        max-width: 624px;
        overflow: auto !important;
        height: 220px;
    }
    .outer {
        display: flex;
        flex: 1;
        width: auto;
        height: 100%;
        padding: 0 20px;
        flex-flow: row nowrap;
        align-items: center;
    }
    .inner-content {
        flex-shrink: 0;
        justify-content: center;
        width: 110px;
        height: calc(100% - 40px);
        box-shadow: 0px 0px 5px black;
        border-radius: 5px;
        padding: 5px;
    }
    .numeros-test{
      cursor: pointer;
      background: white;
      border-radius: 7px;
      margin: 2px 2px;
      padding: 7px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bg-primary {
        background-color: #294977 !important;
    }
    a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
        background-color: #294977 !important;
    }
    
    .numerosSidebarStyle{
      display:flex;
      padding: 10px;
      border-bottom: 1px solid rgb(221, 221, 221);
    }
    .vinetaToggleStyle{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        height: 30px;
        width: 30px;
        background: white;
        border-radius: 20px;
    }
    .numeroToggleStyle{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        height: 30px;
        width: 30px;
        background: white;
        border-radius: 20px;
        box-shadow: inset -6px 3px 10px rgb(0 0 0 / 20%), inset 0 0 12px rgb(0 0 0 / 10%);
    }
    .vinetaSidebarStyle{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        height: 40px;
        width: 40px;
        background: white;
        border-radius: 20px;
    }
    
    .numeroSidebarStyle{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        height: 40px;
        width: 40px;
        background: white;
        border-radius: 20px;
        box-shadow: inset -6px 3px 10px rgb(0 0 0 / 20%), inset 0 0 12px rgb(0 0 0 / 10%);
    }
    .numeroSuggestionStyle{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        height: 40px;
        width: 40px;
        background: white;
        border-radius: 20px;
        box-shadow: inset -6px 3px 10px rgb(0 0 0 / 20%), inset 0 0 12px rgb(0 0 0 / 10%);
    }
    
    
    
   
 
    
    .opcionesEliminarTodo{
      cursor:pointer;
      display: flex;
      align-items: center;
      /* justify-content: center; */
      height: max-content;
      border-radius: 7px;
      margin: 5px;
    }
    .desactiveBoton{
      cursor:not-allowed !important;
    }
    .activeCross{
      color: #506466 !important
    }
    .desactiveCross{
      float: left;
      margin-left: 0px;
      margin-top: -3px;
      color: transparent;
      font-size: 16px;
    }
    .containerCartillas{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      font-size: 15px;
      text-align: center;
      width: 660px;
      padding: 3px;
    }
    .punteroNormal{
      cursor:context-menu !important;
    }
    
    .listCartillas{
      cursor:pointer;
       box-shadow: 0px 0px 5px black;
      /* border: 1px solid #e6d7d7; */
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      background: white;
      
      height: 170px;
      margin: 10px;
      border-radius: 7px;
      padding-bottom: 10px;
      padding-top: 10px;
      padding-right: 5px;
      padding-left: 5px;
    }
    
    .listCartillas:hover{
      
      background: #dfdfdf
      
    }

    .listNumeroCartillasJugar{
      
      color: #D1D8E3;
      font-size: 90px;
      margin: 0px 0 0px;
      
    }
    
    .listCartillas:hover div h1{
      
      color: white
      
    }

    .listShow{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .listNumeros{
      background: #F6F6F8;
      width: 13px;
      height: 13px;
      margin-right: 2px;
      margin-left: 2px;
      margin-top: 2px;
      border-radius: 4px;
    }




 @media (min-width: 1367px) {
    .container {
        width: 1310px;
    }
 }
 
.text-center {
    text-align: center!important;
}


.activehot {
    font-size: 48px;
    margin: 3px;
    color: #68001C !important;
}

.activehotScroll{
    color: #68001C !important;
}

.activecold {
    font-size: 48px;
    margin: 3px;
    color: #027B76 !important;
   
}
.activecoldScroll{
    color: #027B76 !important;
}

.activepar {
    color: #AB853A !important;
    font-size: 48px;
     margin: 3px;
}
.activeparScroll{
    color: #AB853A !important;
}

 .activecartillaagrupacion {
      color: #8F8F8F;
        float: left;
      margin-left: 0px;
        margin-top: -1px;
        font-size: 48px;
    }
    .activeM1naranja {
      color: #660001;
        float: left;
      margin-left: 0px;
        margin-top: -1px;
        font-size: 48px;
}

    .activeM1amarillo {
      color: #DC001A;
        float: left;
      margin-left: 0px;
        margin-top: -1px;
        font-size: 48px;
    }

    .activeM1celeste {
       color: #F76300;
        float: left;
      margin-left: 0px;
        margin-top: -1px;
        font-size: 48px;
}

    .activecartilla {
        color: #8F8F8F;
        float: left;
        margin-left: 0px;
        margin-top: -1px;
        font-size: 48px;
    }

    .activecartillaagrupacionM2 {
      color: #8F8F8F;
      float: left;
      margin-left: 0px;
        margin-top: -1px;
        font-size: 48px;
    }

    .activeM2amarillo {
      color: #DC001A;
      float: left;
      margin-left: 0px;
        margin-top: -1px;
        font-size: 48px;
    }

    .activeM2celeste {
      color: #F76300;
      float: left;
      margin-left: 0px;
        margin-top: -1px;
        font-size: 48px;
    }
    .activeM2naranja{
      color: #660001;
      float: left;
      margin-left: 0px;
        margin-top: -1px;
        font-size: 48px;
    }

    .activecartillaagrupacionM3 {
      color: #8F8F8F;
      float: left;
      margin-left: 0px;
        margin-top: -1px;
        font-size: 48px;
    }

    .activeM3celeste {
      color: #F76300;
      float: left;
      margin-left: 0px;
        margin-top: -1px;
        font-size: 48px;
    }

    .activeM3amarillo {
      color: #DC001A;
      float: left;
      margin-left: 0px;
        margin-top: -1px;
        font-size: 48px;
    }

    .activeM3naranja {
      color: #660001;
      float: left;
      margin-left: 0px;
        margin-top: -1px;
        font-size: 48px;
    }
    .activecartillaagrupacionM1 {
      color: #8F8F8F;
        float: left;
      margin-left: 0px;
        margin-top: -1px;
        font-size: 48px;
    }
  
p, span, li {
    margin: 0;
    color: rgb(55 59 74 / 80%);
    line-height: 1.7;
    font-size: 16px;
}
.boton-ayuda {
    height: max-content;
    width: max-content;
    text-align: center;
    display: flex;
    padding: 10px 20px;
    /* transition: visibility 0s, opacity 0.5s linear; */
}
.texto-boton-ayuda {
    /* display: none; */
    height: auto;
    opacity: 1;
    transition: height 0ms 0ms, opacity 600ms 0ms;
    width: auto;
}

</style>